import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'

export default props => {
    useStyles(classes)
    const { onSwitch, on } = props

    const switchHandle = () => {
        onSwitch?.(!props.on)
    }

    return <span onClick={switchHandle} className={`${classes.Switch} ${on ? classes.On : classes.Off}`}>
        <span className={classes.Toggle}></span>
    </span>
}