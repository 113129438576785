import React, { useCallback, useRef, useState, useMemo } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { unitPrice, getLowerPrice, getDelPrice, isPromotion, getProductUrl } from '../../../../../../utils/product'
import { useLanguage, useWebp } from '../../../../../hooks/common'
import withItemScroll from '../../../../../hocs/item-scroll'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants/index'
import { FormattedMessage } from 'react-intl'
import CartIcon from '../cart/cart-icon'
import { Iconfont } from '../../../../../components/icon/iconfont'
import FaceBookShare from '../tools/share-to-face'
import withAddToWish from '../tools/add-to-wish'
import { useSelector } from 'react-redux'
import { getSensorsUrl } from '../../../../../utils/sensor'
// import ReactPlayer from 'react-player/lazy'
import CountDown from '../../../../../components/count-down'
// import withEnterView from '../../../../../hocs/enter-viewport'
import { ListingPreOrderComponent } from './pre-order-components'



export const ViewMoreProductItem = props =>{

    useStyles(classes)
    const { sensors, item } = props

    const clickHandle = e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.resourcepage_title,
			resource_type: sensors?.resource_type,
			resource_content: sensors?.resource_content,
			resource_position: sensors?.resource_position,
		})
	}


    return <figure className={`${classes.NormalItem}`}>
        <a
            href={item?.href} 
            data-position={sensors?.resource_position}
            data-type={sensors?.resource_type}
            data-content={sensors?.resource_content}
            data-title={sensors?.resourcepage_title}
            onClick={clickHandle}
        >
            <div className={classes.Image}>
                <div className={classes.ViewMore}>View <br /> More {'>'}</div>
            </div>
        </a>
        <figcaption>
        </figcaption>
    </figure>
}


export const EmptyItem = props => {
    useStyles(classes)
    return <figure className={classes.NormalItem}>
        <div>
            <div className={classes.Image}>
                <img alt="empty" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />
            </div>
        </div>
        <figcaption>
            <div className={classes.Name}>-</div>
            <div className={classes.Prices}>
                <span className={classes.Price}>{'-'}</span>
            </div>
        </figcaption>
    </figure>
}

const WishIcon = withAddToWish(props => {
    const { addToWishList, hasExistInWishList } = props
    return <span className={classes.Tool} onClick={addToWishList}>
        {
            hasExistInWishList ? <Iconfont style={{
                color: 'rgb(230, 69, 69)'
            }} className={classes.Icon}>&#xe67b;</Iconfont> : <Iconfont className={classes.Icon}>&#xe62c;</Iconfont>
        }
    </span>
})

const FaceIcon = props => {
    const { shareUrl } = props
    return <FaceBookShare shareUrl={shareUrl}>
        <span className={classes.Tool}>
            <Iconfont className={classes.Icon}>&#xe628;</Iconfont>
        </span>
    </FaceBookShare>
}


const Tools = props => {
    useStyles(classes)
    const { product, product_type, position, selectedColor, sensors } = props
    const isProm = isPromotion(product)
    const promotionType = isProm ? product.promotion.type : ''
    const global = useSelector(state => state.global)
    const { config } = global

    const stopEvent = useCallback(e => {
        e?.stopPropagation?.()
    }, [])


    return <div className={classes.Tools} onClick={stopEvent}>
        <WishIcon productId={product?.id} product_type={product_type} saved={product?.saved}/>
        <div className={classes.Group}>
            <CartIcon
                render={() => <span className={classes.Tool}>
                    <Iconfont className={classes.Icon}>&#xe62a;</Iconfont>
                </span>}
                sensors={
                    {
                        ...sensors,
                        layerName: product?.layerName,
                        promotionType,
                        trackingRecords: product?.trackingRecords,
                        dataSource: product?.dataSource,
                        geekoExperimentId: product?.geekoExperimentId,
                        geekoRequsestId: product?.geekoRequsestId,
                        aliRequestId: product?.aliRequestId,
                        aliExperimentId: product?.aliExperimentId,
                        product_position: position + 1,
                    }
                } product_type={product_type} productId={selectedColor?.productId || product.id} color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color} />

            <FaceIcon shareUrl={`${config?.root}${getProductUrl(product?.id, product?.name)}`} />
        </div>
    </div>
}



export default withItemScroll(props => {
    useStyles(classes)

    const { product, position, column, sensors, product_type, innerRef, filter, multicolorNotDisplay = false, festival } = props

    const language = useLanguage()

    const { differentColorStyleVariants, promotionalSign } = product

    const [selectedColor, setSelectedColor] = useState(differentColorStyleVariants?.find(v => v.productId === product.id))
    const [hovered, setHovered] = useState(false)

    const isWebp = useWebp() && product?.status === '1'

    const [lowerPrice, delPrice, isProm] = [
        getLowerPrice(product),
        getDelPrice(product),
        isPromotion(product)
    ]

    let off = 0
    if (delPrice) {
        off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
    }


    const url = getProductUrl(selectedColor?.productId || product?.id, product?.name, selectedColor?.color, '', language)


    const promotionType = isProm ? product.promotion.type : ''

    const isActivePromotion = isProm && !product?.promotion?.countdownHidden

    const priceClass = isActivePromotion ? classes.FlashPrice : (off > 0 ? classes.PromotionPrice : classes.Price)

    const imageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.mainImage || product?.pcMainImage}`
    const secondaryImageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.pcMainImage2 || product?.pcMainImage2}`


    const handleProductClick = e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.resourcepage_title,
			resource_type: sensors?.resource_type,
			resource_content: sensors?.resource_content,
			resource_position: sensors?.resource_position,
			product_position: position + 1,
            filter,
		})
	}


    const isPreorder = product?.isPreOrder && product?.promotion?.type === '11' && product?.promotion?.enabled


    return <figure className={`${classes.NormalItem} ${position === 0 ? classes.FirstImage : ''}`}>
        <a
            href={url} ref={innerRef}
            product-id={product?.id}
            data-product-list-source
            data-promotion={promotionType}
            data-layer={product?.layerName}
            data-tracking={product.trackingRecords}
            data-product-source={product.dataSource}
            data-geeko-experiment={product.geekoExperimentId}
            data-geeko-id={product.geekoRequsestId}
            data-request-id={product.aliRequestId}
            data-experiment-id={product.aliExperimentId}
            data-column={column}
            data-product-position={position + 1}
            data-position={sensors?.resource_position}
			data-type={sensors?.resource_type}
			data-content={sensors?.resource_content}
			data-title={sensors?.resourcepage_title}
            data-filter={filter}
            data-product-type={product_type} 
            data-parent-sku={product?.topFlag == "related" ? product?.parentSku : undefined} 
			data-top-flag={product?.topFlag == "self" || product?.topFlag == "related" ? true : undefined} 
            onClick={handleProductClick}
        >

            <div onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={classes.Image}>

                {
                    position === 0 ? <img src={hovered && secondaryImageUrl ? secondaryImageUrl : imageUrl} alt={product?.name} /> : <LazyLoadImage src={hovered ? secondaryImageUrl : imageUrl} alt={product?.name} />
                }

                {
                    off > 0 && <span className={classes.OffTip}>{`-${off}%`}</span>
                }

                <span className={classes.PreOrderOff}>Pre order</span>

                {

                    hovered && <div className={classes.AbsoluteTools}>
                        <Tools
                            product={product}
                            selectedColor={selectedColor}
                            product_type={product_type}
                            position={position}
                            sensors={sensors}
                        />
                    </div>
                }
            </div>
        </a>
        <figcaption>
            <div className={classes.Name}>{product.name}</div>
            <div className={classes.Prices}>
                <span className={priceClass}>{unitPrice(lowerPrice)}</span>
                {
                    delPrice && <del className={classes.Del}>{unitPrice(delPrice)}</del>
                }
            </div>
            {
                isPreorder && <ListingPreOrderComponent preorder={product.promotion}/>
            }
        </figcaption>
    </figure>
})