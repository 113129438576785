import useStyles from "isomorphic-style-loader/useStyles";
import React, { useEffect,useMemo,useState } from "react";
import { useIntl } from "react-intl";
import { useLang } from "../../../../../../hooks/common";
import { SetSelect } from "./size-chart-set-select";
// import SizeChartBodyMeasurement from "./size-chart-body-measurement";
import SizeChartHead from "./size-chart-head";
import SizeChartMeasureBody from "./size-chart-measure-body";
import SizeChartModalStature from "./size-chart-modal-stature";
import SizeChartProductMeasurement from "./size-chart-product-measurement";
import SizeChartSelect from "./size-chart-select";
// import SizeChartTable from "./size-chart-table";
import SizeChartType from "./size-chart-type";
import SizeChartTableSelect from "./size-chart-table-select";
import classes from './style.module.scss';


const SizeChartMain = props => {
    useStyles(classes)
    // 接收数据
    const {formateSizeData, productTaglia, productType, closeSizeChart, isApp, countryCode, show} = props || {}
    const {
        defaultCountry,
        sizeCountryList,
        bodyMeasurementData,
        yogaMeasurementData,
        setSelectList: fSetSelectList,
        tableData,
        countrySizeList,
        fitStr,
        fitTypeList,
        stretchList,
        modalStature,
        imgSrc,
        formateSize,
        shoesData,
        braMeasurementData,
        productMeasurementData,
        showCountryList
    } = formateSizeData || {}

    const intl = useIntl()
    const useCountryLang = useLang()

    // 单位数据
    const unitData = useMemo(() => {
        return [{
            id:0,
            label:intl.formatMessage({id:"inches"}),
            value:'inch'
        },{
            id:1,
            label:intl.formatMessage({id:"centimeters"}),
            value:'cm'
        }]
    }, [])
    // 单位选择
    const [unitSelect, setUnitSelect] = useState('inch')
    // 国家选择
    const [countrySelect, setCountrySelect] = useState(defaultCountry)
    // 是否展示如何匹配产品尺寸
    const [showProductSizeM, setShowProductSizeM] = useState(true)
    // 是否展示如何匹配身体尺寸
    const [showBodyM, setShowBodyM] = useState(true)
    // 套装选择部分的状态
    const [setSelected, setSetSelected] = useState(null)
    // 套餐选择列表
    const [setSelectList, setSetSelectList] = useState([])
    // 表格内容选择 product measurements 或者 body measurements
    const [showTableType, setShowTableType] = useState('product')

    // useEffect(() => {
    //     console.log("formateSizeData: ", formateSizeData)
    // }, [formateSizeData])

    useEffect(() => {
        // 传入countryCode自动切换国家
        if(countryCode){
            const countryObj = sizeCountryList?.find(sc => sc.value == countryCode)
            if(countryObj){
                setCountrySelect(countryCode)
            } else {
                setCountrySelect(defaultCountry)
            }
        } else if(defaultCountry) {
            setCountrySelect(defaultCountry)
        }
    }, [defaultCountry, countryCode])

    useEffect(() => {
        if(fSetSelectList?.length > 0){
            setSetSelectList(fSetSelectList)
            setSetSelected(fSetSelectList?.[0])
        } else {
            setSetSelectList([])
            setSetSelected(null)
        }
    }, [fSetSelectList])

    // 单位切换
    const unitChange = (e) => {
        setUnitSelect(e)
    }

    // 国家切换
    const countryChange = (e) => {
        setCountrySelect(e.target.value)
    }

    const getTableHead = useMemo(() => {
        if(tableData?.length == 0) return []
        let midHeadList = []
        const tableTypeData = tableData?.find(td => td?.part == setSelected)
        if(tableTypeData){
            midHeadList = tableTypeData?.tableHead?.[unitSelect] || []
        } else {
            midHeadList = tableData?.[0]?.tableHead?.[unitSelect] || []
        }
        if(showCountryList){
            let countryData = midHeadList?.find(mid => mid?.type)
            if(countryData){
                midHeadList?.splice(1, 1, {title: countrySelect, type:'country'})
            } else {
                midHeadList?.splice(1, 0, {title: countrySelect, type:'country'})
            }
        }
        return midHeadList
    },[tableData, setSelected, showCountryList, countrySelect, unitSelect])

    const getTableBody = useMemo(() => {
        if(tableData?.length == 0) return []
        let midBodyList = []
        const tableTypeData = tableData?.find(td => td?.part == setSelected)
        if(tableTypeData){
            midBodyList = tableTypeData?.tableBody?.[unitSelect]
        } else {
            midBodyList = tableData?.[0]?.tableBody?.[unitSelect] || []
        }
        if(showCountryList && !midBodyList?.[0]?.find(mid => mid?.country)){
            midBodyList?.forEach(ele => {
                let eleSize = ele?.find(ele1 => ele1?.name == 'size')
                let countrySizeObj = countrySizeList?.find(cs => {
                    return cs?.find(cs1 => cs1.country == 'US')?.size == formateSize(eleSize?.value, productType)?.sizeUs
                })
                countrySizeObj?.forEach((tar) => {
                    ele.push({...tar, value: tar?.size, type: 'country', title: tar?.country})
                })
            });
        }
        return midBodyList
    }, [tableData, setSelected, countrySelect, unitSelect, showCountryList, countrySizeList, productType, useCountryLang])

    const getMeasureTableHead = useMemo(() => {
        if(!bodyMeasurementData) return []
        let headList = [{title:intl.formatMessage({id: "size"}), key:'size'}]
        let data = bodyMeasurementData?.[0]?.data?.[unitSelect] || {}
        let keyList = Object.keys(data)
        keyList?.forEach(key => {
            if(!headList?.find(h => h?.key == key)){
                headList?.push({
                    key: key,
                    title: key == 'bust'? intl.formatMessage({id: 'bust'}):
                        key == 'waist'? intl.formatMessage({id: 'waist'}):
                        key == 'hip'? intl.formatMessage({id: 'hip'}):'-',
                })
            }
        })
        return headList
    }, [bodyMeasurementData, unitSelect])

    const getMeasureTableBody = useMemo(() => {
        if(!bodyMeasurementData) return []
        let bodyList = []
        bodyMeasurementData?.forEach(item => {
            let midObj = []
            midObj.push({name: 'size', title: intl.formatMessage({id: "size"}), value: item?.size})
            Object.keys(item?.data?.[unitSelect] || {}).forEach(key => {
                midObj.push({
                    name: key,
                    title: key == 'bust'? intl.formatMessage({id: 'bust'}):
                        key == 'waist'? intl.formatMessage({id: 'waist'}):
                        key == 'hip'? intl.formatMessage({id: 'hip'}):'-',
                    value: item?.data?.[unitSelect]?.[key]
                })
            })
            if(bodyList?.length < bodyMeasurementData?.length){
                bodyList.push(midObj)
            }
        })
        return bodyList
    }, [bodyMeasurementData, unitSelect])



    const getYogaMeasureTableHead = useMemo(() => {
        if(!yogaMeasurementData) return []
        let headList = [{title:intl.formatMessage({id: "size"}), key:'size'}]
        let data = yogaMeasurementData?.[0]?.data?.[unitSelect] || {}
        let keyList = Object.keys(data)
        keyList?.forEach(key => {
            if(!headList?.find(h => h?.key == key)){
                headList?.push({
                    key: key,
                    title: key == 'bust'? intl.formatMessage({id: 'bust'}):
                        key == 'waist'? intl.formatMessage({id: 'waist'}):
                        key == 'hip'? intl.formatMessage({id: 'hip'}):'-',
                })
            }
        })
        return headList
    }, [yogaMeasurementData, unitSelect])

    const getYogaMeasureTableBody = useMemo(() => {
        if(!yogaMeasurementData) return []
        let bodyList = []
        yogaMeasurementData?.forEach(item => {
            let midObj = []
            midObj.push({name: 'size', title: intl.formatMessage({id: "size"}), value: item?.size})
            Object.keys(item?.data?.[unitSelect] || {}).forEach(key => {
                midObj.push({
                    name: key,
                    title: key == 'bust'? intl.formatMessage({id: 'bust'}):
                        key == 'waist'? intl.formatMessage({id: 'waist'}):
                        key == 'hip'? intl.formatMessage({id: 'hip'}):'-',
                    value: item?.data?.[unitSelect]?.[key]
                })
            })
            if(bodyList?.length < yogaMeasurementData?.length){
                bodyList.push(midObj)
            }
        })
        return bodyList
    }, [yogaMeasurementData, unitSelect])












    const getShoeTableHead = useMemo(() => {
        return shoesData?.[0]?.tableHead
    }, [shoesData])

    const getShoeTableBody = useMemo(() => {
        let data = shoesData?.[0]?.tableBody?.[unitSelect] || []
        let newData = data?.map((d) => {
            return d?.map((d1, index) => {
                return {title: getShoeTableHead?.[index]?.title, value: d1}
            })
        })
        return newData
    }, [shoesData, unitSelect])

    const getBraMeasureTableHead = useMemo(() => {
        if(!braMeasurementData) return []
        let headList = [{title:intl.formatMessage({id: "size"}), key:"size"}]
        let data = braMeasurementData?.[0]?.data?.[unitSelect] || {}
        let keyList = Object.keys(data)
        keyList?.forEach(key => {
            if(!headList?.find(h => h?.key == key)){
                headList?.push({
                    key: key,
                    title: key == 'underBuset'? intl.formatMessage({id: 'underBuset'}):
                        key == 'bustDifference'? intl.formatMessage({id: 'bustDifference'}):'-',
                })
            }
        })
        return headList
    }, [braMeasurementData, unitSelect])

    const getBraMeasureTableBody = useMemo(() => {
        if(!braMeasurementData) return []
        let bodyList = []
        braMeasurementData?.forEach(item => {
            let midObj = []
            midObj.push({name: 'size', title: intl.formatMessage({id: "size"}), value: item?.size})
            Object.keys(item?.data?.[unitSelect] || {}).forEach(key => {
                midObj.push({
                    name: key,
                    title: key == 'underBuset'? intl.formatMessage({id: 'underBuset'}):
                        key == 'bustDifference'? intl.formatMessage({id: 'bustDifference'}):'-',
                    value: item?.data?.[unitSelect]?.[key]
                })
            })
            if(bodyList?.length < braMeasurementData?.length){
                bodyList.push(midObj)
            }
        })
        return bodyList
    }, [braMeasurementData, unitSelect])

    const tableHeadUsed = useMemo(() => {
        // if(productType == 'shoes'){
        //     return getShoeTableHead
        // } else 
        if(showTableType == 'product'){
            return getTableHead
        }else if(productTaglia == 'yoga'){
            return getYogaMeasureTableHead
        } else if(productType == 'bra'){
            return getBraMeasureTableHead
        } else {
            return getMeasureTableHead
        }
    }, [showTableType, productType, getTableHead, getBraMeasureTableHead, getMeasureTableHead, getYogaMeasureTableHead, productTaglia])

    const tableBodyUsed = useMemo(() => {
        // if(productType == 'shoes'){
        //     return getShoeTableBody
        // } else 
        if(showTableType == 'product'){
            return getTableBody
        }else if(productTaglia == 'yoga'){
            return getYogaMeasureTableBody
        } else if(productType == 'bra'){
            return getBraMeasureTableBody
        } else {
            return getMeasureTableBody
        }
    }, [showTableType, productType, getTableBody, getBraMeasureTableBody, getMeasureTableBody, getYogaMeasureTableBody, productTaglia])

    return <React.Fragment>
        <div className={classes.SizeChartBox}>

            {
                <React.Fragment>
                    <SizeChartSelect
                        intl={intl}
                        unitData={unitData}
                        unitSelect={unitSelect}
                        unitChange={(e) => unitChange(e)}
                        countryData={sizeCountryList}
                        countrySelect={countrySelect}
                        countryChange={(e) => countryChange(e)}
                        hideUnitSelect={productType == 'bra' && showTableType == 'body'}
                        />
                    {
                        productTaglia != 'shoes' &&
                        <SizeChartType
                            intl={intl}
                            fitTypeValue={fitStr?.fitType}
                            fitTypeList={fitTypeList}
                            stretchValue={fitStr?.stretch}
                            stretchList={stretchList}
                            />
                    }
                </React.Fragment>
            }

            {
                productType == 'clothing' && modalStature ?
                <React.Fragment>
                    <SizeChartModalStature
                        modalStature={modalStature}
                        unitSelect={unitSelect}
                        />
                </React.Fragment>:
                <React.Fragment></React.Fragment>
            }

            {/* 套装部分选择 */}
            {
                setSelectList?.length > 0 &&
                <SetSelect
                    setSelectList={setSelectList}
                    setSelected={setSelected}
                    setSelectChange={e => setSetSelected(e)}
                    intl={intl}
                    />
            }

            {/* product表格和body表格 */}
            <SizeChartTableSelect
                intl={intl}
                unit={unitData}
                productType={productType}
                productTaglia={productTaglia}
                showTableType={showTableType}
                setShowTableType={(e) => {
                    setShowTableType(e)
                    setShowBodyM(true)
                    setShowProductSizeM(true)
                }}
                tableData={tableData}
                tableHead={tableHeadUsed}
                tableBody={tableBodyUsed}
                />
            {
                showTableType == 'product' &&
                <React.Fragment>
                    <SizeChartProductMeasurement
                        showProductSizeM={showProductSizeM}
                        setShowProductSizeM={setShowProductSizeM}
                        productTaglia={productTaglia}
                        productType={productType}
                        productMeasurementData={productMeasurementData}
                        intl={intl}
                        imgSrc={imgSrc}
                        />
                </React.Fragment>
            }

            {
                productType != 'bra' && showTableType == 'body' &&
                <React.Fragment>
                    <SizeChartMeasureBody
                        showBodyM={showBodyM}
                        setShowBodyM={setShowBodyM}
                        />
                </React.Fragment>
            }

        </div>

    </React.Fragment>
}

export default SizeChartMain