export default {
    sort: 'Sort',//CM0768
    filter: 'Filter',//CM0930
    loading: 'Loading',//CM0380
    finished: 'Finished',//
    home: 'الرئيسية',//CM0141
    get: 'Get',//
    currency: 'Currency',//CM0345
    language: 'Language',//CM0655
    order_tracking: 'Order Tracking',//CM0825
    shipping_info: 'Shipping Info',//CM0837
    return_policy: 'Return Policy',//CM0105
    privarcy_policy: 'Privacy & Security Policy Terms',//CM0835
    get_app: 'Get The {app} App',//
    color: 'لون',//CM0143
    size: 'مقاس',//CM0142
    add_to_bag: 'أضف إلى عربة التسوق بنجاح',//
    clear: 'Clear',//CM0855
    apply: 'Apply',//CM0545_1
    search: 'Search',//
    i_shipping_for: 'I\'m shopping for...',//CM0112
    cancel: 'Cancel',//CM0765
    hot_search: 'Hot Search',//CM0705
    items: 'التوصية',//CM1032
    view_more: 'View More', //CM0153,
    sold_out: 'Sold Out',//CM0366
    view_all: 'الاراء الكاملة',//CM0797
    thanks_subscribe: 'Thanks for your subscribing',//CM0956
    thanks_enjoy: 'Please check your email for your username and password.',//CM0957
    subscribe_title: 'Sign-up for exclusive offers!',//CM0084
    subscribe: 'Subscribe',//CM0091
    smufcme: 'Sign me up for {brand} emails (you can unsubscribe at any time).',//CM0967
    c_info: '{brand} Info',//CM0830
    about_us: 'About Us',//CM0095
    affiliate: 'Affiliate',//CM0823
    blogger_program: 'Blogger Program',//CM0824
    help_support: 'Help & Support',//CM0831
    shipping_policy: 'Shipping Policy',//CM0106
    faq: 'FAQ',//CM0099
    order_tracking: 'Order Tracking',//CM0825
    coupons: 'رمز القسيمة',//CM0188
    bonus_points: 'Bonus Points',//CM1035
    customer_care: 'Customer Care',//CM0832
    contact_us: 'Contact Us',//CM0097
    return_policy: 'Return Policy',//CM0105
    out_of_stock: 'Out Of Stock',//CM0621
    wholesale_policy: 'Wholesale Policy',//CM0108
    drop_shipping_policy: 'Drop Shipping Policy',//CM0109
    i_p_rights: 'Intellectual Property Rights',
    privacy_policy: 'Privacy & Security Policy Terms',//CM0835
    term_notice: 'Terms & Conditions Notice',//CM0836
    category: 'الفئات', //CM1019
    new_in: 'New',//CM0001
    bag: 'عربة التسوق',//CM1021
    account: 'أنا',
    flash_sale: 'Flash Deals',//CM0772
    ends_in: 'Ends in', //CM1009
    free_gift: 'Free Gifts',
    multi_add: 'Sorry, you can only choose one item as a free gift, if you want add another item, it will replace the free gift you have already added.',
    learn_more: 'Learn More',
    email: 'بريد إلكتروني',
    recommend: 'سلع',
    confirm: 'confirm',
    get_the_look: "get the look", //CM1187
    previous: "previous", //CM1188
    next: "next", //CM1189
    beauty: "تجميل", //CM0322
    lingerie: "Lingerie",  //CM0135
    shop_the_look: "Shop the Look",
    good_to_know: "good to know", //CM1131
    time_left: "Time Left", //CM0363
    limited_titme: "Limited Time", //CM0907
    flash_sale: "تخفيضات سريعة", //CM0451
    new: "جديد", //CM0456
    more: "More",
    qty: "Qty", //CM0200
    reviews: "تعليقات", //CM0370
    recommend: "Recommend", //CM1034
    you_can_match_with: "You Can Match With", //CM1012
    related_searches: "Related Searches", //CM1000
    too_small: "Too Small", //CM0373
    ture_to_size: "True to size", //CM0372
    to_large: "Too Large", //CM0371
    translare_to: "Translate To", //CM1128
    translate: "translate", //CM1127
    overall_fit: "Overall Fit",
    share_the_product_to: "Share The Product To",
    description: "الشحن الي", //CM1130
    ingredients: "Ingredients", //CM1132
    estimated_time: "Estimated Time of Arrival", //CM1031
    model_stature: "Model Stature", //CM0367
    model_wear: "Model Wear", //CM1015
    height: "Height", //CM0971
    bust: "Bust", //CM0172
    waist: "Waist", //CM0173
    hips: "Hips", //CM0174
    shipping_information: "Shipping Information", //CM0149
    sizing_help: "ما هو مقاسي؟ ", //CM1014
    product_details: "Product Details", //CM1013
    you_may_also_likes: "ربما يعجبك هذا أيضاً", //CM0154
    if_the_item_comes_back: "If the item comes back into stock within 30 days, we will send you an email.", //CM1136
    you_haveArrival_reminder: "You have already subscribed to the arrival reminder", //CM1125
    sold_out_notification: "The item is sold out, click notify me for restock notification", //CM1126
    un_subscribe: "unSubscribe", //CM1123
    notify_me: "Notify Me", //CM1124
    add_to_cart: "أضف إلى عربة التسوق بنجاح", //CM0145
    product_measurements: "Product Measurements", //CM1183
    view_size_information: "View more size information", //CM1129
    beauty_not_supported: "Return and exchange goods are not supported.", //CM1134
    easy_return: "Easy Return", //CM1011
    find_simlar: "Find Simlar", //CM1135
    babies: "Babies",
    babies_look: "Babies Look",
    shipping_method: "وسيلة الشحن", //CM0623
    ship_current_address: "This item cannot ship to current address", //CM1184
    show: "Show",
    index_subscribe_agree: "By subscribe, you agree to our",
    privacy_cookie: "Privacy & Cookie",
    terms_conditions: "Terms & Conditions",
    and: "and",
    register: "حسابي", //CM1204
    us_address_deliverd: "The {value} is estimated to be delivered on",
    us_address: "U.S. address",
    enter_email_address: "Enter you email address",
    mystery_offer: "MYSTERY OFFER",
    get_mystery_offer: "I don't want my mystery offer",
    has_subscribe_to: "The Email has been used by others. Please specify another Email.",
    no_more_data: "No more data", //CM0381
    add: "Add",
    shopping_for: "I'm shopping for", //CM0112
    buy_now: 'Buy Now', //CM0665
    visual_search: "Visual Search",
    upload_image_search_photo: "Take a photo or upload an image to find similar items.",
    recommend: "Recommend", //CM1034
    new_arrivals: "New Arrivals", //CM0340
    price: "Price", //CM0946
    take_a_photo: "Take a Photo",
    upload_a_image: "Upload a Image",
    photo_upload_image: "You can now search for the item you want by take a photo or upload an image",
    not_match_fund: "Not Match found",
    please_try_clearer_image: "Please try a clearer image, or put the product you want to search for in the middle of the image",
    try_text_to_search: "Try text to search for the item you want",
    try_text_search: "Try Text Search",
    try_again_with_image: "Try Again With Image",
    or: "Or", //CM0547
    low_price: "Low Price",
    high_price: "High Price",
    register_success_message: "Check your inbox for your {coupon} and {point} reward! We've sent {email} a confirmation email. The link is valid for 72 hours only.", //CM1111
    register_success_message_phone: "Check your inbox for your {coupon} and {point} reward!",
    sign_in: "تسجيل", //CM0354
    or_join_with: "أو الانضمام باستخدام", //CM1236
    captcha_not_empty: "The CAPTCHA cannot be empty", //google
    forget_password: "هل نسيت كلمة المرور؟", //CM0458
    email_address: "عنوان البريد الإلكتروني", //CM0272
    password: "كلمة المرور", //CM0432
    six_characters_min: "6 characters minimum", //CM1100
    twenty_characters_max: "20 characters maximum", //CM1101
    no_emojis: "No emojis", //CM1102
    belong_get_points_500: "500 points will belong to you immediately", //CM1178
    finshed_register_shop: "Complete the registration and get Points Reward to shop", //CM1179
    register_privacy_policy: "I agree to the {term} and {privacy}", // CM1116 CM0462
    term_of_services: "Terms of Service", //CM0103
    privacy_policy_only: "Privacy Policy", //CM0104
    create_account: "Create Account", //CM0491
    coupon: "رمز القسيمة", //CM0543
    points: "نقاط", //CM1202
    welcome_to: "Welcome to", //CM1107
    registration_success: "Registration Successful", //CM1108
    reset_password: "Reset password", //CM0443
    please_send_email_auto_matically: "Please enter the email address that you registered with us.A password recovery email will be send to your automatically.", //CM1089
    send_email: "Send Email", //CM1090
    link_rest_password_to: "The link to reset password has been sent to", //CM1091
    create_new_password_link_easy: "To create your new password, just click the link in the email we sent you - easy", //CM1092
    check_agin_enter_email_send: "Didn't receive it? Check your junk mail or click the link below and we'll send you a new one.", //CM1093
    still_online_help: "If you still can't find it, click 'Online Help' for help", //CM1094
    three_hours_expires: "Warning: link expires in three hours", //CM1095
    resend_email: "Resend Email", //CM1115
    advanced_messenger: "Advanced messenger service", //CM0948
    discount_deals_weekly: "You will receive discount codes and special deals weekly", //CM0950
    response_order_infomation: "You can get auto response about order information", //CM0951
    online_custoer_service: "You can get online customer service", //CM0952
    continue: "يكمل", //CM0953
    password_incorrect: "Password incorrect! Forgot your password?", //CM1097
    try_again: "Try again", //CM1099
    emaul_no_register_now: "This email account is not registered, do you want to register now", //CM1103
    no_thanks: "NO,THANKS", //CM1104
    register_has_login_now: "This email account has been registered, do you want to log in now?", //CM1105
    log_in_now: "Log in now", //CM1106
    view_on_app: "View On App", //google
    today_no_change: "You've run out of chances today, go shopping!", //CM1140
    go_shopping: "go shopping", //CM1141
    player_again: "play again", //CM1142
    congratulations: "Congratulations", //CM0940
    please_try_again: "Oops, please try again", //CM1145
    go: "GO", //CM1180
    my_prize: "My Prizes", //CM1081
    rules: "Rules", //CM0777
    download_app: "Download APP", //CM1082
    expire_in: "Expire In", //CM1250
    please_select_size: "Please Select a Size", // google
    ok: "Ok",// google
    my_prizes_rules: "My Prizes & Rules", //CM1192
    you_have: "You Have", //CM1038
    changes: "Chances", //CM1039
    customer_services_prizes: "Please take a screenshot and {value} to contact customer service for your prize", //google
    click_here: "Click here", //CM1064
    first_lottery_login: "Please log in first to play", // google
    count_items: "{total} items",
    today_no_change_for_app: "You've run out of chances today. Get 1 extra chance on {name} APP!",
    email_entered_try_again: "The email you entered is invalid. Please check your email and try again",
    you_are_registerd: "You are already registered",
    subscribe_to_get_lottery_change: "*Offer can only be redeemed by new subscribers. By signing up, you agree to receive recurring marketing emails from {name} at the email address used when signing up.",
    invalid_password: "Invalid Password",
    lucky_draw: "LUCKY\nDRAW",
    lucky_draw_nobreak: "LUCKY DRAW",
    fill_in_password: "Fill in the password",
    subtext: "We'd like to send you notifications for the latest news and updates.",//CM1008
    no_thanks: "No Thanks",//CM1117
    allow: "Allow", //CM1118
    draw_now: "DRAW\nNOW",
    take_simlar_item_image: "Take a photo or upload an image to find similar items.",
    camera: "CAMERA",
    images: "IMAGES",
    shop_similar: "منتجات مشابهة",
    privacy_request_center: "Privacy Request Center",
    valid_code_sent: "The verification code has been sent, please go to your email to check the verification code",
    sending: 'Sending',
    send: 'Send',
    submit_successfully: 'Submitted successfully',
    thanck_request: 'Thank you for your request. A representative will review your request and contact you at the email address you provided.',
    country_is_required: 'Please select your country/region',
    name_is_required: 'Please enter your full name',
    account_is_required: 'Please enter your email address',
    account_is_invalid: 'The email you entered is invalid',
    code_is_required: 'Please enter the verification code',
    description_is_required: 'Please describe your problem in detail',
    country: 'البلد/المنطقة',
    place_holder_country: 'Please choose your Country/Region',
    full_name: 'Full Name',
    place_holder_fullname: 'Full name should contain 2-100 characters',
    your_account: 'Your {webname} account',
    place_holder_account: 'We will need you to provide the email address associated with your account in order to find the personal data we have stored in our system',
    verification_code: 'Verification Code',
    detailed_description: 'Detailed Description',
    place_holder_description: 'Please enter a detailed description',
    i_agree_to_the_privacy_policy: 'I agree that {webname} collets and use the data I submit above according to {policy} to Process my request',
    submit: 'Submit',
    next_step: 'Next Step',
    privacy_policy: 'Privacy & Cookie Policy',
    verify_email_comfirm: "Please make sure the email you entered {value} is correct, if you need to continue registration please click confirm.",
    how_to_pay: "How To Pay",
    share_to: "Share To",
    copy_link: "Copy Link",
    copy_success: "Copy successful",
    get_draw_chance: "get 1 extra chance",
    support_pre_sale_title: 'PRE-SALES', //CM0860
    support_pre_sale: 'If you have any questions before making a purchase chat with our online operators to get more information.',//CM0861
    support_after_sale_title: 'AFTER-SALES',//CM0862
    support_after_sale: 'If you have need any help about the after-sales issues, please submit a ticket.',//CM0863
    online_help: 'Online Help',//CM0915
    submit_ticket: 'Submit a Ticket',//CM0864
    search_for: 'I\'m shopping for ...',//CM0112
    clear_all: 'Clear All',//CM0931
    empty_cart_description: 'Your bag is currently empty',//CM0521
    you_selected: 'You selected {color}',//CM0475
    uploading_photo: 'Search by uploading a photo',//CM1298
    upload_photo: 'Upload Photo',//CM0257
    price_low_to_high: 'Price Low To High',//CM0845
    price_high_to_low: 'Price High To Low',//CM0846
    shop_similar: "منتجات مشابهة",//CM1304
    uploading: 'uploading',
    empty_filter_page: 'Uh oh! We couldn\'t find what you were looking for. Try use less filters!',//CM0955
    recently_search: 'Recently Searched', //CM1017
    my_account: 'My Account', //CM0296
    my_orders: 'My Orders', //CM0479
    my_message: 'My Message',//CM1119
    my_coupons: 'My Coupons',//CM1120
    my_points: 'My Points', //CM1121
    view_bag: 'View Bag',//CM0183
    sign_out: 'Sign Out',//CM0254
    total: 'إجمالي المبلغ',//CM0180
    check_it_in_coupons: 'Check it in [My Coupons]',
    got_it: 'Got it',
    congratulations_get: 'Congrats to get {money}!',
    wait: 'Wait',
    limit_coupon_unused: 'You still have limit coupon(s) unused',
    limit_24_time: '24 hours only',
    return_to_site: 'Return To Site',
    get_offer: "Get offer",
    enter_email_to_reveal_offer: 'enter your email to reveal your special offer', //CM1256
    buy: "Buy", //CM0242
    simply_special_price: "Simply scan the QR code to install and get ready with the SPECIAL PRICE!", //CM1209
    model: "Model", // CM0970 
    size_guide: "Size Guide", // CM0156
    weight: "Weight",
    customer_reviews: "Customer Reviews", // CM1018
    size_chart: "Size Chart", //CM0476
    support: "Support", //CM0094
    payment_method: "طريقة الدفع", //CM0389
    website_rights_reserved: "{name} is a registered trademark of {domain}.All Rights Reserved.", //CM0834
    follow_us: "follow us", //CM0093
    shipping_time: "Shipping Time", //CM0377
    costs: "Costs", //CM0965
    shipping_to: "Shipping To", //CM0972
    receiving_time: "Receiving time", //CM0375
    precessing_time: "Processing time", //CM0376
    products: 'Products',
    promotions: 'الترويج',
    go_to_bag: "Go to Bag",
    total_peice_price: "Total {total} peice | {price}",
    reset_qty: "Reset Qty",
    best_sellers: "Best Sellers",
    best_sellers_in: "Best Sellers in",
    product_unavailable: 'Product currently unavailable',
    save: "Save", //CM0212
    set: "set",
    buy_together: "Buy Together",
    add_successful: "Add Successful",
    collect: "collect",
    collect_all: "collect all",
    free_cash: 'Free Cash Giveaway!',
    win_100: '100% WIN Rate!',
    ann_c_1: 'Each Registered User has 1 chance per day and 1 extra chance after Checkout!',
    ann_c_2: '*Daily chance will be refreshed at 00:00 UTC, from Aug 17th to Aug 23rd.',
    counpon_has_sent: '{coupon} has been sent to your wallet.',
    expire_content: 'Expired in 24h!!! Now apply it at Checkout! <br/> *Can\'t be used to deduct shipping fees.',
    lucky_winner: 'LUCKY WINNER',
    view_prizes: "View Prizes",
    continue_shopping: 'Continue Shopping',
    go_wallet: 'Go Wallet',
    open: 'Open',
    shopping: 'Shopping',
    check: 'Check',
    day1: '1st Day',
    day2: '2nd Day',
    day3: '3rd Day',
    day4: '4th Day',
    day5: '5th Day',
    day6: '6th Day',
    day7: '7th Day',
    specialGift: 'Special Gift',
    specialGifts: 'Special Gifts',
    today: 'Today',
    checkInSuccess: 'Check in Successfully',
    checkDays: 'You have checked in for {day} days.',
    checkDay: 'You have checked in for {day} day.',
    checkRules: 'Anniversary Check-in Rules',
    openGift: 'Tap to open the Special Gift!',
    openNotificationTip: 'We are unable to send you notifications. Please go to settings to open it.',
    checkDaysLeft: 'You have checked in for {daysHave} consecutive days. Check in for more days to get more surprise.',
    remindMe: 'Remind Me',
    downloadNow: 'Download Now',
    alreadyChecked: 'You have already Checked-in today',
    checkedNow: '50,000+ GIFTS,HURRY UP!!!',
    checkInNow: 'Check-in now',
    gift: 'Gift',
    v_shop: "V-Shop",
    comments: "Comments",
    chart_ask_question: "Chat or ask questions",
    tap_mystery_gift: 'Tap to open the Mystery Gift!',
    mystery_gift: 'Mystery Gift',
    addtohometext: 'Add {site} to Home Page',
    inches: 'Inch',
    centimeters: 'cm',
    sizeconversation: 'Size Conversation',
    size: 'مقاس',
    bust: "bust",
    waist: "waist",
    hip: "hip",
    length: "length",
    size_chart: "Size Chart",
    size_help: "Size Help",
    fit_type: "Fit Type",
    stretch: "Stretch",
    product_measurements: "Product Measurements",
    body_measurements: "Body Measurements",
    bust_size: 'Bust',
    waist_size: "Waist Size",
    hip_size: "Hip Size",
    your_bust: "Your bust",
    your_waist: "Your waist",
    your_hips: "Your hips",
    measurements: 'We strongly suggest you have your measurements taken and use them as a reference when selecting an items size. Measure yourself by following the guide below.',
    bust_note: "Measure under your arms around the fullest part of your bust. Make sure to keep the tape level!",
    waist_note: "Make sure the measuring tape fits comfortably as you measure around the narrowest part of your natural waist.",
    hip_note: "Stand with your feet together, and measure around the widest part of your hips.",
    length_note: "To measure your foot length, sit down on a chair and place foot flat on a piece of paper. Trace the foot by holding a pencil vertically and outlining the foot. Using a ruler, measure the distance from the bottom of the heel to the tip of the longest toe. Record the result in both inches and centimeters and use these measurements to find your size in the chart.",
    product_measurements_message: "* This data was obtained from manually measuring the product, it may be off by 1-2 CM.",
    measure_product_size: "How to Measure the Product's Size?",
    body_measurements_message: "* Depending on your body type and dressing habits, the above sizes are for reference only.",
    measure_body_size: "How to Measure your Body?",
    measure_body_size_bust: "Measure the circumference over the fullest part of your breast.",
    measure_body_size_waist: "Measure your waist at the thinnest place.",
    measure_body_size_hip: "Measure the fullest part of your hips.",
    size_fit: "Size & Fit",
    height_waist: "Height/Weight",
    measure: "Measurements",
    model_wear: "Model Wear",
    one_size: 'One Size',
    code: "code",
    policy: "Policy",
    after_pay_faq: "Afterpay FAQ",
    klnara_policy_message: "Shop now, pay later with Klarna!",
    zip: "Zip",
    vip: "Vip",
    delivery: "Delivery",
    about_my_wallet: "About My Wallet",
    system_update: 'System Updating',
    system_update_back: 'To better serve you, we are updating our systems. We will be right back!',
    about_early_bird: "About EarlyBird",
    clear_pay: "Clearpay",
    sold_percent: "{percent}% Sold",
    purchase_limit: "Purchase limit: {qty} qty",
    agree_login_policy_message: "Before the next step, please check that you accept the Terms of use and privacy policy.", //CM0856
    register_with: "Register with", //CM0463
    enjoy_services: "to enjoy personalized services,", //CM0465
    including: "including", //CM0466
    online_order_status: "Online order status", //CM0469
    exclusive_emails: "Exclusive Emails", // CM0470
    wishlist: "Wishlist", // CM0344
    checkout_preferences: "Checkout Preferences", // CM0471
    sign_In_Facebook: "الاستمرار بالفيسبوك Facebook", // CM1233
    the_counry_not_support_message: "Current country/region does not support phone number sign in/registration, please use email to sign in/register",
    enter_the_password: "Enter the password", //google
    enter_the_code_number: "Enter the code number",
    enter_phone_number: "Enter your phone number",
    incorrect_phone_number: "Incorrect phone number",
    password_change_success: "Password changed successfully",
    confirm_password: "Confirm Password", //google
    verification_code_error_message: "Sorry, the verification code entered is incorrect or invalid.",
    phone_register_has_already: "The Mobile Phone Number Has Already Been To An Account, Sign In Now?",
    phone_number_no_register_now: "This phone number is not registered, do you want to register now?",
    location: "Location",
    telephone_number: "رقم الهاتف",
    resend: "Resend",
    use_verification_code: "Use the verification code to sign in", //google
    sin_in_via_password: "Sign in via password",
    verifycation_code_success: "Verification code send successfully",
    confirm_your_phone_number_change: "Confirm your phone number and input correct code to change password.",
    inconststent_password: "Inconsistent passwords",
    register_via_telephone_number: "Register via telephone number",
    register_via_email_address: "Register via e-mail address",
    signin_via_telephone_number: "Sign in via telephone number",
    signin_via_email_address: "Sign in via e-mail address",
    almost_sold_out: "Almost Sold Out",
    telephone: 'رقم الهاتف',
    forget_your_password: 'هل نسيت كلمة المرور؟',
    choose_your_location: 'Choose Your Location',
    installment_forms: 'Installment Forms',
    interest_free: 'interest-free',
    staging_rates: 'Staging rates vary by banks',
    accpect: 'Accept',
    sing_receive_discount_now: "Sign in to receive the following discounts now!",
    received_following_discount: "You've received the Following Discounts",
    received_vip_exclusive_discount: "You've received VIP Exclusive Discounts",
    pick_my_gift: "Pick My Gift",
    choose_add_your_cart: "Please choose your favorite gift to add to your cart.",
    reselect_gift_your_bag: "You can reselect the gift in your bag.",
    check_out: 'CHECKOUT',
    gift_to_your_bag: 'The Xmas Set has been put in your bag, you can checkout any time before Dec 6.',
    foot_length: 'Foot Length',
    network_error: 'Network Error',
    subscription: "Subscription", //CM0089
    invalid_email: 'The email you entered is invalid. Please check your email and try again.',
    new_register_privacy_policy: "By signing in to your account, you agree to our Trems of Service and Privacy Policy ",
    incorrect_email: "Incorrect Email",

    size_conversion: 'Size Conversion',
    international_size_reference: 'The international size comparison is for reference only, the specific size is still based on the actual size the clothes.',
    country_size: '{country} Size',
    enter_code_number: "Enter code number",
    enter_the_telephone_number_or_email: "رقم الموبايل أو عنوان البريد الإلكتروني ",
    enter_the_verification_code: "Enter the verification code",
    send_verification_code: "A verification code is sent to {value}",

    back: 'Back',
    free_shipping: 'Free Shipping',
    all: 'All',
    reset: 'Reset',
    done: 'Done',
    price_range: 'Price Range({currency})',
    price_range_only: 'Range Price',
    repick: 'Repick',
    pick: 'Pick',
    sure_delete_item: 'Are you sure you want to delete this product?',
    sure_delete_items: 'Are you sure you want to delete this product(s)?',
    recommend_add_item: 'Recommend Add-On Items',
    free_gifts: 'Free Gifts',

    available: 'Available',
    not: 'Not',
    select_anthoer_paymentmethod: 'Please Select Another PaymentMethod',
    sign_to_sync_bag: 'تسجيل الدخول لمزامنة عربة التسوق',
    ship_to_different_address: 'Ship to different shipping address',
    address_book: 'Address Book',
    first_name: 'الاسم الأول',
    last_name: 'اسم العائلة',
    phone_number: 'رقم الهاتف',
    address_line_1: 'Address Line 1',
    address_line_2: 'Address Line 2',
    address_line_1_placeholder: 'Street name and street number, company name.',
    number: 'Number',
    neighborhood: 'Neighborhood',
    city: 'المدينة',
    state: 'State / Province',
    zip_code: 'Zip Code / Postal Code',
    enter_it_manually: 'أدخل العنوان يدويا',
    optional: 'Optional',
    find_simillar: 'Find similar',
    ships_to_country: 'Ships To: {country}',
    shopping_bag: 'حقيبة التسوق',
    secure_payment: 'Secure Payment',
    shopping_bag_empty: 'Your shopping bag is empty!',
    sign_in_to_view_bag: 'sign in to view your cart and start shopping',
    shop_now: 'Shop Now',
    sign_in_register: 'حسابي / تسجيل',
    secure_checkout: 'دفع امن',
    create_account_more_discount: 'Create a new account to get more discount',
    continue_to_checkout: 'Continue to Checkout',
    checkout_guest_with_email: 'Checkout as a guest with your email',
    guest_checkout: 'الضيف يدفع',
    checkout: 'الدفع',
    move_to_wishlist: 'Move To Wishlist',
    delete: 'Delete',
    move_all: 'Move All',
    you_like_fill_it: 'You Might Like to Fill it With',
    add_to_wish_list_confirm: 'Are you sure moving the product(s) to wishlist from shopping bag?',
    apply_coupon: 'Apply Coupon',
    ivalid_items_title: 'These items are temporarily out of stock.',
    count_items: '{total} Items',
    add_shipping_insurance: 'Add Shipping Insurance',
    place_order: 'قم بالطلب',
    apply_points: 'Apply Points',
    max_available_count: 'Max Available {count}',
    apply_cash: 'Apply Cash',
    wallet: 'محفظة',
    order_confirmation: 'Order Confirmation',
    shipping_address: 'عنوان الشحن',
    switch_payment_method: 'Switch Payment Method',
    change_card_information: 'Change Card Information',
    process_payment: 'We are processing your payment',
    do_not_leave_refresh: 'Please do not leave or refresh',
    price_per_installment: '{price}/month',
    order_summary: 'Order Summary',
    shipping_insurance: 'Shipping Insurance',
    order_number: 'Order No.',
    item_total: 'Item Total',
    shipping_price: 'Shipping Price',
    order_total: 'Order Total',

    required: 'Required',
    invalid_email_address: '{email} is not a valid email',
    first_name_reg_default: 'First Name should contain non-pure numeric 1-40 characters',
    last_name_reg_default: 'Last Name should contain non-pure numeric 1-40 characters',
    phone_reg_10_11: 'Enter at least 10-11 numbers.',
    phone_reg_ae: 'Phone numbers should start with 50/52/54/55/56/58/2/3/4/6/7/9, followed by any 7 digits.',
    phone_reg_sa: 'Phone numbers should start with 50/53/55/51/58/59/54/56/57/11/12/13/14/16/17/811, followed by any 7 digits.',
    phone_reg_default: 'Incorrect phone number format (numbers only, no formatting)',
    please_enter_5_digits: 'Please enter 5 digits or 5 digits with a 4-digits number (E.g. 20001 or 20001-0000)',
    wrong_zip_code: 'Wrong ZIP CODE! Please offer us one or two-letter and one-two figures with a space end with one figure and two letters. (e.g. TN37 7HL,CW8 3AD)',
    wrong_address_br: 'O formato do endereço está incorreto, preencha o formulário de [01310-000]',
    not_vaild_cpf: '{value} não é um CPF válido',

    credit_debit_card: 'Credit/Debit Card',
    billing_address: 'Billing Address',
    select_a_card: 'Select a Card',
    use_new_card: 'Use A New Card',
    waiting_for_payment: "Waiting for payment",
    view: "view",

    auto_search_address: 'مكتشف العنوان: البحث عن طريق الشارع أو العنوان',
    cant_find_address: "Can't find the address?",
    select_pay_method: 'Please select a pay method!',
    only_left: 'Only {count} Left',
    pay_now: 'pay now',
    card_number: 'Card Number',
    expiration_date: 'Expiration Date',

    confirm_change_address: 'Some items shipped from US stores will not be available for checkout, are you sure you want to switch addresses?',
    edit: 'Edit',
    add_new_address: 'Add New Address',
    details: 'تفصيل أكثر',

    use_following_coupons: 'You can use the following coupons on the items in your shopping cart, please use them on the place order page.',
    cancel_info: 'Are you sure you want to cancel payment? If your order is not paid in {countdown},it will be canceled.',
    continue_to_pay: 'تابع للدفع',
    confirm_cancel: 'Confirm Cancel',
    protectAccount: 'Your account details are fully protected and will not be revealed to any third party for any reasons.',
    available: 'Available',
    coupons_tip: "Coupon/Rewards can be used in the next step",
    invalid_address: 'Incorrect address format',








    // Me
    login_or_register: "حسابي / تسجيل",
    introduce_to_others: "Introduce yourself to others",
    sure_delete_items: "Are you sure you want to delete the item(s)?",
    guest_user: "Guest User",
    set_password: "Set password",
    temporay_account_set_password: "This is your temporay account. Set up password to register to get more discount!",
    addFavorites: 'Add your favorites to your Wishlist now!',









    // Me Setting
    sure_delete_address: 'Are you sure to delete this address',  //google
    change_successful: 'Change Successful!',   //google
    change_failed: 'Change Failed!',  //google
    save_successful: 'Save Successful!',  //google
    save_failed: 'Save Failed!',  //google
    change_email: 'Change Email',  //google
    your_contact_email: 'Your contact / subscription address:',  //google
    send_confirm_email: 'Send a confirm Email',
    your_new_account: 'Your New Account',
    my_payment_options: 'My Payment Options',
    change_password: 'Change Password', // changePassword
    about: 'About',
    sure_delete_card: 'Are you sure you want to remove this credit card', //remove_credit_card
    current_password: "Current Password",
    new_password: "New Password",
    must_new_password: "Confirm Password must equals to the new password.",
    confirm_pwd: 'Confirm Password',
    old_new_password: "The old and new passwords cannot be the same",
    expired_in: "Expired in",
    view_rules: "View Rules",
    vip_rewards: "{name} Vip Rewards",
    level_up_unlock: "level up to unlock",
    view_rewards: "View Rewards",
    points_redeem_success: 'Redemption successful!',
    faqs: "FAQS",
    your_vip_level_to: "Your VIP level has been level-up to",
    your_new_rewards: "go check out your new rewards!",
    points_history: "Points History",
    expriring_soon: "Expriring Soon",
    how_to_get_points: "How to get points?",
    buy_and_earn: "Buy and Earn",
    shop_now: "تسوق الان",
    review: "Review",
    suggestion: "Suggestion",
    refer_a_friend: 'Refer a Friend',
    survey: "Survey",
    more_ways: "More Ways",
    hot_use_points: "How to Use Points?",
    redeem_coupon: "Redeem Coupon",
    apply_points: "Apply points towards purchase",
    all: "All",
    recived: "Recived",
    used: "Used",
    expired: "Expired",
    wishlist: "Wishlist",
    delete: 'Delete',

    unused_coupon: 'Unused Coupons', // unused_coupons
    expired_coupon: 'Expired Coupons', // expired_coupons
    expired_coupon_remove: 'Expired coupons will be removed from the coupon pocket after 7 days.', // expired_coupoons_message
    yes: 'Yes',
    no: 'No',
    bust_size: 'Bust Size',
    bra_size: 'Bra Size',
    what_your_preference: 'What is your preference',
    large: 'Large',
    small: 'Small',
    my_measurements: 'My Measurements',
    preference_cannot_empty: "My preferences can't all be empty", // my_preference_error_message
    my_preference: 'My Preference',
    who_you_buy_clothes_for: 'Who do you usually buy clothes for?',
    which_catehories_you_favor: 'Which are your favorite categories?',
    which_style_you_favor: 'Which are your favorite styles?',
    bio: 'bio',
    name: 'Name',
    first_name: 'الاسم الأول',
    last_name: 'اسم العائلة',
    nick_name: 'Nickname',
    change_profile_picture: 'Change profile picture',
    edit_profile: 'Edit Profile', // editProfile
    empty_here: "It's empty here", // google
    ticket: 'Ticket',
    activities: 'Activities',
    promo: 'Promo',
    others: 'Others',
    news: 'News',
    orders: 'Orders',
    message: 'Message',
    about_web: 'About {website}',
    setting: 'Settings',  // settings
    wallet_credit: 'Wallet Credit',
    only_apply_for: 'Only Applicable for {website} Purchases', // only_applicable
    wallet_history: 'Wallet History',
    earned: 'Earned',
    wallet: 'محفظة',
    expired_on: 'Expired on: {date}',  // expied_on
    order: 'Order', // order_wallet
    get_more_points: "Get More points",

    share_friend_points_shop: "You will get up to <span class='font-italic'>200 points</span>,and your Friend will get up to <span class='font-italic'>300 points</span> to shop!", // CM1158
    for_limited_time_only: "For limited time only!", // CM1159
    how_does_it_works: "How does it works?", // CM1161
    share_the_link: "Share the link", // CM1162
    reward: "Reward", //CM0630_1
    copy_fail: 'copy failed! try again', // google
    get_more_rewards: "Wanna get more rewards?", //CM1164
    friends_invited: "Friends Invited", // CM1167
    points_earned: "Points Earned", // CM1168
    share_with: "Share With", // CM1170
    share_friend_max_10: "The link can be shared with {value} friends at one time", //CM1166
    email_title: "Email Title", //CM1155
    open_to_points_reward: "Open to receive mystery points rewards", //CM1242
    email_notes: "Email Notes", //CM1156
    share_textarea_description: "{name} has so many must-haves! Register now for points rewards to shop at {name}. {value}. Don't miss this chance!", // CM1243
    share_via_email: "Share via Email", // CM1151
    share_extra_game_play: "Click on the link and get me an extra game play", //google
    share_draw_luck_gifts: "{name} Black Friday Lucky Draw. Play and win iPhone 13, {name} beauty faves, Bonus Points, Coupons & more.",  //google
    success: "Success", //CM0558

    order_no: 'Order No', // orderNo
    item: "Item",
    track: 'Track',
    pay_now: 'Pay Now', // paynow
    remaining: 'Remaining Payment Time',
    repurchase: 'Repurchase',
    to_review: "To Review",
    add_success: 'Add Success',
    add_failed: 'Add Failed',
    unpaid: 'Unpaid',
    processing: 'Processing',
    shipped: 'Shipped',
    returns: 'Returns',
    history_orders_message: "Click here to check your previous orders.",
    order_details: "Order Details",
    your_package_is_packed: "Your package is being packed",
    it_normally_process_your_order: "It normally takes 3-7 business days for us to process your order.",
    your_package_signed_for: "Your package has been signed for.",
    if_you_cosutomer_services: "If you have any questions, please contact customer service.",
    payment_date: 'Payment Date', // paymentDate
    related_order_no: 'Related Order No', // relatedOrderNo
    shipping_price: 'ShippingPrice', // shippingPrice
    order_total: 'Order Total', // orderTotal
    insurance: "Insurance", // shippingInsurance
    shipping_address: 'Shipping Address',
    billing_address: 'Billing Address',
    shipping_method: 'وسيلة الشحن',
    delivery_time: 'Delivery Time',
    return: 'Return',
    often_bought_with: "OFTEN BOUGHT WITH",
    cancel_order: 'Cancel Order', // cancelOrder
    return_label: "Return Label", // returnlabel
    confirm_cancel: 'Confirm Cancel',
    continue_to_pay: 'Continue To Pay',
    you_sure_cancel: 'Are you sure you want to cancel payment? If your order is not paid in',
    it_will_cancel: 'it will be canceled',
    select_reason: "Select a reason",
    reason_submit_success_message: "Submitted successfully! Would you like to add the product to your shopping bag again?", // google
    no_comment_order: "You have unreviewed orders, go and share your experience to earn more points!",
    you_have_no_orders: "You don't have any orders yet, go pick your favorite products!", // youHaveNoOrders
    go_shopping: 'Go Shopping', // goShopping
    default: ' أجعله أفتراضياً ',
    do_not_have_enough_points: 'You do not have enough points to redeem this coupon.', // points_not_enough_text
    use_points_redeem_coupon: 'Do you want to use {points} points to redeem this coupon?', // points_check_redeem_text
    redeem: 'Redeem',  // points_redeem
    redeemed: 'Redeemed',
    use_points_to_redeem: 'Use points to redeem more coupons', // use_points_redeem_coupon
    upload_image: 'Upload Image',
    image_formate: 'Maximum of 3 photos, only JPEG, GIF or PNG.', // maxinum_three_photos
    screen_freeze: 'Screen Freeze',
    app_crush: 'App crush',
    black_screen: 'Black Screen',
    device_reboot: 'Device reboot', // device_rebot
    running_slowly: 'Running slowly',
    other_error: 'Other error',
    update_success: 'Update Success',
    question_type: 'Question Type',
    time: 'Time',
    suggestion_placeholder: 'Sorry for the inconvenience, we will fix the problem as soon as possible…', // sorry_inconvenience
    points: 'نقاط',
    please_check_rules: 'Please check the rules page for text and picture points rules',
    rating: "Rating", // google
    very_satisfied: "Very Satisfied",
    satisfied: "Satisfied",
    normal: "Normal",
    bad: "Bad",
    poor: "Poor",
    order_review_placeholder: 'Earn {point} points for comments over {wordNum} characters… *', // google
    earn_more: 'Earn more {points} points for comments with pictures',
    my_size_infomation: 'My Size Information (Optional)',
    earn_more_for_size: 'Earn more {points} points for fill your all sizes',
    comments_not_empty: "Comments cannot be empty!", // google
    order_confirmed: "Order Confirmed", // order_confirm
    order_detail_view: 'You can view order details to check this order.',
    order_confirm_success: 'Order Confirmed Successfully!',
    view_order: 'View Order',
    order_confirmed_enter_message: 'The item (s) is still in transit, are you sure you want to click confirm to receive it?', //google
    also_awaiting_review: "These orders are also awaiting review",
    shop_the_reviewd_items: "Shop the highly reviewed items",
    add_to_bag_success: 'Add to Bag Successfully!',
    logisticsInfo: 'Logistics Information',
    tracknum: 'Tracking Number',
    logisticsCompany: 'Logistics Company',
    trackingDetailInfo: 'Tracking Detail Information',
    currentStatus: 'Current Stataus',
    cancel_return: "Cancel Return",
    edit_return_receipt: "Edit Return Receipt",
    return_receipt: "Return Receipt",
    return_record: "Return Record",
    return_canceled: "Return Canceled",
    total_refund: "Total Refund",
    are_you_want_cancel_return: "Are you sure you want to cancel the return?",
    upload_receipt: "Upload Receipt",
    a_pooto_package_and_tracking_number: "A photo of the receipt from the shipping company indicating the weight of the package and tracking number.",
    courier_company: "Courier Company",
    select_courier_company: "Please Select the Courier Company", // please_select_courier_company
    fillin_courier_cpmpany: "Please fill in your courier company",
    not_be_empty: "can not be empty",
    check_order: 'You can check your order details to see the progress of your return.',
    return_logistics: 'Return Logistics',
    find_similar: "Find Similar",
    history_orders: "History Orders",
    dont_want_rate: 'Sure you don\'t want to rate this order? You will receive ',

    help_you: 'How Can I Help You?',
    message_us: 'Message Us',
    tickets: 'Tickets',
    search_holder: 'Products, Delivery, Payment...',
    search_results: 'Search Results',
    search_count: '{count} results for "{key}"',
    section_articles: 'Articles in this section',
    status_replied: 'Replied',
    status_unreplied: 'Unreplied',
    status_resolved: 'Resolved',
    last_message: 'Last Message',
    image: 'Image',
    ticket_id: 'Ticket ID',
    subject_size_color: 'Size/Color Preference',
    subject_address: 'Change Shipping Address',
    subject_shipping: 'Shipping Status or ETA Inquery',
    subject_wrong: 'Received Damaged or Wrong Item',
    subject_shippingmethod: 'Upgrade Shipping Method',
    subject_return: 'Return Or Exchange',
    subject_refund: 'Request Refund/Cancel Order',
    payment_time: 'Time of Payment',
    response_time: 'Expected response time: 1 business day',
    select_order: 'Please select your order',
    order_status_confirmed: 'Confirmed',
    order_status_canceled: 'Canceled',
    order_status_pendding: 'Pending',
    order_status_paid: 'Paid',
    order_status_partially_refunded: 'Partially refunded',
    order_status_refunded: 'Refunded',
    order_status_held: 'Held',
    rate: 'Rate',
    unsatisfied: 'Unsatisfied',
    you_can_rate: 'You can comment customer service here.',
    please_rate: 'Please rate my service',
    click: 'For all Q&As Please Click on our',
    page: "page",
    find_nothing: "Can't find the answer you are looking for?",
    order_processing: "Order Processing",
    tracking: "Logistics Tracking",
    return_tag: "Return & Refund",
    product_tag: "Product & Stock",
    payment_tag: "Payment & Promos",
    account_isssues: "Account Issues",
    ticket_time: 'ONLY for After-Sale Issue（Cancel/Edit Order/Shipping Status/Return/Exchange etc.)',
    online_time: 'ONLY for Pre-Sale Issue. Online sales do not have access to order information.',
    TicketTime: 'ONLY for After-Sale Issue（Cancel/Edit Order/Shipping Status/Return/Exchange etc.)',
    OnlineTime: 'ONLY for Pre-Sale Issue. Online sales do not have access to order information.',
    result_for: "results for \"{search}\"",
    question: "Have more questions?",
    article_helpful: "Was this article helpful?",
    related: "Related Articles",
    noTicket: "no Ticket",
    textarea_placeholder: "Type a message here...",
    response_time: "Expected response time: Within 24h",
    select_tip: 'Please select your question type',
    status_wating: 'Wating for Replied',
    submit_tips: "The reasons below are optional. You can click “X” if you don't want to choose any of them.",
    description_ph: 'Please describe your problem as much as possible so that customer service can respond faster…',
    upload_image: 'Upload image',
    rate: 'Rate My Service',
    rate_info: "We'd love to hear what you think of our customer service to help us to serve you better.",
    status_wating_apply: 'Wating for Apply',
    facebook_check: 'If you check this, we will send you logistics tracking status and order information in Messenger',
    return_the_oder: 'Return the Order',
    popular_search: 'Popular Searches:Refund,Return,Shipping',
    vip_service: 'VIP service',
    copied_successfully: "Copied Successfully", // CM1244
    share_the_code: "Share the code", // CM1165

    out_of_stock_policy: "Out of Stock Policy",
    check_mail_box: "A verification link has been sent to your email address, please check your mailbox.",
    success_will_send_latest: "Success! We will send our latest newsletter to your new email address.",
    upload_file_size_limit: 'File size must under 2MB',

    order_processing_shipping_date: 'We usually take 3 to 7 business days to process your order.', // shippingDate
    my_order: "My Order",
    my_services: "My Service",
    set_password_more_discount: "Set your password to get more discount!",   //google

    time_must_current: "Date of birth must be less than the current time!",

    recently_viewed: "Recently Viewed",
    you_not_recently_view: "You have not viewed anything.",
    website_trends: "{website} trends",

    verify_check_order: "Check Order",
    shipping_cost: "shipping cost",// shippingCost
    register_and_get: 'Register on {siteName} to track your order and get more discount!',
    please_input_password_first: 'Please input password first',
    check_order_msg: 'Now, you can check your order in your account. Please check “My order” under “Me” to track your order.',
    total_count_pages: "Total {count} Pages",
    saved: "Saved",
    use_max_coupon: "Don't forget to use the {coupon} coupon!",
    up_to_saving: "up to {coupon} savings",

    please_select_order_first: 'Please select an order first!',
    exprct_to_save: "Expect to Save",
    max_save: "Max Save",
    step_1_address_content: "To see items that ship to a different Location, change your delivery address.",
    next_step: "Next Step",
    step_2_select_content: "Click here to select items that you need to CHECKOUT.",
    got_it: "Got It",
    step_2_checkout_content: "Apply a Coupon Code and/or {site} Points on the next step.",
    shipping_fee: "رسم الشحن",
    more_products: "More Products",
    change: 'Change',

    bottomLengthNote: "Measure from the waistband to the leg opening or hem.",
    inseam: "Inseam",
    inseamNote: "Measure the length from the crotch seam to the bottom of the leg.",
    yourBustNote: "Measure the circumference over the fullest part of your bust.",
    yourUnderBust: "Your Under Bust",
    underBustNote: "Measure the circumference over the underline of your bust.",
    swimBustNote: "Measure the circumference over the fullest part of your breast.",
    swimUnderBustNote: "Measure straight across the underbust band from edge to edge.",
    swimWaistNote: "Measure your waist at the thinnest place.",
    swimHipNote: "Measure the fullest part of your hips.",
    switchTo: "Switch to",

    underBust: 'under bust',
    bustDifference: "Bust - Under Bust Difference",
    expires_soon: "Expires soon",
    sign_in_check_coupon: "Sign in to check more coupon",

    footWidth: 'Foot Width',
    footLengthNote: 'Measure the maximum length of your foot.',
    footWidthNote: 'Measure the maximum width of your foot.',

    share: "share",
    collect_now: "Collect Now",
    gift_card_receive: 'An exclusive gift card has been placed in your coupon pack',
    get_gift_card_from_share: "You'll get an exclusive gift card shared by your friends",
    wholesale: "Wholesale", // CM0828
    pre_wholesale: "PRE-Wholesale",
    after_wholesale: "AFTER-Wholesale",

    apply_before_expires: "Apply it before expires!",
    gift_has_been_cart: "The gift has been added to your cart!",
    mystery_gifts_giveaway: "mystery gifts & big giveaway",

    got_extra_chance_to_win: "You've got an exclusive VIP offer and a EXTRA CHANCE to win big prize!",
    collect_and_draw: 'Collect & Draw now',

    how_rate_website: "How would you rate {website}?",
    please_rate_by_stars: "Please rate {website} by stars: 1 to 5",
    close: "Close",

    purchase_protection: "Purchase Protection",
    efficient_logistics: "Efficient Logistics",
    customer_service: "Customer Service",
    privacy_protection: "Privacy Protection",
    proceed_to_checkout: "الشروع في الخروج",

    enjoy_discount_add_items: "Enjoy discounts only if you add items from this list to your cart.",
    add_on_items: "Add-on Items",

    with_shipping_price: 'with shipping price',
    modify: 'modify',
    please_sure_address_correct: 'Please make sure the address you fill is correct',
    login_to_receive: "Login To Receive",

    bottoms: 'BOTTOMS',
    coat: "COAT",
    lingerie: 'LINGERIE',
    tops: 'TOPS',

    addOnItemMsg: 'Add-on items extra discount',
    extraDiscount: 'Extra Discount',
    cheapThanAdd: 'cheaper than added',

    can_find_your_size: "؟ اخبرنا ما هو مقاسك",
    tell_us_size: "ليس مقاسك",
    thanks_your_feedback: "Thanks for your feedback.",
    submit_successful: "Submit Successful",
    we_found_similar_size: "We also found similar items for you in size",
    we_optimize_based_feedback: "We will optimize the size range based on your feedback.",
    similar_item: "Similar Items",
    not_find_anything_similar_search: "We're sorry that we couldn't find anything similar to what you were searching for.",

    smaller_than: "Smaller than",
    larger_than: "Larger than",
    spin_to_win_for_mom: 'Spin to win for Mom!',
    not_your_vibe_there_more: 'Not your vibe? There is more!',
    picks_for_you: 'Picks for you',
    add_to_home_screen: "Add {name} To Home Screen",

    collect_share: 'Collect & Share',
    link_expired: 'Sorry, this link has expired',
    link_valid_for_7_days: 'It was only valid for 7 days after it was shared.',
    share_coupon_expired: 'Sorry, the Shared Coupon has Expired',
    reach_coupon_limit: 'Sorry, You\'ve Reached the Limit for Coupon Claims',

    your_app_is_outdated: 'Your App Version is OutDated',
    user_time_limit: 'Sorry,You\'ve Already Received the Coupon.Cannot Be Claimed Again',

    limited_discount: 'Limited Discount',
    enjoy_free_shipping: 'Enjoy Free Shipping',

    set_the_password: "Set the Password",
    send_subscribe_message: "Click to agree promotional messages from {website} via WhatsApp or messenger!  You can unsubscribe at any point.",
    enter_whatsapp_number: "Please enter your WhatsApp number",
    send_to_whatsapp: "Send to WhatsApp",
    please_select_location: "Please select your location",
    subscribe_success: "Subscribe success!",
    subscribe_filed: "Subscribe faild!",
    whatsapp_number: "WhatsApp Number",
    receive_offers_message: "I' d like to receive exclusive offers and news via SMS.",

    status: 'Status',
    wish_list_filter_empty: 'No results found. Consider trying different options.',
    wish_list_nothing: 'You currently have nothing saved."Heart" an item to start your wishlist.',
    click_to_draw: 'Click to draw',

    wrong_zip_code_us: 'ZIP/Postal code should be 5 digits or 5 digits with a 4-digit number, e.g. 20001 or 20001-0000',
    wrong_zip_code_uk: 'ZIP/Postal code should contain 6-8 letters, digits and spaces in the format M2 5BQ, CW8 3AD, TN37 7HL.',
    wrong_zip_code_br: 'ZIP/Postal code must contain 8 digits separated by a hyphen, in the following format: 12345-123',
    wrong_zip_code_mx: 'ZIP/Postal code should be a 5-digit number, e.g. 12345',
    wrong_zip_code_de: 'The postal code should consist of at least 5 digits. Example: 12345',
    wrong_zip_code_fr: 'The postal code must contain 5 digits, eg. 12345',
    wrong_zip_code_es: 'The postal code number must contain 5 digits. The first number cannot be 6, 7, 8 or 9.',
    wrong_zip_code_it: 'The postal code should be a 5-digit number, e.g. 12345',
    wrong_zip_code_at: 'The postal code should consist of 4 digits. Example: 1234',
    wrong_zip_code_be: 'ZIP/Postal code should consist of 4 digits. Example: 1234',
    wrong_zip_code_au: 'ZIP/Postal Code should be a 4-digit number, e.g. 1234',
    wrong_zip_code_bg: 'ZIP/Postal Code should be a 4-digit number, e.g. 1234',
    wrong_zip_code_ca: 'The ZIP code format must be: letters + numbers + letters + spaces + numbers + letters + numbers, e.g. A1B 2C3',
    wrong_zip_code_ch: 'The postal code should consist of 4 digits. Example: 1234',
    wrong_zip_code_cy: 'ZIP/Postal Code should be a 4-digit number, e.g. 1234',
    wrong_zip_code_cz: 'The ZIP/Postal Code should be 5 digits and 1 spaces in the format 123 45.',
    wrong_zip_code_ee: 'ZIP/Postal Code should be a 5-digit number, e.g. 12345',
    wrong_zip_code_fi: 'The ZIP/Postal Code should be a 5-digit number, e.g. 12345',
    wrong_zip_code_gr: 'The ZIP/Postal Codeshould be 5 digits and 1 spaces in the format 123 45.',
    wrong_zip_code_hr: 'ZIP/Postal Code should be a 5-digit number, e.g. 12345',
    wrong_zip_code_ie: 'The ZIP/Postal Code should contain 7 letters, digits and spaces in the format A65 F4E2, T45 D868, T12 FPK5.',
    wrong_zip_code_lt: 'The ZIP/Postal Code should be a 5-digit number, e.g. 12345',
    wrong_zip_code_lu: 'ZIP/Postal Code should be a 4-digit number, e.g. 1234',
    wrong_zip_code_lv: 'The ZIP/Postal Code should be a 4-digit number, e.g. LV-1234',
    wrong_zip_code_nl: 'The zip code/postcode should consist of 4 digits followed by a space and end with 2 letters, e.g., 2561 DV.',
    wrong_zip_code_no: 'The ZIP/Postal Code should be a 4-digit number, e.g. 1234',
    wrong_zip_code_nz: 'ZIP/Postal Code should be a 4-digit number, e.g. 1234',
    wrong_zip_code_pl: 'The ZIP/Postal Code should be 5 digits and a hyphen in the format 12-345.',
    wrong_zip_code_pt: 'The postal code must contain 7 digits separated by a hyphen, in the following format: 1234-123',
    wrong_zip_code_ro: 'ZIP/Postal Code should be a 6-digit number, e.g. 123456',
    wrong_zip_code_se: 'The ZIP/Postal Code should be a 5-digit number, e.g. 12345',
    wrong_zip_code_dk: 'The ZIP/Postal Code should be a 4-digit number, e.g. 1234',
    wrong_zip_code_se: 'ZIP/Postal Code should be a 5-digit number, e.g. 12345',
    wrong_zip_code_dk: 'ZIP/Postal Code should be a 4-digit number, e.g. 1234',

    systemEmailNotice: 'Notice: Please update your email to receive important notifications from us. Click to update now.',
    wishListEmptySignIn: 'Wishlist empty. Sign In to view your Wishlist.',
    country_code: "Country code",
    select_country_code: "Please select the correct country code",

    local_policy_pay_tax: "According to local policy, customers will need to pay a tax on foreign exchange transactions when choosing this payment method.",
    returning: "Returning",
    select_to_be_returned: "Please select the item(s) to be returned",
    cannot_be_returned: "This kind of item cannot be returned",
    are_being_returned: "Item(s) are being returned",
    please_select_the_items: "Please select the item(s)",
    select_reason_all_be_returned: "Please select the reason for all items to be returned",
    please_select_the_reason: "Please select the reason",
    please_submit_further_details: "Please submit further details",
    descrive_problem_customer_service_respond: "Please describe your problem as much as possible so that customer service can respond faster",
    require_support: "Require Support",

    download: "Download",
    download_all: "Download All",
    download_products_images: "Download Product's Images",

    drop_shipping: "Drop Shipping",
    limit_time_only: "limited time only",

    ruler_min: 'The ruler is at its minimum',
    ruler_max: 'The ruler is at its maximum',
    swiper_to_add: 'Swipe to add your body information',
    save_your_body_mea: 'We\'ll save your body measurements to ensure a perfect fit for your future purchases.',
    mea_perf: 'Preference',
    safe_payment_options: "Safe Payment Options",

    influencer_outfit: "Influencer Outfit",

    sign_in_paypal: "تسجيل الدخول باستخدام PayPal",
    ends_today: 'ends today',

    selected: 'selected',
    addon_reach_limit: "You've reached the limit for selected add-on items.Click \"Selected\" to undo your selection.",

    phone_number_or_email_address: "رقم الموبايل أو عنوان البريد الإلكتروني ",
    reset_password_via_email: "Reset password via email address",
    reset_password_via_phone: "Reset password via phone number",
    welcome_back: "Welcome Back",
    please_sign_in_account: "Please sign in with your account.",
    please_create_your_account: "Please create your account.",
    code: 'Code',

    sign_in_with:"تسجيل الدخول باستخدام {mode}",

    incorrect_email_address:"Incorrect email address",


    privacy_protection: 'Privacy protection',
    security_privacy: 'Security & Privacy',
    register_success: "You have successfully registered",

    type:'type',
    spin_to_win:'Spin to Win',
    you_run_out_of_chance: "You've run out of chances",
    get_all_in_app: 'get all in app',
    app_new_user_gift: 'app new user gift',
    ends_in: 'ends in',

    cvv_tip: 'For a successful payment, please enter your Credit Card Verification Number (CVV). You can find the CVV number on the back of your credit card.',

    promotion_details: "Promotion Details",
    estimated_price: "Estimated Price",
    buy_now_enjoy: "Buy now to enjoy the following benefits",
    discount_price:'Discount Price',
    original_price: 'Original Price',
    estimated:'Estimated',
    discount_shown_estimated: 'Discounts shown are estimated and subject to coupon rules. Final price may vary.',
    promotions: 'Promotions',
    get_coupon: 'Get Coupon',
    best_coupon: 'Best Coupon',

    app_exclusive: 'App Exclusive',
    unsubscribe_message: 'Resubscribe with {email} on {website} and get coupons.',
    subscribe_and_get: 'Resubscribe & Get Coupons',

    quick_ship: 'QuickShip',
    provide_faster_delivery_service:'will provide a faster delivery service',
    between: 'between',
    eligible_for_quick_ship:'are eligible for QuickShip',
    consent_cookie_dsc: 'We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from.',
    consent_btn_settings: 'Cookies settings',
    consent_btn_accept: 'Accept',
    consent_btn_decline: 'Decline',
    consent_about: 'About Cookies',
    consent_setting_1_title: 'Necessary',
    consent_setting_1_status: 'Always active',
    consent_setting_1_dsc: 'These cookies are essential for the website to function properly. They enable basic functions such as page navigation and access to secure areas of the website. The website cannot function properly without these cookies, and they cannot be disabled.',

    consent_setting_2_title: 'Analytical Cookies',
    consent_setting_2_dsc: 'Analytical cookies provide information about how this website is used. This enhances the user experience. The collected data is aggregated and anonymized.',
    
    consent_setting_3_title: 'Advertising Cookies',
    consent_setting_3_dsc: 'Advertising cookies provide information about user interactions with ChicMe content. This helps us better understand the effectiveness of the content of our emails and our website.',
    consent_btn_accept_all: 'Accept All',
    consent_btn_save_settings: 'Save settings',
}

