import { 
	fetchProductDetails, 
	fetchMessageGetList, 
	fetchReviewComments, 
	fetchProductCouponMessage, 
	fetchProductDetailsReview, 
	fetchProductMenus, 
	getCountryMessage, 
	fetchProductOftenWith, 
	fetchABTesting, 
	getProductOffInfo, 
	fetchProductShopTheLook ,
	fetchConfig,
	fetchProductShopTheLookNew,
	getProductTaglia,
	getProductClog
} from '../../../api'

export const PRODUCT_SET_VO = 'PRODUCT_SET_VO'
export const PRODUCT_GET_TRANSLATE_DATA = "PRODUCT_GET_TRANSLATE_DATA";
export const PRODUCT_GET_REVIRE_COMMENTS = "PRODUCT_GET_REVIRE_COMMENTS";
export const PRODUCT_GET_REVIRE_COMMENTS_LOADING = "PRODUCT_GET_REVIRE_COMMENTS_LOADING";
export const PRODUCT_GET_REVIRE_COMMENTS_SKIP = "PRODUCT_GET_REVIRE_COMMENTS_SKIP";
export const PRODUCT_GET_REVIRE_COMMENTS_FINISHED = "PRODUCT_GET_REVIRE_COMMENTS_FINISHED";
export const PRODUCT_GET_ADD_TO_CART_COUPON_MESSAGE = "PRODUCT_GET_ADD_TO_CART_COUPON_MESSAGE";
export const PRODUCT_SET_PRODUCT_IFRAME = "PRODUCT_SET_PRODUCT_IFRAME";
export const PRODUCT_SET_PRODUCT_SUMMARY = 'PRODUCT_SET_PRODUCT_SUMMARY'
export const PRODUCT_SET_CATEGORIES = 'PRODUCT_SET_CATEGORIES'
export const PRODUCT_SET_DISCOUNT_MESSAGE = 'PRODUCT_SET_DISCOUNT_MESSAGE'
export const SET_PRODUCT_OFTEN_WITH_PRODUCTS = 'SET_PRODUCT_OFTEN_WITH_PRODUCTS'
export const SET_PRODUCT_OFTEN_WITH_NO_DATA = "SET_PRODUCT_OFTEN_WITH_NO_DATA";
export const SET_PRODUCT_AB = 'SET_PRODUCT_AB'
export const PRODUCT_ZOOM_IMAGE_POPOVER_PC = "PRODUCT_ZOOM_IMAGE_POPOVER_PC"
export const PRODUCT_SET_BOTTOM_BANNER = 'PRODUCT_SET_BOTTOM_BANNER'
export const PRODUCT_SET_PROMOTIONS = 'PRODUCT_SET_PROMOTIONS'
export const PRODUCT_SET_SHOP_THE_LOOK = 'PRODUCT_SET_SHOP_THE_LOOK'
export const PRODUCT_SET_CLEARPAY = 'PRODUCT_SET_CLEARPAY'
export const PRODUCT_SET_TAGLIA = 'PRODUCT_SET_TAGLIA'
export const PRODUCT_SET_CLOG = 'PRODUCT_SET_CLOG'


export const setProductClog = payload => ({
	type: PRODUCT_SET_CLOG,
	payload
})

export const setClearpay = payload => ({
	type: PRODUCT_SET_CLEARPAY,
	payload
})

export const setShopTheLookData = payload => ({
	type: PRODUCT_SET_SHOP_THE_LOOK,
	payload
})

export const setProductTaglia = payload => ({
	type: PRODUCT_SET_TAGLIA,
	payload
})

export const setPromotions = payload => ({
	type: PRODUCT_SET_PROMOTIONS,
	payload
})

export const setBottomBanner = payload => ({
	type: PRODUCT_SET_BOTTOM_BANNER,
	payload
}) 

export const setProductAb = payload => ({
	type: SET_PRODUCT_AB,
	payload
})

export const setDiscountMessage = payload => ({
	type: PRODUCT_SET_DISCOUNT_MESSAGE,
	payload
})

export const setCategories = payload => ({
	type: PRODUCT_SET_CATEGORIES,
	payload
})

export const setProductSummary = payload => ({
	type: PRODUCT_SET_PRODUCT_SUMMARY,
	payload
})

export const setProductVO = payload => ({
	type: PRODUCT_SET_VO,
	payload
})

export const setProductTranslateData = (payload) => ({
	type: PRODUCT_GET_TRANSLATE_DATA,
	payload
})

export const setProductReviewComments = (payload) => ({
	type: PRODUCT_GET_REVIRE_COMMENTS,
	payload
})

export const setProductReviewCommentsLoading = (payload) => ({
	type: PRODUCT_GET_REVIRE_COMMENTS_LOADING,
	payload
})

export const setProductReviewCommentsSkip = (payload) => ({
	type: PRODUCT_GET_REVIRE_COMMENTS_SKIP,
	payload
})

export const setProductReviewCommentsFinished = (payload) => ({
	type: PRODUCT_GET_REVIRE_COMMENTS_FINISHED,
	payload
})

export const setProductCouponMessage = (payload) => ({
	type: PRODUCT_GET_ADD_TO_CART_COUPON_MESSAGE,
	payload
})

export const setProductIframe = (payload) => ({
	type: PRODUCT_SET_PRODUCT_IFRAME,
	payload
})

export const setOftenWithProducts = payload => ({
	type: SET_PRODUCT_OFTEN_WITH_PRODUCTS,
	payload
})

export const setOftenWithNoData = payload => ({
	type: SET_PRODUCT_OFTEN_WITH_NO_DATA,
	payload
})

export const setZoomImagePopover = payload =>({
	type:PRODUCT_ZOOM_IMAGE_POPOVER_PC,
	payload
})




export const action_fetch_product_vo = (id, params, headers) => {
	return async dispatch => {
		const response = await fetchProductDetails(id, params,headers)
		if (response?.code === 200) {
			dispatch(setProductVO({...response.result, params, serverTime: response?.serverTime}))
			return response.result
		} else {
			return null
		}
	}
}

export const action_fetch_product_taglia = (id, headers) => {
	return async dispatch => {
		const response = await getProductTaglia(id, headers)
		if (response?.code === 200) {
			dispatch(setProductTaglia(response?.result))
			return response.result
		} else {
			dispatch(setProductTaglia(null))
			return null
		}
	}
}

export const action_fetch_product_detail_translateData = (code, headers) => {
	return async dispatch => {
		const response = await fetchMessageGetList(code, headers);
		if (response.code === 200) {
			dispatch(setProductTranslateData(response.result));
			return response.result;
		} else {
			return null;
		}
	}
}

export const action_fetch_product_review = (productId, skip) => {
	return async dispatch => {
		dispatch(setProductReviewCommentsLoading(true));

		const response = await fetchReviewComments(productId, skip);
		if (response.code === 200) {
			dispatch(setProductReviewComments(response.result));
			dispatch(setProductReviewCommentsSkip(12));
			dispatch(setProductReviewCommentsLoading(false));

			if (!response?.result || response?.result?.length < 1) {
				dispatch(setProductReviewCommentsFinished(true))
			}
			return response.result;
		} else {
			return null;
		}
	}
}

export const action_fetch_product_coupon_message = () => {
	return async dispatch => {
		let response = await fetchProductCouponMessage();
		if (response.code === 200) {
			dispatch(setProductCouponMessage(response.result));
			return response.result;
		} else {
			return null;
		}
	}
}

export const action_fetch_product_summary = (productId, headers) => {
	return async dispatch => {
		let response = await fetchProductDetailsReview(productId, headers)
		if (response?.code === 200) {
			dispatch(setProductSummary(response.result))
			return response.result
		} else {
			return null
		}
	}

}

export const action_fetch_product_menus = (productId, headers) => {
	return async dispatch => {
		let response = await fetchProductMenus(productId, headers)
		if (response?.code === 200) {
			dispatch(setCategories(response.result))
			return response.result
		} else {
			return null
		}
	}
}

export const action_fetch_product_discount_message = headers => {
	return async dispatch => {
		let response = await getCountryMessage('M1340', headers)
		if (response.code === 200) {
			dispatch(setDiscountMessage(response.result))
			return response.result
		} else {
			return null
		}
	}
}

export const action_fetch_often_with_products = (productId, headers) => {
	return async (dispatch,getState) => {
		const {pageProduct} = getState();
		if(!pageProduct?.oftemWithNoData && !pageProduct?.oftenWithProducts?.length > 0){
			let response = await fetchProductOftenWith(productId,headers);
			if (response.code === 200) {
				let productItems = [];
				if(response.result?.length > 0){
					productItems = response.result?.map(p => ({
						...p,
						geekoRequsestId: response.requestId,
						geekoExperimentId: response.experimentId,
						layerName: response.layerName,
						trackingRecords: response.trackingRecords
					}))
	
					dispatch(setOftenWithProducts(productItems));
				}else{
					dispatch(setOftenWithNoData(true))
				}

				return productItems;
			} else {
				return null;
			}
		}else{
			return null;
		}
	}
}

export const action_fetch_ab = headers => {
    return async dispatch => {
        const response = await fetchABTesting('0268-W', headers).catch(e => ({result: "A"}))
        if(response?.code === 200){
            dispatch(setProductAb(response.result))
        }
        return response?.result
    }
}

export const action_fetch_bottom_banner = headers => {

	return async dispatch => {
		let response = await getCountryMessage('M1674', headers)
		if (response.code === 200) {
			dispatch(setBottomBanner(response.result))
			return response.result
		} else {
			return null
		}
	}

}

export const action_fetch_clearpay = headers => {
	return async dispatch => {
        const response = await fetchConfig('0341-W', headers)
        if(response?.code === 200){
            dispatch(setClearpay(response.result))
        }
        return response?.result
    }
}

export const action_fetch_promotions = (id, headers) => {
	return async dispatch => {
		// let response = await getCountryMessage('M1676', headers)
		let response = await getProductOffInfo(id, headers)
		if (response.code === 200) {
			dispatch(setPromotions(response))
			return response
		} else {
			return null
		}
	}
}


export const action_fetch_shop_the_look = (productId, headers) => {
	return async dispatch => {
		let response = await fetchProductShopTheLook(productId, headers)
		if (response.code === 200) {
			dispatch(setShopTheLookData(response.result))
			return response.result
		} else {
			return null
		}
	}
}




export const action_fetch_shop_the_look_new = (params, headers) => {
	return async dispatch => {
		let response = await fetchProductShopTheLookNew(params, headers)
		if (response.code === 200) {
			dispatch(setShopTheLookData(response.result))
			return response.result
		} else {
			return null
		}
	}
}


export const action_fetch_clog = (params, headers) => {
	return async dispatch => {
		let response = await getProductClog(params, headers)
		if (response.code === 200) {
			dispatch(setProductClog(response.result))
			return response.result
		} else {
			return null
		}
	}
}

