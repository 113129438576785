import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import Item, { EmptyItem } from '../item'
import CollectionCard from '../collection-card'
import SmartCard from '../smart-card'

import { withList as withPage } from '../../hocs/pagination-list'
import { withList } from '../../../../../components/list'
import Pagination from '../../../../components/pagination'

import SimpleItem from '../item/simple-item'
import FlashItem from '../item/flash-item'
import PreorderItem from '../item/preorder-item'

export const Products = props => {
	useStyles(classes)
	const { products, column, sensors, product_type, filter, isFlash, isPreOrder } = props



	return <div className={props?.className || classes.Listing}>
		{
			products?.map((product, index) => {


				// product.isPreOrder = true
				// product.promotion = {
				// 	"promotionPrice": {
				// 		"amount": "12.59",
				// 		"unit": "$",
				// 		"currency": "USD"
				// 	},
				// 	"startTime": 1708480475162,
				// 	"endTime": 1708919675161,
				// 	"type": "11",
				// 	"countdownHidden": false,
				// 	"orderQty": 0,
				// 	"orderQtyThreshold": 15,
				// 	"estimatedShippedTimeMsg": "02 Mar - 07 Mar",
				// 	"enabled": true,
				// 	"serverTime": 1708503929067,
				// 	"isAppOnly": false
				// }


				if (product.isCollection) {
					return <CollectionCard sensors={sensors} position={index} collection={product} key={product.id} />
				}
				if (product.isSmartCard) {
					return <SmartCard sensors={sensors} position={index} smartCard={product} key={product.id} />
				}
				return (product.isPreOrder && isPreOrder) ? <PreorderItem ilter={filter} product_type={product_type} sensors={sensors} column={column} position={index} product={product} key={product.id} /> : (isFlash ? <FlashItem filter={filter} product_type={product_type} sensors={sensors} column={column} position={index} product={product} key={product.id} /> : <Item filter={filter} product_type={product_type} sensors={sensors} column={column} position={index} product={product} key={product.id} />)

			})
		}
	</div>
}

export const SimpleProducts = props => {
	useStyles(classes)
	const { products, column, sensors, product_type, giftInfo, isLuckyDraw, isAddOn } = props
	return <div className={props?.className || classes.Listing}>
		{
			products?.map((product, index) => {
				if (!product.isCollection && !product.isSmartCard) {
					return <SimpleItem product_type={product_type} isLuckyDraw={isLuckyDraw} isAddOn={isAddOn} giftInfo={giftInfo} sensors={sensors} column={column} position={index} product={product} key={product.id} />
				}
			})
		}
	</div>
}

export const EmptyProducts = props => {
	useStyles(classes)
	const { count = 20 } = props
	return <div className={props?.className || classes.Listing}>
		{
			Array.from(new Array(count)).map((_, index) => <EmptyItem key={index} />)
		}
	</div>
}

const ListingProducts = withList(Products)
const PageProducts = withPage(Products, Pagination)

export const PageListProducts = class extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return <PageProducts {...this.props} />
	}
}

export default class extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return <ListingProducts {...this.props} />
	}
}