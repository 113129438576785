export default {
    sort: 'Clasificación',//CM0768
    filter: 'Filtros',//CM0930
    loading: 'cargando',//CM0380
    finished: 'Acabado',//
    home: 'Comprar',//CM0141
    get: 'Get',//
    currency: 'Moneda',//CM0345
    language: 'Idioma',//CM0655
    order_tracking: 'Seguimiento de pedidos',//CM0825
    shipping_info: 'Informaci\u00f3n de env\u00edo',//CM0837
    return_policy: 'Pol\u00edtica de devoluciones',//CM0105
    privarcy_policy: 'T\u00e9rminos de privacidad y pol\u00edtica de seguridad',//CM0835
    get_app: 'Obtenga la aplicaci\u00f3n {app}',//
    color: 'COLOR',//CM0143
    size: 'Talla',//CM0142
    add_to_bag: 'A\u00f1adir a la bolsa',//
    clear: 'Borrar',//CM0855
    apply: 'Aplicar',//CM0545_1
    search: 'búsqueda',//
    i_shipping_for: 'Estoy comprando...',//CM0112
    cancel: 'Cancelar',//CM0765
    hot_search: 'M\u00e1s buscados',//CM0705
    items: 'Art\u00edculos',//CM1032
    view_more: 'Ver todo', //CM0153
    sold_out: 'Agotado',//CM0366
    view_all: 'Ver todo',//CM0797
    thanks_subscribe: 'Gracias por suscribirse',//CM0956
    thanks_enjoy: 'Compruebe su correo electr\u00f3nico para obtener el nombre de usuario y la contrase\u00f1a.',//CM0957
    subscribe_title: '¡Suscr\u00edbete para ofertas exclusivas!',//
    subscribe: 'SUSCR\u00cdBASE',//CM0091
    smufcme:'Inscribirme para recibir {brand} correos electr\u00f3nicos (puede cancelar la suscripci\u00f3n en cualquier momento).',//CM0967
    c_info: '{brand} Informaci\u00f3n',//CM0830
    about_us: 'Acerca de nosotros',//CM0095
    affiliate: 'Afiliado',//CM0823
    blogger_program: 'Programa Blogger',//CM0824
    help_support: 'Ayuda y soporte',//CM0831
    shipping_policy: 'Pol\u00edtica de env\u00edo',//CM0106
    faq: 'Preguntas frecuentes',//CM0099
    order_tracking: 'Seguimiento de pedidos',//CM0825
    coupons: 'Cupones',//CM0188
    bonus_points: 'Puntos extra',//CM1035
    customer_care: 'Atenci\u00f3n al cliente',//CM0832
    contact_us: 'Contacte con nosotros',//CM0097
    return_policy: 'Pol\u00edtica de devoluciones',//CM0105
    out_of_stock: 'Agotado',//CM0621
    wholesale_policy: 'Pol\u00edtica de compra mayorista',//CM0108
    drop_shipping_policy: 'Pol\u00edtica de env\u00edo directo',//CM0109
    i_p_rights: 'Intellectual Property Rights',
    privacy_policy: 'T\u00e9rminos de privacidad y pol\u00edtica de seguridad',//CM0835
    term_notice: 'Aviso de t\u00e9rminos y condiciones',//CM0836
    category: 'Categor\u00eda', //CM1019
    new_in: 'Nuevo',//CM0001
    bag: 'Bolso',//CM1021
    account: 'Yo',
    flash_sale: 'Ofertas rel\u00e1mpago',//CM0772
    ends_in: 'Finaliza en', //CM1009
    free_gift: 'Regalos gratis',
    multi_add: 'Lo sentimos, solo puede elegir un art\u00edculo como regalo gratis, si desea agregar otro art\u00edculo, este reemplazará el regalo gratis que ya agreg\u00f3.',
    learn_more: 'Aprende más',
    email: 'Email',
    recommend: 'Recomendados',
    confirm: 'confirmar',
    get_the_look:"mira", //CM1187
    previous:"anterior", //CM1188
    next:"Siguiente", //CM1189
    beauty:"Belleza", //CM0322
    lingerie:"Lencer\u00eda",  //CM0135
    shop_the_look:"Compra el look",
    good_to_know:"bueno saber", //CM1131
    time_left:"Tiempo restante", //CM0363
    limited_titme:"Tiempo limitado", //CM0907
    flash_sale:"Venta rel\u00e1mpago", //CM0451
    new:"NUEVO", //CM0456
    more:"M\u00e1s",
    qty:"Cantidad", //CM0200
    reviews:"Comentarios", //CM0370
    recommend:"Recomendar", //CM1034
    you_can_match_with:"Puede coincidir con", //CM1012
    related_searches:"B\u00fasquedas relacionadas", //CM1000
    too_small:"Demasiado peque\u00f1o", //CM0373
    ture_to_size:"Buen calce", //CM0372
    to_large:"Demasiado grande", //CM0371
    translare_to:"Traducir a", //CM1128
    translate:"traducir", //CM1127
    overall_fit:"Ajuste general",
    share_the_product_to:"Comparte el producto con",
    description:"descripci\u00f3n", //CM1130
    ingredients:"Ingredientes", //CM1132
    estimated_time:"Tiempo estimado de llegada", //CM1031
    model_stature:"Estatura de modelo", //CM0367
    model_wear:"Ropa de modelo", //CM1015
    height:"Altura", //CM0971
    bust:"Busto", //CM0172
    waist:"Cintura", //CM0173
    hips:"Cadera", //CM0174
    shipping_information:"Informaci\u00f3n de env\u00edo", //CM0149
    sizing_help:"Guía de Tallas", //CM1014
    product_details:"Descripción", //CM1013
    you_may_also_likes:"TAMBI\u00c9N LE PUEDE GUSTAR", //CM0154
    if_the_item_comes_back:"Si el art\u00edculo vuelve a estar disponible dentro de los 30 d\u00edas, le enviaremos un correo electr\u00f3nico.", //CM1136
    you_haveArrival_reminder:"Ya se ha suscrito al recordatorio de llegada", //CM1125
    sold_out_notification:"El art\u00edculo est\u00e1 agotado, haga clic en notificarme para la notificaci\u00f3n de reabastecimiento", //CM1126
    un_subscribe:"ANULAR SUSCRIPCI\u00d3N", //CM1123
    notify_me:"NOTIFICARME", //CM1124
    add_to_cart:"AÑADIR A LA BOLSA", //CM0145
    product_measurements:"Medidas del producto", //CM1183
    view_size_information:"Ver más información sobre tallas", //CM1129
    beauty_not_supported:"Las mercanc\u00edas de retorno y cambio no est\u00e1n soportadas.", //CM1134
    easy_return:"Devoluci\u00f3n f\u00e1cil", //CM1011
    find_simlar:"Buscar similar", //CM1135
    babies:"Beb\u00e9s",
    babies_look:"mirada de bebes",
    shipping_method:"M\u00E9todo de env\u00EDo", //CM0623
    ship_current_address:"Este art\u00EDculo no se puede enviar a la direcci\u00F3n actual", //CM1184
    show:"Show",
    index_subscribe_agree:"Al suscribirte, aceptas nuestros",
    privacy_cookie:"Privacidad y cookies",
    terms_conditions:"T\u00e9rminos y condiciones",
    and:"y",
    register:"Registrarse", //CM1204
    us_address_deliverd:"Se estima que la {value} se entregará el",
    us_address:"direcci\u00f3n de EE.UU.",
    enter_email_address:"Ingrese su direcci\u00f3n de correo electr\u00f3nico",
    mystery_offer:"OFERTA MISTERIOSA",
    get_mystery_offer:"No quiero mi oferta misteriosa",
    has_subscribe_to:"El correo electr\u00f3nico ha sido utilizado por otros. Por favor, especifique otro correo electr\u00f3nico.",
    no_more_data:"No hay m\u00E1s datos", //CM0381
    add:"agregar",
    shopping_for:"Estoy comprando", //CM0112
    buy_now: 'comprar ahora', //CM0665
    visual_search:"Búsqueda visual",
    upload_image_search_photo:"Toma una foto o sube una imagen para buscar elementos similares.",
    recommend:"Recomendardos", //CM1034
    new_arrivals:"Novedades", //CM0340
    price:"Precio", //CM0946
    take_a_photo:"Toma una foto",
    upload_a_image:"Subir una imagen",
    photo_upload_image:"Ahora puede buscar el art\u00edculo que desea tomando una foto o cargando una imagen.",
    not_match_fund:"No se encontr\u00f3 coincidencia",
    please_try_clearer_image:"Pruebe con una imagen m\u00e1s clara o coloque el producto que desea buscar en el medio de la imagen",
    try_text_to_search:"Intente enviar un mensaje de texto para buscar el elemento que desea",
    try_text_search:"Prueba la b\u00fasqueda de texto",
    try_again_with_image:"Int\u00e9ntelo de nuevo con la imagen",
    or:"o", //CM0547
    low_price:"Precio bajo",
    high_price:"Precio alto",
    register_success_message:"\u00A1Revise su bandeja de entrada para obtener su {coupon} y recompensa de {point}! Hemos enviado a {email} un correo electr\u00F3nico de confirmaci\u00F3n. El enlace es v\u00E1lido solo por 72 horas.", //CM1111
    register_success_message_phone: "\u00A1Revise su bandeja de entrada para obtener su {coupon} y recompensa de {point}!",
    sign_in:"Iniciar sesi\u00F3n", //CM0354
    or_join_with:"O únete a", //CM1236
    captcha_not_empty:"El CAPTCHA no puede estar vac\u00edo", //google
    forget_password:"Olvid\u00E9 la contrase\u00F1a", //CM0458
    email_address:"Direcci\u00F3n de correo electr\u00F3nico", //CM0272
    password:"Contrase\u00F1a", //CM0432
    six_characters_min:"6 caracteres m\u00EDnimo", //CM1100
    twenty_characters_max:"20 caracteres m\u00E1ximo", //CM1101
    no_emojis:"No emojis", //CM1102
    belong_get_points_500:"\u00A1500 puntos te pertenecer\u00E1n inmediatamente!", //CM1178
    finshed_register_shop:"\u00A1Complete el registro y obtenga puntos de recompensa para comprar", //CM1179
    register_privacy_policy:"Estoy de acuerdo con {term} y {privacy}", // CM1116 CM0462
    term_of_services:"T\u00E9rminos de servicio", //CM0103
    privacy_policy_only:"Pol\u00EDtica de privacidad", //CM0104
    create_account:"Crear cuenta", //CM0491
    coupon:"Cup\u00F3n", //CM0543
    points:"Puntos", //CM1202
    welcome_to:"Bienvenido a", //CM1107
    registration_success:"Registro exitoso", //CM1108
    reset_password:"Restablecer la contrase\u00F1a", //CM0443
    please_send_email_auto_matically:"Por favor ingrese la direcci\u00F3n de correo electr\u00F3nico que registr\u00F3 con nosotros. Se le enviar\u00E1 autom\u00E1ticamente un correo electr\u00F3nico de recuperaci\u00F3n de contrase\u00F1a.", //CM1089
    send_email:"Enviar correo electr\u00F3nico", //CM1090
    link_rest_password_to:"El enlace para restablecer la contrase\u00F1a se ha enviado a", //CM1091
    create_new_password_link_easy:"Para crear su nueva contrase\u00F1a, simplemente haga clic en el enlace del correo electr\u00F3nico que le enviamos - f\u00E1cil", //CM1092
    check_agin_enter_email_send:"\u00BFNo lo recibiste? Revise su correo basura o haga clic en el enlace a continuaci\u00F3n y le enviaremos uno nuevo.", //CM1093
    still_online_help:"Si a\u00FAn no puede encontrarlo, haga clic en \"Ayuda en l\u00EDnea\" para obtener ayuda.", //CM1094
    three_hours_expires:"Advertencia: \u00A1el enlace caduca en tres horas", //CM1095
    resend_email:"Reenviar email", //CM1115
    advanced_messenger:"Servicio de mensajer\u00EDa avanzado", //CM0948
    discount_deals_weekly:"Recibir\u00E1 c\u00F3digos de descuento y ofertas especiales semanalmente", //CM0950
    response_order_infomation:"Puede obtener una respuesta autom\u00E1tica sobre la informaci\u00F3n de la compra", //CM0951
    online_custoer_service:"Puede obtener servicio al cliente en l\u00EDnea", //CM0952
    continue:"Continuar", //CM0953
    password_incorrect:"\u00A1Contrase\u00F1a incorrecta! \u00BFOlvidaste tu contrase\u00F1a?", //CM1097
    try_again:"Int\u00E9ntalo de nuevo", //CM1099
    emaul_no_register_now:"Esta cuenta de correo electr\u00F3nico no est\u00E1 registrada, \u00BFquieres registrarte ahora?", //CM1103
    no_thanks:"NO, GRACIAS", //CM1104
    register_has_login_now:"Esta cuenta de correo electr\u00F3nico ha sido registrada, \u00BFquieres iniciar sesi\u00F3n ahora?", //CM1105
    log_in_now:"Iniciar sesi\u00F3n ahora", //CM1106
    view_on_app:"Ver en la aplicaci\u00f3n", //google
    today_no_change:"Hoy te quedaste sin oportunidades, \u00A1ve de compras!", //CM1140
    go_shopping:"Ir de compras", //CM1141
    player_again:"Juega de nuevo", //CM1142
    congratulations:"Felicitaciones", //CM0940
    please_try_again:"\u00A1Vaya! Vuelve a intentarlo", //CM1145
    go:"IR", //CM1180
    my_prize:"Mis premios", //CM1081
    rules:"Reglas", //CM0777
    download_app:"Descarga la APLICACI\u00D3N", //CM1082
    expire_in:"Caducar en", //CM1250
    please_select_size:"por fawor,selecciana Talla", // google
    ok:"Ok",// google
    my_prizes_rules:"Mis premios y reglas", //CM1192
    you_have:"Tienes", //CM1038
    changes:"Posibilidades", //CM1039
    customer_services_prizes:"Tome una captura de pantalla y {value} para comunicarse con el servicio al cliente para obtener su premio", //google
    click_here:"haga clic aqu\u00ED", //CM1064
    first_lottery_login:"Inicia sesión primero para jugar", // google
    count_items: "{total} art\u00edculos",
    today_no_change_for_app:"Hoy te has quedado sin oportunidades. ¡Consigue 1 oportunidad extra en {name} APP!",
    email_entered_try_again:"El correo electrónico que has introducido no es válido. Por favor, compruebe su correo electrónico e inténtelo de nuevo.",
    you_are_registerd:"Ya está registrado",
    subscribe_to_get_lottery_change:"*La oferta sólo puede ser canjeada por nuevos suscriptores. Al registrarse, acepta recibir correos electrónicos de marketing recurrentes de {name} en la dirección de correo electrónico utilizada al registrarse.",
    invalid_password:"Contraseña invalida",
    lucky_draw:"SORTEO\nSUERTE",
    lucky_draw_nobreak:"SORTEO SUERTE",
    fill_in_password:"Rellene la contraseña",
    subtext: "Nos gustar\u00EDa enviarle notificaciones sobre las \u00FAltimas noticias y actualizaciones.",//CM1008
    no_thanks: "No, gracias",//CM1117
    allow: "Permitir", //CM1118
    draw_now:"Dibujar\nAhora",
    take_simlar_item_image:"Tome una foto o cargue una imagen para encontrar art\u00edculos similares.",
    camera:"CÁMARA",
    images:"IMÁGENES",
    shop_similar:"Comprar similares",



    privacy_request_center: "Centro de solicitud de privacidad",
    valid_code_sent: "El código de verificación ha sido enviado, vaya a su correo electrónico para verificar el código de verificación",
    sending: 'Sending',//todo
    send: 'Enviar',
    submit_successfully: 'Enviado satisfactoriamente',
    thanck_request: 'Gracias por su petición. Un representante revisará su solicitud y se pondrá en contacto con usted en la dirección de correo electrónico que usted proporcionó.',
    country_is_required: 'Seleccione su pa\u00eds/región',
    name_is_required: 'Por favor ingresa tu nombre completo',
    account_is_required: 'El correo electrónico que ingresó no es válido', //todo
    account_is_invalid: 'El correo electrónico que ingresó no es válido',
    code_is_required: 'Por favor, introduzca el código de verificación',
    description_is_required: 'Por favor, describa su problema en detalle',
    country: 'Pa\u00eds/Región',
    place_holder_country: 'Elija su pa\u00eds/región',
    full_name: 'Nombre completo',
    place_holder_fullname: 'El nombre completo debe contener de 2-100 caracteres',
    your_account: 'Tu cuenta {webname}',
    place_holder_account: 'Necesitaremos que proporcione la dirección de correo electrónico asociada con su cuenta para encontrar los datos personales que hemos almacenado en nuestro sistema',
    verification_code: 'Código de verificación',
    detailed_description: 'Descripción detallada',
    place_holder_description: 'Introduzca una descripción detallada',
    i_agree_to_the_privacy_policy: 'Acepto que {webname} recopila y utiliza los datos que env\u00edo arriba de acuerdo con la Pol\u00edtica de {policy} procesar mi solicitud',
    submit: 'ENTREGAR',
    next_step: 'PRÓXIMO PASO',
    privacy_policy: 'privacidad y cookies para',
    verify_email_comfirm:"Por favor, asegúrese de que el correo electrónico que ha introducido {value} es correcto, si necesita continuar con el registro por favor haga clic en confirmar.",
    how_to_pay:"Como pagar",
    share_to:"Compartir a",
    copy_link:"Copiar enlace",
    copy_success:"Copia exitosa",
    get_draw_chance:"obtener 1 oportunidad extra",
    support_pre_sale_title: 'preventas', //CM0860
    support_pre_sale: 'Si tiene alguna pregunta antes de hacer una compra, puede chatear con nuestros operadores en l\u00EDnea para obtener m\u00E1s informaci\u00F3n.',//CM0861
    support_after_sale_title: 'posventa',//CM0862
    support_after_sale: 'Si necesita ayuda con temas de posventa, env\u00EDe un tique.',//CM0863
    online_help: 'Ayuda en l\u00EDnea',//CM0915
    submit_ticket: 'Enviar un tique',//CM0864
    search_for: 'Estoy comprando...',//CM0112
    clear_all: 'Borrar todo',//CM0931
    empty_cart_description: 'Su bolsa est\u00E1 vac\u00EDa',//CM0521
    you_selected: 'Usted seleccion\u00F3 {color}',//CM0475
    uploading_photo: 'Buscar subiendo una foto',//CM1298
    upload_photo: 'Subir foto',//CM0257
    price_low_to_high: 'Precios de menor a mayor',//CM0845
    price_high_to_low: 'Precios de mayor a menor',//CM0846
    shop_similar:'Comprar similares',//CM1304
    uploading: 'cargando',
    empty_filter_page: '\u00A1Oh, oh! No pudimos encontrar lo que buscaba. Intente usar menos filtros',//CM0955
    recently_search: 'B\u00FAsqueda reciente', //CM1017
    my_account: 'Mi cuenta', //CM0296
    my_orders: 'Mis pedidos', //CM0479
    my_message: 'Mis mensaje',//CM1119
    my_coupons: 'Mis cupones',//CM1120
    my_points: 'Mis Puntos', //CM1121
    view_bag: 'Revisar la bolsa',//CM0183
    sign_out: 'Desconectarse',//CM0254
    total: 'Total',//CM0180
    check_it_in_coupons: 'Compruébalo en [Mis cupones]',
    got_it: 'ENTENDIDO',
    congratulations_get: '¡Felicidades por conseguir {money}!',
    wait: '¡Espere',
    limit_coupon_unused: 'Todavía tienes cupón(es) limitado(s) sin usar',
    limit_24_time: 'SÓLO 24 HORAS',
    return_to_site: 'VOLVER AL SITIO',
    get_offer:"Obtener oferta",
    enter_email_to_reveal_offer:'ingrese su correo electrónico para revelar su oferta especial', //CM1256
    buy:"Comprar", //CM0242
    simply_special_price:"\u00A1Simplemente escanee el c\u00F3digo QR para instalar y prep\u00E1rese con el PRECIO ESPECIAL!", //CM1209
    model:"Modelo", // CM0970 
    size_guide:"Guía de Tallas", // CM0156
    weight:"Peso",
    customer_reviews:"Evaluaciones De Clientes", // CM1018
    size_chart:"Guía de Tallas", //CM0476
    support:"Soporte", //CM0094
    payment_method:"M\u00E9todo de pago", //CM0389
    website_rights_reserved:"{name} es una marca registrada de {domain}.Todos los derechos reservados.", //CM0834
    follow_us:"síganos", //CM0093
    shipping_time:"Plazo de env\u00EDo", //CM0377
    costs:"Costos", //CM0965
    shipping_to:"Destino", //CM0972
    receiving_time:"Tiempo de recepci\u00F3n", //CM0375
    precessing_time:"Tiempo de procesamiento", //CM0376
    products: 'Productos',
    promotions: 'Promociones',
    go_to_bag: "IR A LA BOLSA",
    total_peice_price:"Total {total} piezas | {price}",
    reset_qty:"Reiniciar",
    best_sellers:"Los más vendidos",
    best_sellers_in:"Los más vendidos en",

    product_unavailable:'Producto actualmente no disponible',
    save:"Guardar", //CM0212
    set:"establecer",
    buy_together:"Comprar juntos",
    add_successful:"Añadir con éxito",
    collect:"collect",
    collect_all:"recoger todo",
    free_cash: '¡Regalo de Dinero Gratis!',
    win_100: '¡Ganancia 100%!',
    ann_c_1: 'Cada Usuario Registrado tiene 1 oportunidad al día y ¡1 oportunidad extra después de Cada Compra!',
    ann_c_2: '*La oportunidad diaria se actualizará a las 00:00 UTC, del 17 al 23 de agosto.',
    counpon_has_sent: 'Se ha enviado {coupon} a su Cartera.',
    expire_content: '¡¡¡Caduca en 24h!!! ¡Aplícalo en la Caja! <br/> *No se puede utilizar para deducir gasto de envío.',

    lucky_winner: 'GANADOR DE LA SUERTE',
    view_prizes:"Ver premios",
    continue_shopping: 'Continuar comprando',
    go_wallet: 'A la billetera',
    open:'Abrir',
    shopping: 'Compras',
	check: 'cheque',

    day1:'1er día',
    day2:'2do día',
    day3:'3er día',
    day4:'4to día',
    day5:'5to día',
    day6:'6to día',
    day7:'7mo día',
    specialGift: 'Regalos especial',
    specialGifts: 'Regalos especiales',
    today: 'Hoy',
    checkInSuccess: 'Regístrese con éxito',
    checkDays: 'Te has registrado durante {day} días.',
    checkDay: 'Te has registrado durante {day} día.',
    checkRules: 'Reglas de registro de aniversario',
    openGift: '¡Toca para abrir el regalo especial!',
    openNotificationTip: 'No podemos enviarle notificaciones. Vaya a la configuración para abrirlo.',
    checkDaysLeft: ' Te has registrado durante {daysHave} días consecutivos. Regístrese durante días más para obtener más sorpresas.',
    remindMe: 'Recuérdame',
    downloadNow: 'Descargar ahora',

    alreadyChecked: 'Ya has hecho el check-in hoy',
    checkedNow: '50,000+ REGALOS, ¡¡¡APRESÚRATE!!!',
    checkInNow: 'Regístrese ahora',
    gift: 'Regalos',
    v_shop:"V-Tienda",
    comments:"Comentarios",
    chart_ask_question:"Chatear o hacer preguntas",
    tap_mystery_gift: '¡Toca para abrir el regalo misterioso!',
    mystery_gift: 'Regalo Misterioso',
    addtohometext: 'Agregar {site} a P\u00E1gina de inicio',
    // sizechart
    inches:'Pulgada',
    centimeters:'cm',
    sizeconversation:'Conversación de tamaño',
    size:'Talla',
    bust:"busto",
    waist: "cintura",
    hip: "cadera",
    length:"longitud",
    size_chart:"Tabla de tallas",
    size_help: "Guía de Tallas",
    fit_type:"Tipo de ajuste",
    stretch:"Estirar",
    product_measurements:"Medidas del producto",
    body_measurements:"Medidas del cuerpo",
    bust_size:"busto",
    waist_size:"Tamaño de la cintura",
    hip_size:"Tamaño de la cadera",
    your_bust:"Tu busto",
    your_waist:"Tu cintura",
    your_hips:"Tus caderas",
    measurements: 'Le sugerimos enfáticamente que tome sus medidas y las use como referencia al seleccionar el tamaño de un artículo. Mídete siguiendo la guía a continuación.',
    bust_note: "Mide debajo de tus brazos alrededor de la parte más completa de tu busto. ¡Asegúrate de mantener la cinta nivelada!",
    waist_note: "Asegúrate de que la cinta métrica se ajuste cómodamente mientras mides alrededor de la parte más estrecha de tu cintura natural",
    hip_note: "Párate con los pies juntos y mide alrededor de la parte más ancha de tus caderas.",
    length_note: "Para medir la longitud de su pie, siéntese en una silla y coloque el pie plano sobre una hoja de papel. Trace el pie sosteniendo un lápiz verticalmente y delineando el pie. Con una regla, mida la distancia desde la parte inferior del talón hasta la punta del dedo más largo. Registra el resultado en pulgadas y centímetros y usa estas medidas para encontrar tu talla en la tabla.",
    product_measurements_message:"* Estos datos se obtuvieron midiendo manualmente el producto, es posible que tengan una desviación de 1 a 2 cm.",
    measure_product_size:"¿Cómo medir el tamaño del producto?",
    body_measurements_message:"* Dependiendo de tu tipo de cuerpo y hábitos de vestir, los tamaños anteriores son solo para referencia.",
    measure_body_size:"¿Cómo medir tu cuerpo?",
    measure_body_size_bust:"Mide la circunferencia sobre la parte más completa de tu seno.",
    measure_body_size_waist:"Mide tu cintura en el lugar más delgado.",
    measure_body_size_hip:"Mide la parte más completa de tus caderas",
    size_fit:"Tamaño y ajuste",
    height_waist:"Altura peso",
    measure:"Mediciones",
    model_wear:"Desgaste del modelo",

    one_size: 'Talla única',
    code:"CÓDIGO",

    system_update: 'Actualización del sistema',
    system_update_back: 'Para servirle mejor, estamos actualizando nuestros sistemas. ¡Volveremos enseguida!',
    policy:"Política",
    after_pay_faq: "Afterpay FAQ",
    klnara_policy_message:"¡Compre ahora, pague después con Klarna!",
    zip:"Zip",
    vip:"Vip",
    delivery:"Entrega",
    about_my_wallet:"Acerca de mi billetera",
    about_early_bird:"Acerca de EarlyBird",
    clear_pay:"Clearpay",
    sold_percent: "{percent}% vendido",
    purchase_limit: "Límite de compra: {qty} cantidad",

    agree_login_policy_message:"Antes del siguiente paso, marque para aceptar los t\u00E9rminos de uso y la pol\u00EDtica de privacidad.", //CM0856
    register_with:"Reg\u00EDstrese en", //CM0463
    enjoy_services:"para disfrutar de servicios personalizados,", //CM0465
    including:"incluidos", //CM0466
    online_order_status:"Estado de la compra en l\u00EDnea", //CM0469
    exclusive_emails:"Correos electr\u00F3nicos exclusivos", // CM0470
    wishlist:"Lista de deseos", // CM0344
    checkout_preferences:"Preferencias de comprobaci\u00F3n de seguridad", // CM0471
    sign_In_Facebook:"Iniciar sesi\u00F3n usando Facebook", // CM1233
    the_counry_not_support_message:"El país/región actual no admite el inicio de sesión/registro con número de teléfono, utilice el correo electrónico para iniciar sesión/registrarse",
    enter_the_password:"Introduzca la contraseña",
    enter_the_code_number:"Ingresar el número de código",
    enter_phone_number:"Ingresar su número telefónico",
    incorrect_phone_number:"Número de teléfono incorrecto",
    password_change_success:"Contraseña cambiada con éxito",
    confirm_password:"Confirme la contraseña",
    verification_code_error_message:"Lo sentimos, el código de verificación ingresado es incorrecto o no válido.",
    phone_register_has_already:"El número de teléfono móvil ya ha estado en una cuenta, ¿iniciar sesión ahora?",
    phone_number_no_register_now:"Este número de teléfono no está registrado, ¿quieres registrarte ahora?",
    location:"UBICACIÓN",
    telephone_number:"NÚMERO DE TELÉFONO",
    resend:"reenviar",
    use_verification_code:"Utilice el código de verificación para iniciar sesión",
    sin_in_via_password:"Iniciar sesión a través del contraseña",
    verifycation_code_success:"Código de verificación enviado con éxito",
    confirm_your_phone_number_change:"Confirme su número de teléfono e ingrese el código correcto para cambiar la contraseña.",
    inconststent_password:"Contraseñas inconsistentes",
    register_via_telephone_number:"Registrar a través del número de teléfono",
    register_via_email_address:"Registrar a través de la dirección de correo electrónico",
    signin_via_telephone_number:"Iniciar sesión a través del número de teléfono",
    signin_via_email_address:"Iniciar sesión a través de la dirección de correo electrónico",
    almost_sold_out: "Casi agotado",

    telephone: 'Teléfono',
    forget_your_password: '¿Olvidaste tu contraseña?',
    choose_your_location: 'Por favor seleccione su ubicación',
    installment_forms: 'Formularios de pago a plazos',
    interest_free: 'Sin intereses',
    staging_rates: 'El tipo de interés varían según los bancos',
    accpect: 'Aceptar',
    sing_receive_discount_now:"¡Regístrese para recibir los siguientes descuentos ahora!",
    received_following_discount:"Has recibido los Siguientes Descuentos",
    received_vip_exclusive_discount:"Ha recibido Descuentos Exclusivos VIP",
    pick_my_gift:"Elige mi regalo",
    choose_add_your_cart:"Elija su regalo favorito para agregarlo a su carrito.",
    reselect_gift_your_bag:"Puedes volver a seleccionar el regalo en tu bolsa.",
    check_out: 'PAGAR',
    gift_to_your_bag: 'El Beauty Box se ha puesto en su bolso, puede pagar en cualquier momento antes del 6 de diciembre.',
    foot_length: 'Longitud del pie',
    network_error:'Error de red',
    subscription:"Suscripci\u00F3n", //CM0089
    invalid_email: 'Die eingegebene E-Mail ist ung\u00FCltig. Bitte \u00FCberpr\u00FCfen Sie Ihre E-Mail und versuchen Sie es erneut.',

    size_conversion: 'Conversión de tallas',
    international_size_reference: 'La comparación de tallas internacionales es solo de referencia, la talla específica sigue estando basada en la talla real de la ropa.',
    country_size: 'Talla de {country}',
    new_register_privacy_policy:"Al acceder a su cuenta, usted acepta nuestros Términos de Servicio y Política de Privacidad ", // CM1116 CM0462
    incorrect_email:"Correo electrónico incorrecto",
    enter_code_number:"Ingrese el número de código",
    enter_the_telephone_number_or_email:"Por favor, introduzca el teléfono o el correo electrónico",
    enter_the_verification_code:"Ingresa el código de verificación",
    send_verification_code:"Se envía un código de verificación a {value}",

    back: 'Volver',
    free_shipping: 'ENVÍO GRATIS',
    all: 'Todo',
    reset: 'Reinicializar',
    done: 'Hecho',
    price_range: 'Rango de precios({currency})',
    price_range_only: 'Rango de precios',
    repick: 'Repetir',
    pick: 'elegir',
    sure_delete_item: '¿Está seguro/a de que quieres eliminar este artículo?',
    sure_delete_items: '¿Está seguro/a de que quieres eliminar este producto(s)?',
    recommend_add_item: 'Elementos adicionales recomendados',
    free_gifts: 'Regalos gratis',

    available: 'Disponible',
    not: 'Not',
    select_anthoer_paymentmethod: 'Seleccione otro método de pago',
    sign_to_sync_bag: 'Inicie sesión para sincronizar su bolsa de compras',
    ship_to_different_address: 'Enviar a una dirección de envío diferente',
    address_book: 'Directorio',
    first_name: 'Nombre',
    last_name: 'Apellido',
    phone_number: 'Número de teléfono',
    address_line_1: 'Dirección de la calle',
    address_line_2: 'Unidad',
    address_line_1_placeholder: 'Calle, Dirección, Nombre de la Empresa, C/O',
    number: 'Número',
    neighborhood: 'Barrio',
    city:'Ciudad',
    state: 'Estado',
    zip_code: 'Código postal',
    enter_it_manually: 'Introducirlo manualmente',
    optional: 'Opcional',
    find_simillar: 'Buscar similares',
    ships_to_country: 'Envíos a: {country}',
    shopping_bag: 'Cesta De La Compra',
    secure_payment: 'Pago seguro',
    shopping_bag_empty: '¡Tu bolsa de compras está vacía!',
    sign_in_to_view_bag: 'Regístrese para ver su carrito y comenzar a comprar.',
    shop_now: 'Compra ahora',
    sign_in_register: 'Iniciar sesión/REGISTRARSE',
    secure_checkout: 'PAGO SEGURO',
    create_account_more_discount: 'Crea una nueva cuenta para obtener más descuento',
    continue_to_checkout: 'CONTINUAR PARA PAGAR',
    checkout_guest_with_email: 'Pagar como invitado con tu correo electrónico',
    guest_checkout: 'pago de invitado',
    checkout: 'PAGAR',
    move_to_wishlist: 'Mover a la lista de deseos',
    delete: 'Borrar',
    move_all: 'Mover todo',
    you_like_fill_it: 'te gustaría llenarlo con',
    add_to_wish_list_confirm: '¿Estás seguro de mover este artículo a tu lista de deseos?',
    apply_coupon: 'Aplicar cupón',
    ivalid_items_title: 'Estos artículos están temporalmente agotados.',
    count_items: '{total} Elementos',
    add_shipping_insurance: 'Añadir seguro de envío',
    place_order: 'Realizar un Pedido',
    apply_points: 'Aplicar Puntos',
    max_available_count: 'Máximo disponible {count}',
    apply_cash: 'Aplicar efectivo',
    wallet: 'Billetera',
    order_confirmation: 'confirmación de pedido',
    shipping_address: 'DIRECCIÓN DE ENVÍO',
    switch_payment_method: 'Cambiar método de pago',
    change_card_information: 'Cambiar la información de la tarjeta',
    process_payment: 'Estamos procesando su pago',
    do_not_leave_refresh: 'Por favor, no dejes ni actualices',
    price_per_installment: '{price}/mes',
    order_summary: 'Resumen del Pedido',
    shipping_insurance: 'Seguro de envió',
    order_number: 'N º de pedido',
    item_total: 'Total artículos',
    shipping_price: 'Coste del envío',
    order_total: 'Total del Pedido',

    required: 'Requerido',
    invalid_email_address: '{email} no es un correo electrónico válido',
    first_name_reg_default: 'El nombre debe contener de 1 a 40 caracteres numéricos no puros',
	last_name_reg_default: 'El apellido debe contener de 1 a 40 caracteres numéricos no puros',
    phone_reg_10_11: 'Ingrese al menos 10-11 números.',
	phone_reg_ae: 'Los números de teléfono deben comenzar con 50/52/54/55/56/58/2/3/4/6/7/9, seguido de 7 dígitos cualesquiera.',
	phone_reg_sa: 'Los números de teléfono deben comenzar con 50/53/55/51/58/59/54/56/57/11/12/13/14/16/17/811, seguido de 7 dígitos cualesquiera.',
	phone_reg_default: 'Formato de número de teléfono incorrecto (solo números, sin formato)',
    please_enter_5_digits: 'Ingrese 5 dígitos o 5 dígitos con un número de 4 dígitos (por ejemplo, 20001 o 20001-0000)',
	wrong_zip_code: '¡CÓDIGO POSTAL erróneo! Por favor, ofrézcanos una o dos letras y una o dos cifras con un espacio final con una cifra y dos letras. (por ejemplo, TN37 7HL,CW8 3AD)',
	wrong_address_br: 'El formato de la dirección es incorrecto, complete el formulario desde [01310-000]',
    not_vaild_cpf: '{value} no es un cpf válido',

    credit_debit_card:'Tarjeta de crédito / débito',
    billing_address: 'Dirección de facturación',
	select_a_card: 'Seleccione una tarjeta',
	use_new_card: 'Usar una Nueva Tarjeta',
    waiting_for_payment:"A la espera del pago",
	view:"vista",

    auto_search_address:'BUSCADOR DE DIRECCIONES: Buscar por código postal, calle o dirección',
    cant_find_address: "¿No puedes encontrar la dirección?",
    select_pay_method: '¡Seleccione un método de pago!',
    only_left: 'Sólo quedan {count}',
    pay_now: 'comprar ahora',
    card_number: 'número de tarjeta',
    expiration_date: 'fecha de caducidad',

    confirm_change_address: 'Algunos artículos enviados desde tiendas de EE. UU. no estarán disponibles para pagar, ¿está seguro de que desea cambiar de direcciones?',
    edit: 'Editar',
    add_new_address: 'Añadir una nueva dirección',
    details: 'Detalle',

    use_following_coupons: 'Puede usar los siguientes cupones en los artículos de su carrito de compras, utilícelos en la página de realizar el pedido.',
    cancel_info: '¿Está seguro de que desea cancelar el pago? Si su pedido no se paga en {countdown}, será cancelado.',
	continue_to_pay: 'Continuar a pagar',
	confirm_cancel: 'confirmar cancelar',
    protectAccount: 'Los detalles de su cuenta están totalmente protegidos y no serán revelados a terceros por ningún motivo.',
    available: 'Disponible',
    coupons_tip: "Los cupones/premios se pueden usar en el siguiente paso",
    invalid_address: 'Formato de dirección incorrecto',

    // Me
    login_or_register:"Iniciar sesión / Registrarse",
    introduce_to_others:"Preséntate a las demás",
    sure_delete_items:"¿Está seguro de que desea eliminar los elementos?",
    guest_user:"Usuario Invitado",
    set_password:"Configurar la clave",
    temporay_account_set_password:"Esta es su cuenta temporal. ¡Configure la contraseña para registrarse y obtener más descuentos!",
    addFavorites:'¡Agregue sus favoritos a su lista de deseos ahora!',









    // Me Setting
    sure_delete_address: '¿Está seguro de eliminar esta dirección?', //google
    change_successful: '¡Cambio correcto!', //google
    change_failed: '¡Cambio fallido!', //google
    save_successful: 'Guardar correctamente', //google
    save_failed: 'Guardado fallido', //google
    change_email: 'Cambiar correo electrónico', //google
    your_contact_email: 'Tu dirección de contacto/suscripción:', //google
    send_confirm_email: 'Enviar un correo electrónico de confirmación',
    your_new_account: 'Tu nueva cuenta',
    my_payment_options:'Mi opción de pago',
    change_password: 'Cambiar contraseña', // changePassword
    about: 'Acerca de',
    sure_delete_card: '¿Seguro que quieres eliminar esta tarjeta de crédito', // remove_credit_card
    current_password:"Contraseña actual",
    new_password:"Nueva Contraseña",
    must_new_password:"Confirmar contraseña debe ser igual a la nueva contraseña.",
    confirm_pwd: 'Confirmar contraseña',
    old_new_password:"The old and new passwords cannot be the same",
    expired_in:"Caducado en",
    view_rules:"Ver reglas",
    vip_rewards:"RECOMPENSAS {name} VIP",
    level_up_unlock:"SUBE DE NIVEL PARA DESBLOQUEAR",
    view_rewards:"Ver recompensas",
    points_redeem_success:'¡Redención exitosa!',
    faqs:"FAQS",
    your_vip_level_to:"Su nivel VIP se ha elevado a",
    your_new_rewards:"¡eche un vistazo a sus nuevas recompensas!",
    points_history:"Historial de puntos",
    expriring_soon:'Que vencen Pronto',
    how_to_get_points:'¿Cómo conseguir puntos?',
    buy_and_earn:'Comprar y ganar',
    shop_now:'Compra ahora',
    review:"Revisar",
    suggestion:"Sugerencia",
    refer_a_friend:'Recomendar un amigo',
    survey:"Encuesta",
    more_ways:'M\u00e1s formas',
    hot_use_points:'¿Cómo usar los puntos?',
    redeem_coupon:'Canjear cupón',
    apply_points:'Aplicar puntos para la compra',
    all:'Todos',
    recived:'Recibido',
    used:'Usado',
    wishlist:'Lista de Favoritos',
    expired:'Caducado',
    delete:'Borrar',
    unused_coupon: 'Cupones no utilizados', // unused_coupons
    expired_coupon: 'Cupones caducados', // expired_coupons
    expired_coupon_remove: 'Los cupones caducados se eliminarán de la bolsa de cupones después de 7 días.', // expired_coupoons_message
    yes:'SÍ',
    no:'NO',
    bust_size:'Tamaño del busto',
    bra_size:'Talla de sujetador',
    what_your_preference:'¿Cuál es tu preferencia?',
    large:'Grande',
    small:'Pequeño',
    my_measurements: 'Mis Medidas',
    preference_cannot_empty: "Mis preferencias no pueden estar todas vacías",  // my_preference_error_message
    my_preference: 'Mi preferencia',
    who_you_buy_clothes_for:'¿A quién le compras ropa habitualmente?',   // who_do_you_ususlly
    which_catehories_you_favor:'¿Cu\u00e1les son tus categorías favoritas?', // whitch_are_your_favorite
    which_style_you_favor: '¿Cu\u00e1les son tus estilos favoritos?', // whitch_are_your_styles
    bio: 'Bio',
    name: 'Nombre',
    first_name: 'Nombre', // firstName
    last_name: 'Apellido', // lastName
    nick_name: 'Apodo',
    change_profile_picture:'Cambiar foto de perfil',
    edit_profile: 'Modifier le Profil', // editProfile
    empty_here: "Está vacío aquí", // google
    ticket: 'Boleto',
    activities: 'Actividades',
    promo: 'Promoción',
    others: 'Otros',
    news: 'Noticias',
    orders: 'Pedidos',
    message: 'Mensaje',
    about_web: 'Acerca de {website}',
    setting: 'Configuración', // settings
    wallet_credit: 'Crédito en la cartera',
    only_apply_for: 'Sólo aplicable a las compras de {website}',  // only_applicable
    wallet_history: 'Historial de la cartera',
    earned: 'Ganado',
    wallet: 'Billetera',
    expired_on: 'Caducado en: {date}',  // expied_on
    order: 'Pedir',  // order_wallet
    get_more_points:"Obtenga m\u00e1s puntos",

    share_friend_points_shop:"Obtendr\u00E1 hasta <span class=\"font-red\">500 puntos</span>, y su amigo obtendr\u00E1 hasta <span class=\"font-red\">1000 puntos</span> para comprar!", // CM1158
    for_limited_time_only:"\u00A1Solo por tiempo limitado!", // CM1159
    how_does_it_works:"\u00BFC\u00F3mo funciona?", // CM1161
    share_the_link:"Comparte el enlace", // CM1162
    reward:"Recompensa", //CM0630_1
    copy_fail: 'copia fallida, inténtalo de nuevo', // google
    get_more_rewards:"\u00BFQuieres obtener m\u00E1s recompensas?", //CM1164
    friends_invited:"Amigos invitados", // CM1167
    points_earned:"Puntos ganados", // CM1168
    share_with:"COMPARTIR CON", // CM1170
    share_friend_max_10:"The link can be shared with {value} friends at one time", //CM1166
    email_title:"T\u00EDtulo del correo electr\u00F3nico", //CM1155
    open_to_points_reward:"Abrir para recibir recompensas de puntos misteriosos", //CM1242
    email_notes:"Notas por correo electr\u00F3nico", //CM1156
    share_textarea_description:"\u00A1{name} tiene tantas cosas imprescindibles! Reg\u00EDstrese ahora para obtener recompensas de puntos para comprar en {name}. {value}. \u00A1No pierdas esta oportunidad!", // CM1243
    share_via_email:"Compartir por correo electr\u00F3nico", // CM1151
    share_extra_game_play:"Haz clic en el enlace y consigue un juego extra", //google
    share_draw_luck_gifts:"{name} Sorteo del Black Friday. Juega y gana iPhone 13, {name} favoritos de belleza, Puntos Extra, Cupones y mucho más.",  //google
    success:"\u00C9xito", //CM0558
    order_no: 'Núm. de Pedido', // orderNo
    item:"art\u00edculo",
    track:'Pista',
    pay_now:'PAGUE AHORA', // paynow
    remaining:'Tiempo de pago restante',
    repurchase:'Readquirir',
    to_review:'Para revisar',
    add_success:'Agregar éxito',
    add_failed:'Error al agregar',
    unpaid:'No pagado',
    processing:'Procesando',
    shipped:'Pedido enviadot',
    returns:'Returens',
    history_orders_message:'Haga clic aquí para consultar sus pedidos anteriores.',
    order_details:'Detalles del pedido',
    your_package_is_packed:'Su paquete se est\u00e1 empaquetando',
    it_normally_process_your_order:'Normalmente tardamos entre 3 y 7 d\u00edas h\u00e1biles en procesar su pedido',
    your_package_signed_for:'Su paquete ha sido firmado.',
    if_you_cosutomer_services:'Si tiene alguna pregunta',
    payment_date:'Date de Paiement',  // paymentDate
    related_order_no: 'Número de orden relacionado', // relatedOrderNo
    shipping_price: 'Precio del Env\u00edo', // shippingPrice
    order_total: 'Total del Pedido', // orderTotal
    insurance:"Seguro de envi\u00f3", // shippingInsurance
    shipping_address:'Dirección de env\u00edo',
    billing_address:'Dirección de facturación',
    shipping_method:'Método de env\u00edo',
    delivery_time:'Tiempo de entrega',
    return:'Volver',
    often_bought_with:'A MENUDO COMPRADO CON',
    cancel_order: 'Annuler l\'Ordre', // cancelOrder
    return_label:"Etiqueta de devoluci\u00f3n", // returnlabel
    confirm_cancel: 'Confirmar Cancelar',
    continue_to_pay: 'Continuar pagando',
    you_sure_cancel:'¿Está seguro de que desea cancelar el pago? Si tu pedido no está pagado',
    it_will_cancel:'será cancelado',
    select_reason:'Seleccione un motivo',
    reason_submit_success_message:"¡Enviado correctamente! ¿Desea volver a añadir el producto a su bolsa de la compra?", // google
    no_comment_order:'¡Tienes pedidos sin revisar, ve y comparte tu experiencia para ganar m\u00e1s puntos!',
    you_have_no_orders: "Todav\u00eda no tienes ningún pedido, ¡ve a elegir tus productos favoritos!", // youHaveNoOrders
    go_shopping: 'Ir de compras', // goShopping
    default: 'predeterminado',
    do_not_have_enough_points: 'No tienes suficientes puntos para canjear este cupón.', // points_not_enough_text
    use_points_redeem_coupon: '¿Quieres usar {points} puntos para canjear este cupón?', // points_check_redeem_text
    redeem:'Canjear',  // points_redeem
    redeemed: 'Redimido',
    use_points_to_redeem:'¡Usa puntos para canjear más cupones!', // use_points_redeem_coupon
    upload_image:'Cargar imagen',
    image_formate:'Máximo de 3 fotos, solo JPEG,GIF o PNG.', // maxinum_three_photos
    screen_freeze:'Congelación de pantalla',
    app_crush:'Aplastamiento de la aplicación',
    black_screen:'Pantalla en negro',
    device_reboot: 'Reinicio del dispositivo', // device_rebot
    running_slowly:'Corriendo despacio',
    other_error:'Otro error',
    update_success:'Erfolg aktualisieren',
    question_type:'tipo de pregunta',
    time:'Tiempo',
    suggestion_placeholder:'Disculpe las molestias, solucionaremos el problema lo antes posible ...', // sorry_inconvenience
    points: 'Puntos',
    please_check_rules:'Consulte la página de reglas para ver las reglas de puntos de texto e imágenes',
    rating:"Valoración", // google
    very_satisfied:'Muy Satisfecho',
    satisfied:'Satisfecho',
    normal:'Normal',
    bad:'Malo',
    poor:'Pobre',
    order_review_placeholder:'Gana {point} por comentarios de más de {wordNum} caracteres... *', // google
    earn_more:'Gana más {points} puntos por comentarios con imágenes',
    my_size_infomation:'Información de mi talla (opcional)',
    earn_more_for_size:'Gana más {points} puntos por llenar todas tus tallas',
    comments_not_empty:"¡Los comentarios no pueden estar vacíos!", // google
    order_confirmed:"Pedido Confirmado", // order_confirm
    order_detail_view:'Puedes ver los detalles del pedido para verificar este pedido.',
    order_confirm_success:'¡Pedido confirmado con éxito!',
    view_order:'Ver pedido',
    order_confirmed_enter_message: 'El artículo (s) está todavía en tránsito, ¿está seguro de que desea hacer clic en confirmar para recibirlo?', //google
    also_awaiting_review:'Estos pedidos tambi\u00e9n est\u00e1n pendientes de revisi\u00f3n',
    shop_the_reviewd_items:'Compre los art\u00edculos altamente calificados',
    add_to_bag_success:'¡Agregar a la bolsa con éxito!',
    logisticsInfo:'informaci\u00f3n log\u00edstica',
    tracknum:'El número de rastreo',
    logisticsCompany:'Compañ\u00eda de log\u00edsitca',
    trackingDetailInfo:'Compañ\u00eda de log\u00edsitca',
    currentStatus:'Estado actual',
    cancel_return:'Cancelar devoluci\u00f3n',
    edit_return_receipt:'Editar recibo de devoluci\u00f3nt',
    return_receipt:'Recibo de devoluci\u00f3n',
    return_record:'Regresar registro',
    return_canceled:'Devoluci\u00f3n cancelada',
    total_refund:'Reembolso total',
    are_you_want_cancel_return:'¿Est\u00e1 seguro de que desea cancelar la devoluci\u00f3n?',
    upload_receipt:'Subir recibo',
    a_pooto_package_and_tracking_number:'Una foto del recibo de la empresa de env\u00edo indicando el peso del paquete y el número de seguimiento.',
    courier_company:'empresa de mensajer\u00eda',
    select_courier_company:'Por favor seleccione la compañ\u00eda de mensajer\u00eda', // please_select_courier_company
    fillin_courier_cpmpany:'Por favor, rellene su empresa de mensajer\u00eda',
    not_be_empty:'No puede estar vac\u00edo',
    check_order:'Puedes revisar los detalles de tu pedido para ver el progreso de tu devolución.',
    return_logistics:'Log\u00edstica de devolución',
    find_similar:'Similar',
    history_orders:'Órdenes de historia',
    dont_want_rate: '¿Seguro que no quieres calificar este pedido? Recibirás ',

    help_you: '¿Como puedo ayudarte?',
    message_us: 'Mensaje nosotros',
    tickets: 'Entradas',
    search_holder: 'Productos, entrega, pago ...',
    search_results: 'Resultados de la búsqueda',
    search_count: '{count} resultados para "{key}"',
    section_articles: 'Artículos en esta sección',
    status_replied: 'Respondido',
    status_unreplied: 'Sin responder',
    status_resolved: 'Resuelto',
    last_message: 'Ultimo mensaje',
    image: 'Imagen',
    ticket_id: 'Identificación de entradas',
    subject_size_color: 'Tamaño / preferencia de color',
    subject_address: 'Cambiar la dirección de envío',
    subject_shipping: 'Estado de envío o consulta de ETA',
    subject_wrong: 'Artículo dañado o incorrecto recibido',
    subject_shippingmethod: 'Método de envío de actualización',
    subject_return: 'Devolución o cambio',
    subject_refund: 'Solicitar reembolso / cancelar pedido',
    payment_time: 'Tiempo de pago',
    response_time: 'Tiempo de respuesta esperado: 1 día hábil',
    select_order: 'Por favor seleccione su orden',
    order_status_confirmed: 'Confirmado',
    order_status_canceled: 'Cancelado',
    order_status_pendding: 'Pendiente',
    order_status_paid: 'Pagado',
    order_status_partially_refunded: 'Reintegrado parcialmente',
    order_status_refunded: 'Reintegrado',
    order_status_held: 'Retenida',
    rate: 'Evaluacion',
    unsatisfied: 'Insatisfecho',
    you_can_rate: 'Puedes comentar servicio al cliente aquí',
    please_rate: 'Por favor califica mi servicio',
    // support:
    click:'Para todas las preguntas y respuestas, haga clic en nuestro',
    page:"página",
    find_nothing:"¿No encuentra la respuesta que busca?",
    order_processing: "Procesamiento de pedidos",
    tracking:"Seguimiento logístico",
    return_tag:"Devolución y reembolso",
    product_tag:"Producto y stock",
    payment_tag:"Pago y promociones",
    account_isssues:"Problemas con la cuenta",
    // contact:
    ticket_time: 'SÓLO para problemas de posventa （Cancelar / Editar pedido / Estado de envío / Devolución / Cambio, etc.)',
    online_time: 'SOLO para la edición de preventa. Las ventas en línea no tienen acceso a la información del pedido. ',
    result_for: "resultados para \"<strong>{result}</strong>\"",
    question:"¿Tienes más preguntas?",
    article_helpful:"¿Te resultó útil este artículo?",
    related:"Artículos relacionados",
    // ticket
    noTicket:"no Ticket",
    // order
    // ticket add
    textarea_placeholder:"Escriba un mensaje aquí ...",
    response_time:"Tiempo de respuesta esperado: en 24 horas",
    select_tip: 'Seleccione su tipo de pregunta',
    status_wating: 'Esperando respuestas',
    // question ticket
    submit_tips: "Las siguientes razones son opcionales. Puede hacer clic en “X” si no desea elegir ninguno de ellos.  ",
    description_ph: 'Describe tu problema tanto como sea posible para que el servicio al cliente pueda responder más rápido ...',
    upload_image: 'Cargar imagen',
    rate:'Califica mi servicio',
    rate_info: "Nos encantaría saber qué piensa de nuestro servicio de atención al cliente para ayudarnos a brindarle un mejor servicio",
    rate_text_placeholder: 'Puede comentar el servicio al cliente aquí.',
    status_wating_apply:'Esperando aplicar',
    facebook_check: 'Si marca esta opción, le enviaremos el estado de seguimiento de la logística y la información del pedido en Messenger.',
    return_the_oder: 'Devolver el Pedido',
    popular_search: 'Búsquedas populares:Reembolso,Devolución,Envío',
    vip_service: 'Servicio VIP',
    copied_successfully:"Copiado correctamente", // CM1244
    share_the_code:"Comparte el c\u00F3digo", // CM1165

    out_of_stock_policy:"Política de agotamiento de existencias",
    check_mail_box:"Se ha enviado un enlace de verificación a su dirección de correo electrónico, verifique su buzón.",
    success_will_send_latest:"¡Éxito! Enviaremos nuestro último boletín a su nueva dirección de correo electrónico.",
    upload_file_size_limit: 'El tamaño del archivo debe ser inferior a 2 MB',

    order_processing_shipping_date: 'Por lo general, tardamos de 3 a 7 d\u00edas hábiles en procesar su pedido.', // shippingDate
    my_order:"Mis Pedidos",
    my_services:"Más servicios",

    set_password_more_discount:"¡Establezca su contraseña para obtener más descuento!",   //google

    time_must_current:"¡La fecha de nacimiento debe ser anterior a la hora actual!",

    recently_viewed: "Visto recientemente", 
    you_not_recently_view:"No has visto nada.", 
    website_trends:"Tendencias {website}",

    verify_check_order: "Revisar orden",
    shipping_cost: "costo de envío",// shippingCost
    register_and_get: '¡Regístrese en {siteName} para rastrear su pedido y obtener más descuentos!',
    please_input_password_first:'Por favor ingrese la contraseña primero',
    check_order_msg: 'Ahora, puede verificar su pedido en su cuenta. Marque "Mi pedido" en "Yo" para rastrear su pedido.',
    total_count_pages: "{count} páginas totales",
    saved: "Guardado",
    use_max_coupon: "¡No olvides usar el cupón de descuento del {coupon}!",
    up_to_saving: "hasta {coupon} de ahorro",

    please_select_order_first: '¡Seleccione un pedido primero!',

    exprct_to_save: "Espere ahorrar",
    max_save: "Ahorro máx.",
    step_1_address_content: "Para ver los artículos que se envían a una ubicación diferente, cambie su dirección de entrega.",
    next_step: "Siguiente paso",
    step_2_select_content: "Haga clic aquí para seleccionar los artículos que necesita PAGAR.",
    got_it: "Entendido",
    step_2_checkout_content: "Aplique un código de cupón y/o puntos de {site} en el siguiente paso.",
    shipping_fee: "Costo de envío",
    more_products: "Más productos",
    change: 'Cambiar',

    bottomLengthNote: "Mide desde la cintura hasta la abertura de la pierna o el dobladillo.",
    inseam: "Entrepierna",
    inseamNote: "Mide la longitud desde la costura de la entrepierna hasta la parte inferior de la pierna.",
    yourBustNote:"Mide la circunferencia sobre la parte más ancha de tu busto.",
    yourUnderBust: "Tu Bajo Busto",
    underBustNote: "Mide la circunferencia sobre la línea inferior de tu busto.",
    swimBustNote: "Mide la circunferencia sobre la parte más completa de tu seno.",
    swimUnderBustNote:"Mide en línea recta a lo largo de la banda debajo del busto de borde a borde.",
    swimWaistNote: "Mide  la cintura en el lugar más delgado.",
    swimHipNote: "Mide la parte más ancha de tus caderas.",
    switchTo: "Cambiar a",

    underBust: 'debajo del busto',
    bustDifference: "Busto - Diferencia debajo del busto",
    expires_soon: "Expira pronto",
    sign_in_check_coupon:"Inicia sesión para ver más cupones",

    footWidth: 'Ancho del pie',
    footLengthNote: 'Mide la longitud máxima de tu pie.',
    footWidthNote: 'Mide el ancho máximo de tu pie.',

    wholesale: "Compra mayorista", // CM0828

    pre_wholesale: "PRE-Mayorista",
    after_wholesale: "DESPUÉS de mayorista",

    apply_before_expires: "¡Aplícalo antes de que expire!",
    gift_has_been_cart: "¡El regalo ha sido añadido a tu carrito!",
    mystery_gifts_giveaway:"Regalos sorpresa y gran sorteo.",

    got_extra_chance_to_win: "Sie haben ein exklusives VIP-Angebot und eine EXTRA CHANCE auf einen großen Preis!",
    collect_and_draw: 'Acepta & Sortea Ahora',

    how_rate_website: "¿Cómo calificarías {website}?",
	please_rate_by_stars: "Califique {website} por estrellas: 1 a 5",
    close: "Cerrar",

    purchase_protection: "Pago protegido",
    efficient_logistics: "Logística Eficiente",
    customer_service: "Servicio al Cliente",
    privacy_protection: "Privacidad protegida",
    proceed_to_checkout: "Ir a pagar",

    enjoy_discount_add_items: "Disfrute de descuentos solo si agrega artículos de esta lista a su carrito.",
    add_on_items: "Artículos adicionales",

    with_shipping_price: 'con gastos de envío',
    modify: 'MODIFICAR',
	please_sure_address_correct: 'Por favor, asegúrese de que la dirección que rellena es correcta',
    login_to_receive: "Iniciar sesión para recibir",

    bottoms: 'PANTALONES',
    coat: "ABRIGO",
    lingerie: 'LENCERÍA',
    tops: 'TOPS',

    addOnItemMsg: 'Descuento extra',
    extraDiscount: 'Descuento Adicional',
    cheapThanAdd: 'Más barato que añadir',

    can_find_your_size:"¿No encuentras tu talla?",
    tell_us_size: "Dinos tu talla",
    thanks_your_feedback: "Gracias por tus comentarios.",
    submit_successful: "Enviar con éxito", 
    we_found_similar_size: "También encontramos artículos similares para ti en tamaño",
    we_optimize_based_feedback:"Optimizaremos el rango de tamaño en función de sus comentarios.",
    similar_item: "Artículos similares",
    not_find_anything_similar_search: "Lamentamos no poder encontrar nada similar a lo que estabas buscando.",

    smaller_than: "Mas pequeño que",
    larger_than: "Más grande que",
    spin_to_win_for_mom: 'Gira a ganar para mamá!',
    not_your_vibe_there_more: '¿No es adecuado? ¡Hay más!',
    picks_for_you: 'Selecciones para usted',
    add_to_home_screen:"Añadir {name} a la pantalla de inicio",

    collect_share: 'Recopilar y compartir',
    link_expired: 'Lo siento, este enlace ha caducado',
    link_valid_for_7_days: 'Solo fue válido durante 7 días después de ser compartido.',
    share_coupon_expired: 'Lo siento, el cupón compartido ha expirado',
    reach_coupon_limit: 'Lo siento, ha alcanzado el límite de reclamos de cupones',

    your_app_is_outdated: 'Su versión de la aplicación está desactualizada.',
    user_time_limit: 'Lo siento, ya has recibido el cupón. No se puede reclamar nuevamente.',

    share: "compartir",
    collect_now: "Recoger ahora",
    gift_card_receive: 'Se ha añadido una tarjeta de regalo exclusiva a tu paquete de cupones',
    get_gift_card_from_share: "Recibirás una tarjeta de regalo exclusiva compartida por tus amigos",
    limited_discount: 'Descuento Limitado',
    enjoy_free_shipping: '¡Disfruta de Envío Gratis',

    set_the_password: "Establecer la contraseña",
    send_subscribe_message: "Haz clic para aceptar los mensajes promocionales de {website} a través de WhatsApp o messenger. Puedes darte de baja en cualquier momento.",
    enter_whatsapp_number: "Introduce tu número de WhatsApp",
    send_to_whatsapp: "Enviar a WhatsApp",
    please_select_location: "Por favor, seleccione su ubicación",
    subscribe_success: "¡Suscribirse con éxito!",
    subscribe_filed: "Suscripción fallida",
    whatsapp_number: "Número de WhatsApp",
    receive_offers_message: "Me gustaría recibir ofertas y noticias exclusivas por SMS.",

    status:'Estado',
    wish_list_filter_empty: 'No se encontraron resultados. Considere probar opciones diferentes.',
    wish_list_nothing: 'Actualmente no tienes nada guardado. Marca un elemento con un \'corazón\' para comenzar tu lista de deseos.',
    click_to_draw: 'Haz clic para dibujar',

    wrong_zip_code_us: 'El código postal debe tener 5 dígitos o 5 dígitos seguidos de un número de 4 dígitos, por ejemplo 20001 o 20001-0000.',
    wrong_zip_code_uk: 'El código postal debe contener de 6 a 8 letras, dígitos y espacios en el formato M2 5BQ, CW8 3AD, TN37 7HL.',
    wrong_zip_code_br: 'El código postal debe contener 8 dígitos separados por un guión, en el siguiente formato: 12345-123.',
    wrong_zip_code_mx: 'El código postal debe ser un número de 5 dígitos, por ejemplo 12345.',
    wrong_zip_code_de: 'El código postal debe consistir en al menos 5 dígitos. Ejemplo: 12345.',
    wrong_zip_code_fr: 'El código postal debe contener 5 dígitos, por ejemplo 12345.',
    wrong_zip_code_es: 'El código postal debe contener 5 dígitos. El primer dígito no puede ser 6, 7, 8 o 9.',
    wrong_zip_code_it: 'El código postal debe ser un número de 5 dígitos, por ejemplo 12345.',
    wrong_zip_code_at: 'El código postal debe consistir en 4 dígitos. Ejemplo: 1234.',
    wrong_zip_code_be: 'El código postal debe consistir en 4 dígitos. Ejemplo: 1234.',
    wrong_zip_code_au: 'El código postal debe ser un número de 4 dígitos, por ejemplo 1234.',
    wrong_zip_code_bg: 'El código postal debe ser un número de 4 dígitos, por ejemplo 1234.',
    wrong_zip_code_ca: 'El formato del código postal debe ser: letras + números + letras + espacios + números + letras + números, por ejemplo A1B 2C3.',
    wrong_zip_code_ch: 'El código postal debe consistir en 4 dígitos. Ejemplo: 1234.',
    wrong_zip_code_cy: 'El código postal debe ser un número de 4 dígitos, por ejemplo 1234.',
    wrong_zip_code_cz: 'El código postal debe tener 5 dígitos y 1 espacio en el formato 123 45.',
    wrong_zip_code_ee: 'El código postal debe ser un número de 5 dígitos, por ejemplo 12345.',
    wrong_zip_code_fi: 'El código postal debe ser un número de 5 dígitos, por ejemplo 12345.',
    wrong_zip_code_gr: 'El código postal debe tener 5 dígitos y 1 espacio en el formato 123 45.',
    wrong_zip_code_hr: 'El código postal debe ser un número de 5 dígitos, por ejemplo 12345.',
    wrong_zip_code_ie: 'El código postal debe contener 7 letras, dígitos y espacios en el formato A65 F4E2, T45 D868, T12 FPK5.',
    wrong_zip_code_lt: 'El código postal debe ser un número de 5 dígitos, por ejemplo 12345.',
    wrong_zip_code_lu: 'El código postal debe ser un número de 4 dígitos, por ejemplo 1234.',
    wrong_zip_code_lv: 'El código postal debe ser un número de 4 dígitos, por ejemplo LV-1234',
    wrong_zip_code_nl: 'El código postal debe consistir en 4 dígitos seguidos de un espacio y terminar con 2 letras, por ejemplo 2561 DV.',
    wrong_zip_code_no: 'El código postal debe ser un número de 4 dígitos, por ejemplo 1234.',
    wrong_zip_code_nz: 'El código postal debe ser un número de 4 dígitos, por ejemplo 1234.',
    wrong_zip_code_pl: 'El código postal debe tener 5 dígitos y un guión en el formato 12-345.',
    wrong_zip_code_pt: 'El código postal debe contener 7 dígitos separados por un guión, en el siguiente formato: 1234-123.',
    wrong_zip_code_ro: 'El código postal debe ser un número de 6 dígitos, por ejemplo 123456.',
    wrong_zip_code_se: 'El código postal debe ser un número de 5 dígitos, por ejemplo 12345.',
    wrong_zip_code_dk: 'El código postal debe ser un número de 4 dígitos, por ejemplo 1234.',
    wrong_zip_code_us: 'Código postal: debe tener 5 dígitos o 5 dígitos seguidos de un número de 4 dígitos, ej. 20001 o 20001-0000',
    wrong_zip_code_uk: 'Código postal: debe contener 6-8 letras, dígitos y espacios en el formato M2 5BQ, CW8 3AD, TN37 7HL',
    wrong_zip_code_br: 'Código postal: debe contener 8 dígitos separados por un guión, en el siguiente formato: 12345-123',
    wrong_zip_code_mx: 'Código postal: debe ser un número de 5 dígitos, ej. 12345',
    wrong_zip_code_de: 'Código postal: debe tener al menos 5 dígitos. Ejemplo: 12345',
    wrong_zip_code_fr: 'Código postal: debe contener 5 dígitos, ej. 12345',
    wrong_zip_code_es: 'El número de código postal debe contener 5 cifras. La primera cifra no puede ser 6, 7, 8 o 9.',
    wrong_zip_code_it: 'Código postal: debe ser un número de 5 dígitos, ej. 12345',
    wrong_zip_code_at: 'El código postal debe consistir en 4 dígitos. Ejemplo: 1234',
    wrong_zip_code_be: 'El código postal debe consistir en 4 dígitos. Ejemplo: 1234',
    wrong_zip_code_au: 'Código postal: debe ser un número de 4 dígitos, ej. 1234',
    wrong_zip_code_bg: 'Código postal: debe ser un número de 4 dígitos, ej. 1234',
    wrong_zip_code_ca: 'Formato de código postal: letras + números + letras + espacios + números + letras + números, ej. A1B 2C3',
    wrong_zip_code_ch: 'El código postal debe consistir en 4 dígitos. Ejemplo: 1234',
    wrong_zip_code_cy: 'Código postal: debe ser un número de 4 dígitos, ej. 1234',
    wrong_zip_code_cz: 'Código postal: debe tener 5 dígitos y 1 espacio, en el formato 123 45',
    wrong_zip_code_ee: 'Código postal: debe ser un número de 5 dígitos, ej. 12345',
    wrong_zip_code_fi: 'Código postal: debe ser un número de 5 dígitos, ej. 12345',
    wrong_zip_code_gr: 'Código postal: debe tener 5 dígitos y 1 espacio, en el formato 123 45',
    wrong_zip_code_hr: 'Código postal: debe ser un número de 5 dígitos, ej. 12345',
    wrong_zip_code_ie: 'Código postal: debe contener 7 letras, dígitos y espacios en el formato A65 F4E2, T45 D868, T12 FPK5',
    wrong_zip_code_lt: 'Código postal: debe ser un número de 5 dígitos, ej. 12345',
    wrong_zip_code_lu: 'Código postal: debe ser un número de 4 dígitos, ej. 1234',
    wrong_zip_code_lv: 'Código postal: debe ser un número de 4 dígitos, ej. LV-1234',
    wrong_zip_code_nl: 'Código postal: debe tener 4 dígitos seguidos de un espacio y 2 letras, ej. 2561 DV',
    wrong_zip_code_no: 'Código postal: debe ser un número de 4 dígitos, ej. 1234',
    wrong_zip_code_nz: 'Código postal: debe ser un número de 4 dígitos, ej. 1234',
    wrong_zip_code_pl: 'Código postal: debe tener 5 dígitos y "-" en el formato 12-345',
    wrong_zip_code_pt: 'Código postal: debe contener 7 dígitos separados por un guión, en el siguiente formato: 1234-123',
    wrong_zip_code_ro: 'Código postal: debe ser un número de 6 dígitos, ej. 123456',
    wrong_zip_code_se: 'Código postal: debe ser un número de 5 dígitos, ej. 12345',
    wrong_zip_code_da: 'Código postal: debe ser un número de 4 dígitos, ej. 1234',

    systemEmailNotice: 'Aviso: Actualiza tu correo electrónico para recibir notificaciones importantes de nuestra parte. Haz clic para actualizar ahora.',
    wishListEmptySignIn: 'Lista de deseos vacía. Inicia sesión para ver tu lista de deseos.',
    country_code: "Código de país",
    select_country_code: "Por favor, selecciona el código de país correcto",

    local_policy_pay_tax: "Según la política local, los clientes deberán pagar un impuesto sobre las operaciones de cambio al elegir este método de pago.",
    returning: "Volviendo",
    select_to_be_returned: "Seleccione el/los artículo(s) a devolver",
    cannot_be_returned: "Este tipo de artículo no se puede devolver",
    are_being_returned: "El/los artículo(s) se están devolviendo",
    please_select_the_items: "Seleccione el/los artículo(s)",
    select_reason_all_be_returned: "Seleccione el motivo por el cual todos los artículos deben ser devueltos",
    please_select_the_reason: "Seleccione el motivo",
    please_submit_further_details: "Envíe más detalles",
    descrive_problem_customer_service_respond: "Describa su problema lo más detalladamente posible para que el servicio al cliente pueda responder más rápido",
    require_support: "requerir soporte",

    download: "Descargar",
    download_all: "Descargar todo",
    download_products_images: "Descargar imágenes del producto",

    drop_shipping: "Envío directo",
    limit_time_only:"SOLO POR TIEMPO LIMITADO",
    ruler_min: 'La regla está en su mínimo',
    ruler_max: 'La regla está en su máximo',
    swiper_to_add: 'Desliza para agregar tu información corporal',
    save_your_body_mea: 'Guardaremos tus medidas corporales para asegurar un ajuste perfecto en tus futuras compras.',
    mea_perf: 'Preferencia',
    safe_payment_options: "Opciones de pago seguras",

    influencer_outfit: "Atuendo de influencer",

    sign_in_paypal:"Iniciar sesi\u00F3n usando PayPal",
    ends_today: 'termina hoy',

    selected: 'seleccionado',
    addon_reach_limit: 'Ha alcanzado el límite de artículos adicionales seleccionados.Haga clic en "Seleccionado" para deshacer su selección.',

    phone_number_or_email_address: "Número de teléfono o dirección de correo electrónico",
    reset_password_via_email: "Restablecer contraseña por dirección de correo electrónico",
    reset_password_via_phone: "Restablecer contraseña por número de teléfono",
    welcome_back: "Bienvenido de nuevo",
    please_sign_in_account: "Por favor, inicie sesión con su cuenta.",
    please_create_your_account: "Por favor, cree su cuenta.",
    code: 'Código',

    sign_in_with:"Iniciar sesi\u00F3n usando {mode}",

    incorrect_email_address:"Dirección de correo electrónico incorrecta",


    privacy_protection: 'Protección de la privacidad',
    security_privacy: 'Seguridad y privacidad',
    register_success: "Se ha registrado con éxito",

    type: 'tipo',
    spin_to_win: 'Girar para Ganar',
    you_run_out_of_chance: "Te has quedado sin oportunidades",
    get_all_in_app: 'obtener todo en la aplicación',
    app_new_user_gift: 'Regalo de App Nuevo',
    ends_in: 'termina en',

    cvv_tip: 'Para un pago exitoso, por favor ingrese su Número de Verificación de Tarjeta de Crédito (CVV). Puede encontrar el número CVV en la parte trasera de su tarjeta de crédito.',

    promotion_details: "Detalles de la promoción",
    estimated_price: "Precio estimado",
    buy_now_enjoy: "Compra ahora para disfrutar de los siguientes beneficios",
    discount_price: "Precio con descuento",
    original_price: "Precio original",
    estimated: "Estimado",
    discount_shown_estimated: "Los descuentos mostrados son estimados y están sujetos a las reglas de cupones. El precio final puede variar.",
    promotions: "Promociones",
    get_coupon: "Obtener cupón",
    best_coupon: "Mejor cupón",

    app_exclusive: 'Exclusivo',
    unsubscribe_message: 'Vuelve a suscribirte con {email} en {website} y obtén cupones.',
    subscribe_and_get: 'Vuelve a suscribirte y ahorra',

    quick_ship: 'Envío rápido',
    provide_faster_delivery_service: 'proporcionará un servicio de entrega más rápido',
    between: 'entre',
    eligible_for_quick_ship: 'son elegibles para Envío Rápido',

    consent_cookie_dsc: 'Utilizamos cookies y otras tecnologías de seguimiento para mejorar su experiencia de navegación en nuestro sitio web, mostrarle contenido personalizado y anuncios dirigidos, analizar el tráfico de nuestro sitio web y comprender de dónde provienen nuestros visitantes.',
    consent_btn_settings: 'Configuración de cookies',
    consent_btn_accept: 'Aceptar',
    consent_btn_decline: 'Rechazar',
    consent_about: 'Acerca de las cookies',
    consent_setting_1_title: 'Cookies Necesarias',
    consent_setting_1_status: 'Siempre activas',
    consent_setting_1_dsc: 'Estas cookies son esenciales para que el sitio web funcione correctamente. Permiten funciones básicas como la navegación por páginas y el acceso a áreas seguras del sitio web. El sitio web no puede funcionar correctamente sin estas cookies y no se pueden desactivar.',
    consent_setting_2_title: 'Cookies Analíticas',
    consent_setting_2_dsc: 'Las cookies analíticas proporcionan información sobre cómo se utiliza este sitio web. Esto mejora la experiencia del usuario. Los datos recopilados se agregan y se anonimizan.',
    consent_setting_3_title: 'Cookies Publicitarias',
    consent_setting_3_dsc: 'Las cookies publicitarias proporcionan información sobre las interacciones de los usuarios con el contenido de ChicMe. Esto nos ayuda a comprender mejor la efectividad del contenido de nuestros correos electrónicos y nuestro sitio web.',
    consent_btn_accept_all: 'Aceptar todo',
    consent_btn_save_settings: 'Guardar configuración',
}