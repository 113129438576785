import axios from 'axios'
import qs from 'qs'
import website from '../configs/website.json'
import Cookie from 'js-cookie'
import { v4 } from 'uuid'
import https from 'https'

function getHeaders() {
	if (typeof window !== 'undefined') {
		const randomid = v4()
		const headers = {
			deviceType: window.deviceType,
			appVersion: window.appVersion,
			saAppVersion: window.saAppVersion,
			originType: window.accessToken ? 'app' : '',
			wid: Cookie.get('clientId') || randomid,
			randomid,
			xtoken: window.xtoken || '',
			'x-width': window?.screen?.width,
			'x-height': window?.screen?.height,
			'x-colordepth': window?.screen?.colorDepth,
			'x-time-zone-offset': new Date().getTimezoneOffset(),
			forterMobileUID: window.forterMobileUID,
		}
		if(window.accessToken){
			headers['accessToken'] = window.accessToken
		}
		return headers
	} else {
		return {
			appVersion: '4.5.9',
			serverType: 'node'
		}
	}
}

function getBaseUrl() {
	if (typeof window !== 'undefined') {
		return website.baseUrl.client
	} else {
		return website.baseUrl.server
	}
}

function getInstance(isServer) {
	const conf = {
		baseURL: getBaseUrl(),
		timeout: 50000,
		headers: getHeaders()
	}

	return isServer ? {
		...conf, httpsAgent: new https.Agent({
			rejectUnauthorized: false
		})
	} : conf
}

export const clientApi = axios.create(
	getInstance(typeof window === 'undefined')
)

const requestToken = () => clientApi.get('/context/anon/gat', {}, []).then((res) => {
	const token = res.data.result
	clientApi.defaults.headers['xtoken'] = token
	if (typeof window !== 'undefined') {
		try {
			Cookie.set('xtoken', token, { expires: 7, path: '/' })
		} catch (error) {
			console.error('cookie 设置错误')
		}
	}
	return token
})

export const get = (url, params, headers = {}) => {

	return clientApi.get(url, {
		params,
		headers: headers || {}
	}).then((res) => {
		if (res.data.code == 310 && typeof window !== 'undefined') {
			return requestToken().then(token => {
				return clientApi.get(url, {
					params,
					headers
				}).then(res => res.data)
			})
		} else {
			return res.data
		}

	}).catch((e) => {
		console.error(e)
		return null
	})
}

export const post = (url, data, headers = {}) => {
	return clientApi.post(url, qs.stringify(data), {
		headers: headers || {}
	}).then((res) => {
		if (res.data.code == 310 && typeof window !== 'undefined') {
			return requestToken().then(token => {
				return clientApi.post(url, qs.stringify(data), {
					headers
				}).then(res => res.data)
			})
		} else {
			return res.data
		}

	}).catch((e) => {
		console.error(e)
		return null
	})
}

export const body = (url, data, params, headers = {}) => {

	return clientApi.post(url, data, {
		params,
		headers: headers || {}
	}).then((res) => {
		if (res.data.code == 310 && typeof window !== 'undefined') {
			return requestToken().then(token => {
				return clientApi.post(url, data, {
					params,
					headers
				}).then(res => res.data)
			})
		} else {
			return res.data
		}

	}).catch((e) => {
		console.error(e)
		return null
	})
}