// snaptr('track', 'ADD_CART', {'price': INSERT_PRICE, 'currency': 'INSERT_CURRENCY', 'item_ids': ['INSERT_ITEM_ID_1', 'INSERT_ITEM_ID_2'], 'number_items': INSERT_NUMBER_ITEMS, 'uuid_c1': 'INSERT_UUID_C1', 'user_email': 'INSERT_USER_EMAIL'})
// snaptr('track', 'PAGE_VIEW', {'item_ids': ['INSERT_ITEM_ID_1', 'INSERT_ITEM_ID_2'], 'uuid_c1': 'INSERT_UUID_C1', 'user_email': 'INSERT_USER_EMAIL'})
// snaptr('track', 'PURCHASE', {'price': INSERT_PRICE, 'currency': 'INSERT_CURRENCY', 'transaction_id': 'INSERT_TRANSACTION_ID', 'item_ids': ['INSERT_ITEM_ID_1', 'INSERT_ITEM_ID_2'], 'number_items': INSERT_NUMBER_ITEMS, 'uuid_c1': 'INSERT_UUID_C1', 'user_email': 'INSERT_USER_EMAIL'})
// snaptr('track', 'SIGN_UP', {'sign_up_method': 'INSERT_SIGN_UP_METHOD', 'uuid_c1': 'INSERT_UUID_C1', 'user_email': 'INSERT_USER_EMAIL'})
// snaptr('track', 'VIEW_CONTENT', {'price': INSERT_PRICE, 'currency': 'INSERT_CURRENCY', 'item_ids': ['INSERT_ITEM_ID_1', 'INSERT_ITEM_ID_2'], 'uuid_c1': 'INSERT_UUID_C1', 'user_email': 'INSERT_USER_EMAIL'})


const addToCart = data => {
    try {
        if (!window.snaptr) return

        const {
            productId,
            amount,
            currency,
            quantity
        } = data

        window.snaptr('track', 'ADD_CART', {
            'price': amount,
            'currency': currency,
            'item_ids': [productId],
            'number_items': quantity,
        })


    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
    try {
        if (!window.snaptr) return
        const {
            productId,
            amount,
            currency,
        } = data
        window.snaptr('track', 'VIEW_CONTENT', {
            'price': amount,
            'currency': currency,
            'item_ids': [productId],
        })
    } catch (e) {
        console.warn(e)
    }
}

const purchase = data => {
    try {
        const {
            transactionId,
            orderTotal,
            orderItems
        } = data

        const item_ids = orderItems?.map(item => item.productId)
        const item_quantity = orderItems?.reduce((acc, item) => acc + item.quantity, 0)

        if (window.snaptr) {
            window.snaptr('track', 'PURCHASE', {
                'price': orderTotal?.amount,
                'currency': orderTotal?.currency,
                'transaction_id': transactionId,
                'item_ids': item_ids,
                'number_items': item_quantity,
            })
        }

    } catch (e) {
        console.warn(e)
    }
}


const signup = data => {
    try {
        if (!window.snaptr) return
        window.snaptr('track', 'SIGN_UP', {
            'sign_up_method': data?.method,
            'user_email': data?.email
        })
    } catch (e) {
        console.warn(e)
    }

}


export default {
    addToCart,
    pageView,
    purchase,
    signup,
}
