import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import CountDown from '../../../../../components/count-down'
import { Iconfont } from '../../../../../components/icon/iconfont'
import DisplayTransition from '../../../../components/transitions/display-transition'
import Mask from '../../../../components/mask'


const PreOrderContent = props => {
    useStyles(classes)
    const { title, onClose } = props
    return <div className={classes.PreOrderContent}>
        <div className={classes.Hd}>
            <span className={classes.Title}>{title}</span>
            <Iconfont className={classes.CloseIcon} onClick={onClose}>&#xe69a;</Iconfont>
        </div>
        <div className={classes.Bd}>
            <div className={classes.Content}>
                <span style={{ fontSize: 18, fontFamily: 'Roboto-Bold' }}>Our Pre-order Policy</span><br /><br />
                1.A Pre-order succeeds if it reaches 30 purchases in 7 days; otherwise, it's deemed unsuccessful.<br /><br />
                2. Enjoy up to 30% off on Pre-order items for a limited 7-day period, but discounts can't be combined with other coupons.<br /><br />
                3. Shipping dates align with the estimated shipping date shown on product pages.<br /><br />
                4. All Pre-orders ship free without minimum order, and are placed separately from in-stock orders.<br /><br />
                5.In case of a failed Pre-order, we promptly refund to your store account.<br /><br />
            </div>

        </div>
    </div>
}

const PreOrderPolicyWindow = props => {
    useStyles(classes)
    const { title, onClose, show } = props

    return <React.Fragment>
        <DisplayTransition in={show} className={classes.PreOrderPolicyWindow}>
            <div>
                <PreOrderContent {...props} />
            </div>
        </DisplayTransition>
        {
            show && <Mask onClick={onClose} style={{zIndex: 99}}/>
        }
    </React.Fragment>
}


const PreOrderPortal = props => {

    return typeof window === 'undefined' ? null : ReactDOM.createPortal(
        <PreOrderPolicyWindow {...props} />,
        document.getElementById('root')
    )
}

const Ask = props => {
    useStyles(classes)
    return <Iconfont className={classes.Ask} {...props}>&#xe718;</Iconfont>
}



const ListingProcesses = props => {
    useStyles(classes)
    const { goal, sales } = props
    const percent = Math.round(sales * 100 / goal)
    return <div className={classes.PreProcesses}>
        <div className={classes.Actived} style={{ width: `${percent > 100 ? 100 : percent}%` }} />
    </div>
}

export const ListingPreOrderComponent = props => {
    useStyles(classes)
    const { preorder } = props
    const offset = preorder.endTime - preorder.serverTime
    const leftGoal = preorder.orderQtyThreshold - preorder.orderQty
    return <div className={classes.PreOrder}>
        <ListingProcesses goal={preorder.orderQtyThreshold} sales={preorder.orderQty} />
        <div className={classes.GoalText}>
            {Math.round(leftGoal >= 0 ? leftGoal : 0)}pc(s) to the goal
        </div>
        <div className={classes.Timmer}>
            <CountDown showDay showHour showLabel offset={offset} />
        </div>
    </div>
}

export const PreOrderComponent = props => {
    useStyles(classes)
    const { preorder } = props
    const [show, setShow] = useState(false)
    const offset = preorder.endTime - preorder.serverTime
    const leftGoal = preorder.orderQtyThreshold - preorder.orderQty
    return <React.Fragment>
        <div className={classes.DetailPreOrderContainer}>
            <div className={classes.DetailPreOrder}>
                <div className={classes.PreTitle}>
                    <span className={classes.Title}>Pre Order</span> <Ask onClick={() => setShow(true)} />
                </div>
                <ListingProcesses goal={preorder.orderQtyThreshold} sales={preorder.orderQty} />
                <div className={classes.PreOrderDescription}>
                    <span className={classes.GoalText}>
                        {Math.round(leftGoal >= 0 ? leftGoal : 0)}pc(s) to the goal
                    </span>
                    <span className={classes.Timmer}>
                        <CountDown showDay showHour showLabel offset={offset} />
                    </span>
                </div>
            </div>
            <div className={classes.Estimated}>
                Estimated to be shipped on: {preorder.estimatedShippedTimeMsg}
            </div>
        </div>
        <PreOrderPortal show={show} onClose={() => { setShow(false) }} title={'Pre Order'} />
    </React.Fragment>
}