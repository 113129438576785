import React, { useRef, useState, useCallback } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { unitPrice, getLowerPrice, getDelPrice, isPromotion, getProductUrl } from '../../../../../../utils/product'
import { useApp, useLanguage, useWebp } from '../../../../../hooks/common'
import withItemScroll from '../../../../../hocs/item-scroll'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants/index'
import { FormattedMessage } from 'react-intl'
import CartIcon from '../cart/cart-icon'
import { Iconfont } from '../../../../../components/icon/iconfont'
import FaceBookShare from '../tools/share-to-face'
import withAddToWish from '../tools/add-to-wish'
import { useSelector } from 'react-redux'
import { getSensorsUrl } from '../../../../../utils/sensor'
// import ReactPlayer from 'react-player/lazy'
// import CountDown from '../../../../../components/count-down'
// import withEnterView from '../../../../../hocs/enter-viewport'

const convertCommaToBrTitle = title => title?.replace(/,/g, '<br/>')

// const ListingVideo = withEnterView(props => {

//     const { link, poster, innerRef, entered } = props
//     const [duration, setDuration] = useState(0)
//     const [lefting, setLefting] = useState(0)
//     const [ready, setReady] = useState(false)
//     const [ended, setEnded] = useState(false)
//     const ref = useRef()

//     const durationHandle = d => {
//         setDuration(d)
//     }

//     const endedHandle = () => {
//         ref.current?.getInternalPlayer()?.playVideo?.()
//         setLefting(lefting + 1)
//         // setEnded(true)
//     }

//     const clickHandle = e => {
//         // e.preventDefault()
//         // e.stopPropagation()
//         // setEnded(false)
//     }

//     return <div className={classes.VideoContainer} ref={innerRef}>

//         {
//             entered && !ended ? <React.Fragment>
//                 <div className={classes.Timmer}>
//                     {
//                         duration && ready && <CountDown key={lefting} offset={duration * 1000} />
//                     }
//                 </div>

//                 <ReactPlayer className={classes.ReactPlayer}
//                     ref={ref}
//                     width='100%'
//                     height='100%'
//                     url={`https://www.youtube.com/watch?v=${link}`}
//                     onDuration={durationHandle}
//                     muted
//                     playing={ready}
//                     onReady={() => { setReady(true) }}
//                     onEnded={endedHandle}
//                     config={{
//                         youtube: {
//                             playerVars: { showinfo: 0, controls: 0, modestbranding: 1, playsinline: 1, autohide: 1, rel: 0, iv_load_policy: 3 },
//                             embedOptions: { showinfo: 0, controls: 0, modestbranding: 1, playsinline: 1, autohide: 1, rel: 0, iv_load_policy: 3 }
//                         }
//                     }} />


//                 {
//                     !ready && <LazyLoadImage src={poster} />
//                 }

//             </React.Fragment> : <LazyLoadImage src={poster} />
//         }



//     </div>
// })



export const EmptyItem = props => {
    useStyles(classes)
    return <div className={classes.EmptyItem} />
}

const WishIcon = withAddToWish(props => {
    const { addToWishList, hasExistInWishList } = props
    return <span className={classes.Tool} onClick={addToWishList}>
        {
            hasExistInWishList ? <Iconfont style={{
                color: 'rgb(230, 69, 69)'
            }} className={classes.Icon}>&#xe67b;</Iconfont> : <Iconfont className={classes.Icon}>&#xe62c;</Iconfont>
        }
    </span>
})

const FaceIcon = props => {
    const { shareUrl } = props
    return <FaceBookShare shareUrl={shareUrl}>
        <span className={classes.Tool}>
            <Iconfont className={classes.Icon}>&#xe628;</Iconfont>
        </span>
    </FaceBookShare>
}


const Tools = props => {
    useStyles(classes)
    const { product, product_type, position, selectedColor, sensors } = props
    const isProm = isPromotion(product)
    const promotionType = isProm ? product.promotion.type : ''
    const global = useSelector(state => state.global)
    const { config } = global

    const stopEvent = useCallback(e => {
        e?.stopPropagation?.()
    }, [])



    return <div className={classes.Tools} onClick={stopEvent}>
        <WishIcon productId={product?.id} product_type={product_type} />
        <div className={classes.Group}>
            <CartIcon
                render={() => <span className={classes.Tool}>
                    <Iconfont className={classes.Icon}>&#xe62a;</Iconfont>
                </span>}
                sensors={
                    {
                        ...sensors,
                        layerName: product?.layerName,
                        promotionType,
                        trackingRecords: product?.trackingRecords,
                        dataSource: product?.dataSource,
                        geekoExperimentId: product?.geekoExperimentId,
                        geekoRequsestId: product?.geekoRequsestId,
                        aliRequestId: product?.aliRequestId,
                        aliExperimentId: product?.aliExperimentId,
                        product_position: position + 1,
                    }
                } product_type={product_type} productId={selectedColor?.productId || product.id} color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color} />

            <FaceIcon shareUrl={`${config?.root}${getProductUrl(product?.id, product?.name)}`} />
        </div>
    </div>
}


export const List6Product = withItemScroll(props => {
    useStyles(classes)

    const { product, position, column, sensors, product_type, innerRef, giftInfo, showCart, showRanking } = props

    const language = useLanguage()

    const isGift = !!giftInfo

    const { differentColorStyleVariants } = product

    const [selectedColor, setSelectedColor] = useState(differentColorStyleVariants?.find(v => v.productId === product.id))
    const [hovered, setHovered] = useState(false)

    const isWebp = useWebp() && selectedColor?.status === '1'

    const [lowerPrice, delPrice, isProm] = [
        getLowerPrice(product),
        getDelPrice(product),
        isPromotion(product)
    ]

    let off = 0
    if (delPrice) {
        off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
    }

    let suffix = ''

    if (product_type) {
        suffix += `${suffix ? '&' : ''}product_type=${product_type}`
    }

    const url = getProductUrl(selectedColor?.id || product?.id, product?.name, selectedColor?.color, suffix, language)


    const promotionType = isProm ? product.promotion.type : ''

    const isActivePromotion = isProm && !product?.promotion?.countdownHidden

    const priceClass = isActivePromotion ? classes.FlashPrice : (off > 0 ? classes.PromotionPrice : classes.Price)

    const imageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.mainImage || product?.pcMainImage}`
    const secondaryImageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.pcMainImage2 || product?.pcMainImage2}`

    const handleProductClick = e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.resourcepage_title,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            resource_position: sensors?.resource_position,
            product_position: position + 1,
        })
    }

    return <figure className={`${classes.NormalItem} ${classes.SimpleList6Item} ${position === 0 ? classes.FirstImage : ''}`}>
        <a
            href={url} ref={innerRef}
            product-id={product?.id}
            data-product-list-source
            data-promotion={promotionType}
            data-layer={product?.layerName}
            data-tracking={product.trackingRecords}
            data-product-source={product.dataSource}
            data-geeko-experiment={product.geekoExperimentId}
            data-geeko-id={product.geekoRequsestId}
            data-request-id={product.aliRequestId}
            data-experiment-id={product.aliExperimentId}
            data-column={column}
            data-product-position={position + 1}
            data-position={sensors?.resource_position}
            data-type={sensors?.resource_type}
            data-content={sensors?.resource_content}
            data-title={sensors?.resourcepage_title}
            data-product-type={product_type}
            onClick={handleProductClick}
        >

            <div onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={classes.Image}>

                {
                    position === 0 ? <img src={hovered && secondaryImageUrl ? secondaryImageUrl : imageUrl} alt={product?.name} /> : <LazyLoadImage src={hovered ? secondaryImageUrl : imageUrl} alt={product?.name} />
                }


                {
                    product.combinatorialPromotion && product.combinatorialPromotion.enabled && <div className={classes.CombinatorialPromotion} style={{
                        background: product.combinatorialPromotion.theme?.background,
                        color: product.combinatorialPromotion.theme?.color,
                    }}>
                        <span dangerouslySetInnerHTML={{ __html: convertCommaToBrTitle(product?.combinatorialPromotion?.title) }} />
                    </div>
                }

                {
                    showRanking ? <React.Fragment>
                        <div className={classes.RankIcon} style={{ backgroundImage: `url(https://image.geeko.ltd/upgrade/20211223/${position < 4 ? position + 1 : 4}.svg)` }}>
                            <span>{position + 1}</span>
                        </div>
                    </React.Fragment> : <React.Fragment>
                        {
                            product.flag ? (
                                <span className={`${classes.OffTip} ${classes.PreFall}`}>{product.flag}</span>
                            ) : (
                                <React.Fragment>
                                    {
                                        product.isNew && <span className={classes.New}><FormattedMessage id="new" defaultMessage="New" /></span>
                                    }

                                    {
                                        !product.isNew && isActivePromotion && <span style={{ backgroundColor: '#fed157', color: '#222' }} className={classes.OffTip}>{`-${off}%`}</span>
                                    }

                                    {
                                        !product.isNew && !isActivePromotion && off > 0 && <span className={classes.OffTip}>{`-${off}%`}</span>
                                    }
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                }




                {

                    !showCart && hovered && <div className={classes.AbsoluteTools}>
                        <Tools
                            product={product}
                            selectedColor={selectedColor}
                            product_type={product_type}
                            position={position}
                            sensors={sensors}
                        />
                    </div>
                }
            </div>
        </a>
        <figcaption>
            <div className={classes.PricesContainer}>
                <div className={classes.Prices}>
                    {
                        isGift ? <span className={priceClass}>{product?.giftPrice ? unitPrice(product?.giftPrice) : unitPrice({ ...lowerPrice, amount: '0.00' })}</span> : <span className={priceClass}>{unitPrice(lowerPrice)}</span>
                    }

                    {
                        delPrice && <del className={classes.Del}>{unitPrice(delPrice)}</del>
                    }
                </div>

                {
                    product?.estimatedPrice && !isGift &&
                    <div className={classes.EstimatedPrice}>
                        <Iconfont className={classes.EstimatedIcon}>&#xe7e9;</Iconfont>
                        <FormattedMessage id="estimated" defaultMessage={"Estimated"} />
                        <span className={classes.EPrice}>{unitPrice(product?.estimatedPrice)}</span>
                    </div>
                }

                {
                    showCart && <div>
                        <CartIcon
                            render={() => {
                                return <button className={classes.BuyNow}><FormattedMessage id="buy_now" defaultMessage="Buy Now" /></button>
                            }}
                            sensors={
                                {
                                    ...sensors,
                                    layerName: product?.layerName,
                                    promotionType,
                                    trackingRecords: product?.trackingRecords,
                                    dataSource: product?.dataSource,
                                    geekoExperimentId: product?.geekoExperimentId,
                                    geekoRequsestId: product?.geekoRequsestId,
                                    aliRequestId: product?.aliRequestId,
                                    aliExperimentId: product?.aliExperimentId,
                                    product_position: position + 1,
                                }
                            } product_type={product_type} productId={selectedColor?.productId || product.id} color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color} giftInfo={giftInfo} />
                    </div>
                }
            </div>
        </figcaption>
    </figure>
})

export default withItemScroll(props => {
    useStyles(classes)

    const { product, position, column, sensors, product_type, innerRef, giftInfo, isLuckyDraw, isAddOn, dark } = props

    const language = useLanguage()

    const isApp = useApp()

    const isGift = !!giftInfo && !isAddOn || !!isLuckyDraw

    const { differentColorStyleVariants } = product

    const [selectedColor, setSelectedColor] = useState(differentColorStyleVariants?.find(v => v.productId === product.id))
    const [hovered, setHovered] = useState(false)

    const isWebp = useWebp() && selectedColor?.status === '1'

    const [lowerPrice, delPrice, isProm] = [
        getLowerPrice(product),
        getDelPrice(product),
        isPromotion(product)
    ]

    let off = 0
    if (delPrice) {
        off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
    }

    let suffix = ''

    if (product_type) {
        suffix += `${suffix ? '&' : ''}product_type=${product_type}`
    }

    const url = getProductUrl(selectedColor?.id || product?.id, product?.name, selectedColor?.color, suffix, language)


    const promotionType = isProm ? product.promotion.type : ''

    const isActivePromotion = isProm && !product?.promotion?.countdownHidden

    const priceClass = isActivePromotion ? classes.FlashPrice : (off > 0 ? classes.PromotionPrice : classes.Price)

    const imageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.mainImage || product?.pcMainImage}`
    const secondaryImageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.pcMainImage2 || product?.pcMainImage2}`

    // if(selectedColor){
    // 	selectedColor.appExclusiveZone={
    // 		enable: true,
    // 		message: '50% off on app'
    // 	}
    // }

    // if(product){
    // 	product.appExclusiveZone={
    // 		enable: true,
    // 		message: '50% off on app'
    // 	}
    // }

    const appExclusiveZone = selectedColor?.appExclusiveZone || product?.appExclusiveZone

    const handleProductClick = e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.resourcepage_title,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            resource_position: sensors?.resource_position,
            product_position: position + 1,
        })
    }


    return <figure className={`${classes.NormalItem} ${classes.SimpleItem} ${position === 0 ? classes.FirstImage : ''}`}>
        <a
            href={url} ref={innerRef}
            product-id={product?.id}
            data-product-list-source
            data-promotion={promotionType}
            data-layer={product?.layerName}
            data-tracking={product.trackingRecords}
            data-product-source={product.dataSource}
            data-geeko-experiment={product.geekoExperimentId}
            data-geeko-id={product.geekoRequsestId}
            data-request-id={product.aliRequestId}
            data-experiment-id={product.aliExperimentId}
            data-column={column}
            data-product-position={position + 1}
            data-position={sensors?.resource_position}
            data-type={sensors?.resource_type}
            data-content={sensors?.resource_content}
            data-title={sensors?.resourcepage_title}
            data-product-type={product_type}
            onClick={handleProductClick}
        >

            <div onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={classes.Image}>

                {
                    position === 0 ? <img src={hovered && secondaryImageUrl ? secondaryImageUrl : imageUrl} alt={product?.name} /> : <LazyLoadImage src={hovered ? secondaryImageUrl : imageUrl} alt={product?.name} />
                }


                {
                    product.combinatorialPromotion && product.combinatorialPromotion.enabled && <div className={classes.CombinatorialPromotion} style={{
                        background: product.combinatorialPromotion.theme?.background,
                        color: product.combinatorialPromotion.theme?.color,
                    }}>
                        <span dangerouslySetInnerHTML={{ __html: convertCommaToBrTitle(product?.combinatorialPromotion?.title) }} />
                    </div>
                }

                {
                    product.flag ? (
                        <span className={`${classes.OffTip} ${classes.PreFall}`}>{product.flag}</span>
                    ) : (
                        <React.Fragment>
                            {
                                product.isNew && <span className={classes.New}><FormattedMessage id="new" defaultMessage="New" /></span>
                            }

                            {/* {
                                !product.isNew && off > 0 && <span className={classes.BlackFridayIcon}>{`-${off}%`}</span>
                            } */}

                            {
                                !product.isNew && isActivePromotion && <span style={{ backgroundColor: '#fed157', color: '#222' }} className={classes.OffTip}>{`-${off}%`}</span>
                            }

                            {
                                !product.isNew && !isActivePromotion && off > 0 && <span className={classes.OffTip}>{`-${off}%`}</span>
                            }
                        </React.Fragment>
                    )
                }
            </div>
        </a>
        <figcaption>
            <div className={classes.Name}>{product.name}</div>
            <div className={classes.PricesContainer}>
                <div className={classes.Prices}>
                    {
                        isGift ? <span className={`${priceClass} ${dark ? classes.Dark : ''}`}>{product?.giftPrice ? unitPrice(product?.giftPrice) : unitPrice({ ...lowerPrice, amount: '0.00' })}</span> : <span className={`${priceClass} ${dark ? classes.Dark : ''}`}>{unitPrice(lowerPrice)}</span>
                    }

                    {
                        delPrice && <del className={classes.Del}>{unitPrice(delPrice)}</del>
                    }
                </div>

                <div>
                    <CartIcon
                        render={() => {
                            return <Iconfont className={`${classes.CartIcon} ${dark ? classes.Dark : ''}`}>&#xe6a8;</Iconfont>
                        }}
                        sensors={
                            {
                                ...sensors,
                                layerName: product?.layerName,
                                promotionType,
                                trackingRecords: product?.trackingRecords,
                                dataSource: product?.dataSource,
                                geekoExperimentId: product?.geekoExperimentId,
                                geekoRequsestId: product?.geekoRequsestId,
                                aliRequestId: product?.aliRequestId,
                                aliExperimentId: product?.aliExperimentId,
                                product_position: position + 1,
                            }
                        }
                        product_type={product_type}
                        productId={selectedColor?.productId || product.id}
                        color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color}
                        giftInfo={giftInfo}
                        isLuckyDraw={isLuckyDraw}
                        isAddOn={isAddOn}
                        dark={dark}
                    />
                </div>
            </div>

            {/* {
                differentColorStyleVariants && differentColorStyleVariants.length > 1 && <ColorSwiper product={product} onColor={c => { setSelectedColor(c) }} selectedColor={selectedColor} colors={differentColorStyleVariants} />
            } */}
            <div>
                {
                    appExclusiveZone && appExclusiveZone.enable && <div className={classes.AppExclusive}>
                        <span dangerouslySetInnerHTML={{ __html: appExclusiveZone.message }} />
                    </div>
                }
            </div>
        </figcaption>
    </figure>
})