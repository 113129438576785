import React, { useCallback, useEffect, useState } from 'react'
import { Iconfont } from '../../../../../components/icon/iconfont'
import _ from 'lodash'
import { searchSimple } from '../../../../../../api'
import SearchResult from '../search-result'
import HotWords, {addWordToCache} from '../hot-words'
import RecentlyWords from '../recently-search'
import withOutClick from '../../../../../hocs/out-click'
import { action_fetch_hot_words } from '../../../../../../store/actions/global'
import { useDispatch, useSelector } from 'react-redux'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { useIntl } from 'react-intl'
import VisualSearch from '../visual-search'
import { SET_SEARCH_IMAGE_RESULT } from '../../../../../../store/actions/global'
import { getSensorsUrl } from '../../../../../utils/sensor'


const Input = props => {

    useStyles(classes)

    const intl = useIntl()

    const handleKeyUp = e => {
        if(e.keyCode === '13'){
            props.submitHandle(e)
        }
    }


    return <form className={classes.SearchInput} onSubmit={props.submitHandle}>
        <Iconfont className={classes.IconSearch}>&#xe67d;</Iconfont>
        <input value={props.keyWord} className={classes.Input} onKeyUp={handleKeyUp} onChange={props.onChange} onFocus={props.onFocus} placeholder={intl.formatMessage({id: 'search_for', defaultMessage: 'I\'m searching for...'})}/>
        {/* <Iconfont onClick={props.handleVisual} className={classes.CameraIcon}>&#xe706;</Iconfont> */}
    </form>
}

export default withOutClick(props => {

    useStyles(classes)

    const { open } = props

    const global = useSelector(state => state.global)
    const dispatch = useDispatch()

    const { hotWords, imageSearch } = global

    const [searchResult, setSearchResult] = useState(null)
    const [showSuggestion, setShowSuggestion] = useState(false)
    const [showHotwords, setShowHotwords] = useState(false)
    const [keyWord, setKeyWord] = useState('')
    const [showVisual, setShowVisual] = useState(false)

    const deboundedFetch = useCallback(_.debounce(async key => {
		if (key && key.length >= 2) {
			const response = await searchSimple(key)
			if (response.code === 200) {
				setSearchResult(response.result?.groupResult)
			}
		}
		setShowSuggestion(key && key.length >= 2)
        setShowHotwords(!key)
	}, 400), [])

    const searchHandle = useCallback(async key => {
        setKeyWord(key)
		deboundedFetch(key)
	}, [])

    const focusHandle = async e => {
        setShowHotwords(!showSuggestion && !keyWord)
        if (!hotWords || hotWords.length < 1) {
			dispatch(action_fetch_hot_words())
		}
        if(keyWord){
            searchHandle(keyWord)
        }

        if (window.GeekoSensors) {
            window.GeekoSensors.Track("click_search");
        }
    }

    useEffect(() => {
        setShowSuggestion(false)
        setShowHotwords(false)
    }, [open])


    const submitHandle = e => {
		e?.preventDefault?.()
		const finalSearchVaule = keyWord || global.currentSearch || hotWords?.[0]?.value
		if(!finalSearchVaule) return
        let sensorsData;
		try {
            
			addWordToCache({ value: finalSearchVaule, label: finalSearchVaule })

            if(keyWord){
                sensorsData = {
                    resourcepage_title: 'search',
                    resource_position: '1',
                    resource_type: '14',
                    resource_content: keyWord
                };
                if(window.GeekoSensors){
                    window.GeekoSensors.Track("PitPositionClick", sensorsData)
    
                    // window.GeekoSensors.Track("PitPositionExposure", sensorsData)
                }
            }
    
            // if(global.currentSearch){
            //     if(window.GeekoSensors){
            //         window.GeekoSensors.Track("PitPositionClick", {
            //             resourcepage_title: 'search',
            //             resource_position: '1',
            //             resource_type: '17',
            //             resource_content: global.currentSearch
            //         })
            //     }
            // }
		} catch (e) {
			console.error(e)
		}

        window.location.href = getSensorsUrl(`/i/search/result?key=${encodeURIComponent(finalSearchVaule)}`,sensorsData);
	}

    const imageSearchHandle = async () =>{
        if(imageSearch?.productId || imageSearch?.result?.length > 0){
            await dispatch({
                type:SET_SEARCH_IMAGE_RESULT,
                payload:{
                    url:null,
                    result:[],
                    show:false,
                    searchType:null,
                    sensors:null,
                    productId: null
                }
            });
        }else{
            setShowVisual(false)
        }
    }

    return <div className={classes.SearchInputContainer}>
        <Input submitHandle={submitHandle} value={keyWord} onChange={e => {searchHandle(e.target.value)}} onFocus={focusHandle} handleVisual={e => {setShowVisual(true)}}/>

        {
            (showSuggestion || showHotwords) && <div className={classes.DownMenu}>
                {
                    showSuggestion && <SearchResult keyWord={keyWord} product={searchResult?.product} words={searchResult?.words}/>
                }
                
                {
                    showHotwords && <React.Fragment>
                        <RecentlyWords/>
                        <HotWords hotWords={hotWords}/>
                    </React.Fragment>
                }
            </div>
        }

        <VisualSearch 
            showEditor={ imageSearch?.show || showVisual } 
            onClose={() => imageSearchHandle()}
            visualResult={imageSearch?.result}
            page={imageSearch?.searchType}
            image={imageSearch?.url} 
            productId={imageSearch?.productId} 
            sensors={imageSearch?.sensors} 
        />
    </div>
})