import React, { useEffect, useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import SwitchButton from '../switch'
import Cache from '../../../../utils/cache'
import Mask from '../mask'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

const eu_countries = ['DE', 'FR', 'IT', 'ES', 'NL', 'BE', 'AT', 'SE', 'DK', 'FI',
    'EL', 'PT', 'IE', 'CY', 'LU', 'MT', 'SI', 'SK', 'EE', 'LV',
    'LT', 'HR', 'BG', 'RO']

const MainContent = props => {
    useStyles(classes)
    const { onSetting, consentHandle } = props

    const acceptHandle = () => {
        consentHandle({
            analytics: true
        })
    }

    const declineHandle = () => {
        consentHandle(null)
    }

    return <>
        <div className={classes.Bd}>
            We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from.
        </div>
        <div className={classes.Fd}>
            <button className={classes.TextButton} onClick={onSetting}>Cookies settings</button>
            <button className={`${classes.SettingButton} ${classes.Accept}`} onClick={acceptHandle}>Accept</button>
            <button className={classes.SettingButton} onClick={declineHandle}>Decline</button>
        </div>
    </>
}


const CookiesSettings = props => {
    useStyles(classes)
    const { consentHandle } = props
    const [analyticsOn, setAnalyticsOn] = useState(false)
    const [advertisingOn, setAdvertisingOn] = useState(false)
    const switchAnalyticsHandle = on => {
        setAnalyticsOn(on)
    }

    const switchAdvertisingHandle = on => {
        setAdvertisingOn(on)
    }

    useEffect(() => { }, [])

    const acceptAllHandle = () => {
        consentHandle({
            analytics: true,
            advertising: true,
        })
    }



    const saveSettingsHandle = () => {
        if (analyticsOn && advertisingOn) {
            consentHandle({
                analytics: analyticsOn,
                advertising: advertisingOn,
            })
        } else {
            consentHandle({
                analytics: analyticsOn,
                advertising: advertisingOn,
            }, 7)
        }
    }

    return <>
        <div className={classes.Hd}>
            <span className={classes.Title}>
                <FormattedMessage id="consent_about" defaultMessage="About Cookies" />
            </span>
        </div>
        <div className={classes.Bd}>
            <FormattedMessage id="consent_cookie_dsc" defaultMessage="We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from." />
        </div>
        <div className={classes.Settings}>
            <div className={classes.Setting}>
                <div className={classes.Hd}>
                    <span className={classes.Title}>
                        <FormattedMessage id="consent_setting_1_title" defaultMessage="Necessary Cookies" />
                    </span>
                    <span className={classes.Status}>
                        <FormattedMessage id="consent_setting_1_status" defaultMessage="Always active" />
                    </span>
                </div>
                <div className={classes.Bd}>
                    <FormattedMessage id="consent_setting_1_dsc" defaultMessage="These cookies are essential for the website to function properly. They enable basic functions such as page navigation and access to secure areas of the website. The website cannot function properly without these cookies, and they cannot be disabled." />
                </div>
            </div>

            <div className={classes.Setting}>
                <div className={classes.Hd}>
                    <span className={classes.Title}>
                        <FormattedMessage id="consent_setting_2_title" defaultMessage="Analytical Cookies" />
                    </span>
                    <SwitchButton onSwitch={switchAnalyticsHandle} on={analyticsOn} />
                </div>

                <div className={classes.Bd}>
                    <FormattedMessage id="consent_setting_2_dsc" defaultMessage="Analytical cookies provide information about how this website is used. This enhances the user experience. The collected data is aggregated and anonymized." />
                </div>
            </div>

            <div className={classes.Setting}>
                <div className={classes.Hd}>
                    <span className={classes.Title}>
                        <FormattedMessage id="consent_setting_3_title" defaultMessage="Advertising Cookies" />
                    </span>
                    <SwitchButton onSwitch={switchAdvertisingHandle} on={advertisingOn} />
                </div>
                <div className={classes.Bd}>
                    <FormattedMessage id="consent_setting_3_dsc" defaultMessage="Advertising cookies provide information about user interactions with ChicMe content. This helps us better understand the effectiveness of the content of our emails and our website." />
                </div>
            </div>
        </div>
        <div className={classes.Fd}>
            <button onClick={acceptAllHandle} className={`${classes.SettingButton} ${classes.Accept}`}>
                <FormattedMessage id="consent_btn_accept_all" defaultMessage="Accept All" />
            </button>
            <button onClick={saveSettingsHandle} className={classes.SettingButton}>
                <FormattedMessage id="consent_btn_save_settings" defaultMessage="Save settings" />
            </button>
        </div>
    </>
}




export default props => {
    useStyles(classes)
    const [show, setShow] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [consent, setConsent] = useState(null)
    const { country } = useSelector(state => state.global)

    useEffect(() => {
        const _consent = Cache.get('consent_banner')

        if (eu_countries.includes(country)) {
            if (!_consent) {
                setShow(true)
                if(window.GeekoSensors){
                    window.GeekoSensors.Track('consent_show', {})
                }
            } else {
                const ad_storage = _consent?.advertising ? 'granted' : 'denied'
                const analytics_storage = _consent?.analytics ? 'granted' : 'denied'
                const normal_storage = _consent?.advertising ? 'granted' : 'denied'


                if (window.gtag) {
                    window.gtag('consent', 'update', {
                        'ad_user_data': normal_storage,
                        'ad_personalization': normal_storage,
                        'ad_storage': ad_storage,
                        'analytics_storage': analytics_storage
                    })
                }
            }
        }
    }, [])

    const settingHandle = () => {
        setShowSettings(true)
    }

    const consentHandle = (c, day) => {
        setConsent(c)
        setShow(false)
        if(c){
            if (day) {
                Cache.set('consent_banner', c, day * 24 * 60 * 60)
            } else {
                Cache.set('consent_banner', c, 365 * 24 * 60 * 60)
            }
    
            const ad_storage = c?.advertising ? 'granted' : 'denied'
            const analytics_storage = c?.analytics ? 'granted' : 'denied'
            const normal_storage = c?.advertising ? 'granted' : 'denied'
    
    
            if (window.gtag) {
                window.gtag('consent', 'update', {
                    'ad_user_data': normal_storage,
                    'ad_personalization': normal_storage,
                    'ad_storage': ad_storage,
                    'analytics_storage': analytics_storage
                })
            }
            if(window.GeekoSensors){
                window.GeekoSensors.Track('consent_update', {
                    'ad_user_data': normal_storage,
                    'ad_personalization': normal_storage,
                    'ad_storage': ad_storage,
                    'analytics_storage': analytics_storage
                })
            }
        }else{
            Cache.remove('consent_banner')
            window.gtag('consent', 'update', {
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'ad_storage': 'denied',
                'analytics_storage': 'denied'
            })
            if(window.GeekoSensors){
                window.GeekoSensors.Track('consent_update', {
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                })
            }
        }
        
    }

    return show ? <>
        <div className={`${classes.Container} ${showSettings ? classes.SettingsContainer : ''}`}>
            {
                showSettings ? <CookiesSettings consentHandle={consentHandle} consent={consent} /> : <MainContent consentHandle={consentHandle} onSetting={settingHandle} />
            }
        </div>
        {
            showSettings && <Mask style={{ zIndex: 9 }} />
        }

    </> : <></>
}