import React, { useEffect, useState, useCallback } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import CountDown from '../../../../components/count-down'
import { useSelector } from 'react-redux'
import Navigation from '../navigation'
import Tools from '../tools'
import BaseBox from '../../base-layout/base-box'
import withSource from '../../../../hocs/source-scoll'
import withSourceClick from '../../../../hocs/source-only-click'
import { getSensorsUrl } from '../../../../utils/sensor'

const HeadTip = withSourceClick(props => {
	useStyles(classes)
	const { tip, innerRef } = props

	const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: "topbanner",
			resource_type: '7',
			resource_content: "topbanner",
			resource_position: '0',
		})
	}, [])


	return <a ref={innerRef}
		data-position={0}
		data-type="7"
		data-content="topbanner"
		data-title="topbanner"
		onClick={clickHandle}
		href={tip?.href} className={classes.HeaderTip} style={{ backgroundColor: tip?.bgcolor, backgroundImage: `url(${tip?.imageUrl})` }}>
		{' '}
		{
			tip?.endTime && <CountDown numberStyle={{
				color: tip?.numberColor,
			}} labelStyle={{
				color: tip?.labelColor,
				textTransform: 'uppercase',
				marginLeft: 6,
				marginRight: 6
			}} dotStyle={{ display: 'none' }} offset={Number(tip?.endTime) - new Date().getTime()} showDay showHour showLabel className={classes.CountDown} />
		}
	</a>
})

const Websites = props => {
	useStyles(classes)
	const global = useSelector((state) => state.global)
	const { websites, website } = global
	const currentWebsite = website || '1'

	return websites?.length > 1 ? <ul className={classes.Websites}>
		{
			websites.map((w, i) => <li key={i} className={`${classes.Website}  ${w.id === currentWebsite ? classes.Selected : ''}`}>
				<a href={`/?wbs=${w.id}`}>
					<span>{w.name}</span>
					{
						w.icon && <span className={classes.Hot} style={{
							color: w.icon.color,
							backgroundColor: w.icon.backgroundColor
						}}>
							{w.icon.text}
						</span>
					}
				</a>
			</li>)
		}
	</ul> : <React.Fragment />

}

const Header = props => {
	useStyles(classes)
	const global = useSelector((state) => state.global)
	const { config } = global
	return <header>
		<div className={classes.Main}>
			<a href="/" className={classes.Logo} title={config?.name}>
				<img src={config?.logo} alt={config?.name} />
			</a>

			<BaseBox className={classes.Content}>
				<Websites />
				<Tools />
			</BaseBox>

		</div>
		<Navigation />
	</header>
}

export default props => {
	useStyles(classes)

	const global = useSelector((state) => state.global)
	const ui = useSelector(state => state.ui)
	const { tip } = global

	return <div className={`${classes.HeaderContainer} ${ui.direction === 'up' ? classes.SlideUp : ''}`}>
		<HeadTip tip={tip} />
		<Header />
	</div>
}